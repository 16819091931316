import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function ViewSelectField({ onChange, value, placeholder = '', ...props }) {
	const viewQuery = useQuery(gql`{
        availableViews {
	        id,
	        name,
        }
    }`);

	const options = useMemo(() => {
		return (viewQuery?.data?.availableViews ?? []).map(v => ({
			value: v.id,
			label: v.name,
			original: v,
		}));
	}, [ viewQuery?.data?.availableViews ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={viewQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		value={options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }} />;
}

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};