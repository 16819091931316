import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function UserSelectField({ onChange, value, placeholder = '', allowDeleted = false, disabled = false, ...props }) {
	const userQuery = useQuery(gql`{
		users {
			id,
			firstName,
			fullName,
			dockWorkerRole {
				id
            },
			deletedOn
		}
	}`);

	const options = useMemo(() => {
		return (userQuery?.data?.users ?? []).filter(u => allowDeleted || u.deletedOn == null).map(u => ({
			value: u.id,
			label: u.fullName,
			original: u,
		}));
	}, [ userQuery?.data?.users ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={userQuery.loading}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		isDisabled={disabled}
		formatOptionLabel={({ value, label, original }) => {
			return <span>{original.deletedOn != null ? <><span className="fa fa-archive" />&nbsp; </> : ''}{label}</span>;
		}}
		value={options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }} />;
}

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};