import React, { Suspense, useContext, useEffect, useState } from 'react';
import UserContext from '../../context/UserContext';
import { gql, useQuery } from '@apollo/client';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import UserSelectField from '../lib/selectfields/UserSelectField';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { DateTime } from 'luxon';
import { Button, ButtonGroup, DateField, LinkButton, Pagination, WithLabel } from '@atrocit/scl';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';
import { PrivilegesContext } from '../../context/PrivilegesContext';
const ImportWageRecords = React.lazy(() => import('./ImportWageRecords'));

export default function WageRecordOverview() {
	const me = useContext(UserContext);
	const privileges = useContext(PrivilegesContext);
	const onlyMyRecords = !privileges.has('READ_ALL_WAGE_RECORDS');
	const [ importModalOpen, setImportModalOpen ] = useState(false);
	const [ page, setPage ] = useState(1);
	const [ user, setUser ] = useState(onlyMyRecords ? me.id : null);
	const [ lowerbound, setLowerbound ] = useState(DateTime.now().startOf('week'));
	const [ upperbound, setUpperbound ] = useState(DateTime.now().endOf('week'));

	useEffect(() => {
		if (page != 1) setPage(1);
	}, [ user ]);

	const recordsQuery = useQuery(gql`query Query($userId: Int, $page: Int!, $lowerbound: Instant!, $upperbound: Instant!) {
		wageRecordsInInterval(page: $page, userId: $userId, lowerbound: $lowerbound, upperbound: $upperbound) {
			totalElements,
			content {
				id,
				dockWorkerRole {
					id,
					user {
						id,
						firstName,
						fullName,
					},
                },
				createdAt,
				createdBy {
					id,
					firstName,
					fullName,
                },
				applied,
				wage,
				premium,
				workbookNumber,
				lastName,
				firstName,
				category,
				function,
				description,
				firm,
				department,
            }
		}
	}`, { variables: { page, userId: user, lowerbound: lowerbound.toUTC().toISO(), upperbound: upperbound.toUTC().toISO() } });
	const records = recordsQuery?.data?.wageRecordsInInterval?.content ?? [];

	const unattachedRecordsQuery = useQuery(gql`{
        unattachedWageRecords { id }
    }`, { skip: !privileges.has('READ_ALL_WORK_RECORDS') });
	const amountOfUnattachedRecords = (unattachedRecordsQuery?.data?.unattachedWageRecords ?? []).length;

	return <>
		<TopBar title={'Loonbewijzen'} />

		{importModalOpen && <Suspense fallback={<></>}>
			<ImportWageRecords key={importModalOpen} onClose={() => { setImportModalOpen(false); recordsQuery.refetch(); unattachedRecordsQuery.refetch(); }} />
		</Suspense>}

		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/wages', label: 'Loonbewijzen' }, { link: '/wages', label: 'Overzicht' } ]} />

				<PrivilegeBarrier privileges={[ 'WRITE_WAGE_RECORDS' ]}>
					<div style={{ display: 'flex', gap: 'var(--u-8)', justifyContent: 'flex-end' }}>
						<LinkButton disabled={amountOfUnattachedRecords == 0} to="/wages/unattached"><span className="fa fa-link" />&nbsp; Bewijzen koppelen{amountOfUnattachedRecords > 0 && <>&nbsp;<span className="badge">{amountOfUnattachedRecords}</span></>}</LinkButton>
						<Button level="primary" onClick={() => setImportModalOpen(true)}><span className="fa fa-cloud-upload" />&nbsp; Loonbewijzen importeren</Button>
					</div>
				</PrivilegeBarrier>

				{!onlyMyRecords && <div style={{ padding: 'var(--u-16) 0' }}>
					<UserSelectField allowDeleted={true} onChange={setUser} value={user} placeholder="Selecteer gebruiker om te filteren..." />
				</div>}

				<div>
					<div className="col-2" style={{ alignItems: 'flex-end', paddingBottom: 'var(--u-8)' }}>
						<WithLabel label="Vanaf datum">
							<DateField onChange={d => setLowerbound(d.startOf('day'))} value={lowerbound} />
						</WithLabel>
						<WithLabel label="Tot en met datum">
							<DateField onChange={d => setUpperbound(d.endOf('day'))} value={upperbound} />
						</WithLabel>

					</div>
					<div style={{ paddingBottom: 'var(--u-32)' }}>
						<ButtonGroup>
							<Button
								onClick={() => { setLowerbound(DateTime.now().minus({ days: 14 }).startOf('week')); setUpperbound(DateTime.now().minus({ days: 14 }).endOf('week')); }}
								active={ lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week')) && upperbound.equals((DateTime.now().minus({ days: 14 }).endOf('week')))}
								level={ lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week')) && upperbound.equals((DateTime.now().minus({ days: 14 }).endOf('week'))) ? 'primary' : 'secondary'}>
								Twee weken geleden
							</Button>
							<Button
								onClick={() => { setLowerbound(DateTime.now().minus({ days: 7 }).startOf('week')); setUpperbound(DateTime.now().minus({ days: 7 }).endOf('week')); }}
								active={ lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week')) && upperbound.equals((DateTime.now().minus({ days: 7 }).endOf('week')))}
								level={ lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week')) && upperbound.equals((DateTime.now().minus({ days: 7 }).endOf('week'))) ? 'primary' : 'secondary'}>
								Afgelopen week
							</Button>
							<Button
								onClick={() => { setLowerbound(DateTime.now().startOf('week')); setUpperbound(DateTime.now().endOf('week')); }}
								active={ lowerbound.equals(DateTime.now().startOf('week')) && upperbound.equals((DateTime.now().endOf('week')))}
								level={ lowerbound.equals(DateTime.now().startOf('week')) && upperbound.equals((DateTime.now().endOf('week'))) ? 'primary' : 'secondary'}>
								Deze week
							</Button>
						</ButtonGroup>
					</div>

				</div>

				<table className="table table-fw">
					<colgroup>
						<PrivilegeBarrier privileges={[ 'READ_ALL_WAGE_RECORDS' ]}>
							<col width="200" />
						</PrivilegeBarrier>
						<col width="130" />
						<col width="130" />
						<col width="90" />
						<col width="90" />
						<col width="70" />
						<col width="70" />
						<col width="*" />
					</colgroup>
					<thead>
						<tr>
							<PrivilegeBarrier privileges={[ 'READ_ALL_WAGE_RECORDS' ]}>
								<th>Arbeider</th>
							</PrivilegeBarrier>
							<th>Eff. datum <span className="fa fa-caret-down" /></th>
							<th>Geregistreerd</th>
							<th className="tbl-align-right">Loon</th>
							<th className="tbl-align-right">Premie</th>
							<th>Cat.</th>
							<th>Func.</th>
							<th>Beschrijving</th>
						</tr>
					</thead>
					<tbody>
						{records.map(r => <tr key={r.id}>
							<PrivilegeBarrier privileges={[ 'READ_ALL_WAGE_RECORDS' ]}>
								<td>{r?.dockWorkerRole?.user?.fullName}</td>
							</PrivilegeBarrier>
							<td><FormattedDate value={DateTime.fromISO(r.applied).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td><FormattedDate value={DateTime.fromISO(r.createdAt).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td className={"tbl-align-right" + (r.wage == 0 ? ' tbl-faded' : '')}>
								<FormattedNumber value={r.wage} style="currency" currency="EUR" />
							</td>
							<td className={'tbl-align-right' + (r.premium == 0 ? ' tbl-faded' : '')}>
								<FormattedNumber value={r.premium} style="currency" currency="EUR" />
							</td>
							<td>{r.category}</td>
							<td>{r.function}</td>
							<td>{r.description}</td>
						</tr>)}
					</tbody>
				</table>
				<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)' }}>
					<Pagination page={page} pageSize={25} setPage={setPage} total={recordsQuery?.data?.wageRecordsInInterval?.totalElements ?? 0} />
				</div>
			</div>
		</div>
	</>;
}