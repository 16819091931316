import ButtonArea from '../lib/forms/ButtonArea';
import { useState } from 'react';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { requestMagicLink } from '../../util/api';
import { Button, Form, InputField, WithLabel } from '@atrocit/scl';
import atrocitLogo from '../../assets/atrocit-logo-1024.png';

export default function Login() {
	const [ email, setEmail ] = useState('');
	const [ loading, setLoading ] = useState(false);
	const [ showMagicLinkMessage, setShowMagicLinkMessage ] = useState(false);

	if (loading) {
		return <div style={{ textAlign: 'center' }}><span className="fa fa-spinner fa-pulse" /></div>;
	} else if (showMagicLinkMessage) {
		return <div>
			<p>Er is een e-mail naar het opgegeven adres gestuurd met een link. Door op de link te klikken ben je onmiddellijk ingelogd.</p>

			<p>Als je eenmaal op de link in de mail geklikt hebt, en je ziet dit scherm nog steeds, klik hier:</p>
			<Button onClick={() => window.location.reload()}>Herladen</Button>
		</div>;
	} else {
		return <Form onSubmit={() => {
			setLoading(true);
			requestMagicLink(email)
				.then(() => setShowMagicLinkMessage(true))
				.finally(() => setLoading(false));
		}}>
			<h2 style={{ margin: 0, paddingBottom: 'var(--u-32)', color: 'var(--col-grey-700)' }}>Inloggen</h2>

			<WithLabel label="E-mail">
				<InputField type="email" onChange={setEmail} value={email} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					level="primary"
					validations={[
						{ valid: email.length >= 3, error: 'E-mailadres is te kort' },
						{ valid: email.includes('@'), error: 'E-mailadres moet @ bevatten' },
					]}
					type="submit">
					Volgende stap&nbsp;&nbsp; <span className="fa fa-chevron-circle-right" />
				</ValidatedButton>
			</ButtonArea>

			<div className="login-frame-attachment">
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'var(--col-grey-600)', textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.85em' }}>
					Powered by&nbsp; <img src={atrocitLogo} alt="Atrocit Logo" style={{ width: 'auto', height: '35px', opacity: 0.9 }} />
				</div>
			</div>
		</Form>;
	}
}