import { useCallback, useState } from 'react';

function readValueFromLocalStorage(storageKey, defaultValue) {
	try {
		const value = localStorage.getItem(storageKey);
		return value ? JSON.parse(value) : defaultValue;
	} catch (e) {
		// Fallback to default value if something goes wrong
		return defaultValue;
	}
}

export default function usePersistentState(defaultValue, key) {
	const storageKey = 'bmblb.persistentHook.' + key;
	const [ value, setter ] = useState(readValueFromLocalStorage(storageKey, defaultValue));

	const customSetter = useCallback(newValue => {
		const actualNewValue = (typeof newValue === 'function') ? newValue(localStorage.getItem(storageKey) ? JSON.parse(localStorage.getItem(storageKey)) : defaultValue) : newValue;
		localStorage.setItem(storageKey, JSON.stringify(actualNewValue));
		setter(actualNewValue);
	}, [ setter ]);

	return [ value,	customSetter ];
}
