import React, { useCallback, useContext, useState } from 'react';
import { Button, Form } from '@atrocit/scl';
import { gql, useMutation, useQuery } from '@apollo/client';
import ButtonArea from '../../lib/forms/ButtonArea';
import RemarkViewLevel from './RemarkViewLevel';
import { PrivilegesContext } from '../../../context/PrivilegesContext';

export default function ShiftTrackRemark({ planningId, trackId }) {
	const privileges = useContext(PrivilegesContext);
	const [ editMode, setEditMode ] = useState(false);
	const [ editRemark, setEditRemark ] = useState('');
	const [ editShowPublicly, setEditShowPublicly ] = useState(false);

	const [ saveRemark ] = useMutation(gql`mutation Mutation($planningId: Int!, $trackId: Int!, $remark: String!, $showPublicly: Boolean!) {
        changePlanningTrackRemark(planningId: $planningId, trackId: $trackId, remark: $remark, showPublicly: $showPublicly) { id }
    }`);

	const remarkQuery = useQuery(gql`query Query($planningId: Int!, $trackId: Int!) {
        findRemarksByPlanningTrack(planningId: $planningId, trackId: $trackId) {
            id,
	        showPublicly,
            remark,
        }
    }`, { variables: { planningId, trackId } });
	const showPublicly = remarkQuery?.data?.findRemarksByPlanningTrack?.[0]?.showPublicly ?? false;
	const remark = remarkQuery?.data?.findRemarksByPlanningTrack?.[0]?.remark;

	const onStartEdit = useCallback(() => {
		if (!privileges.has('WRITE_PLANNING_TRACK_REMARKS')) return;
		setEditRemark(remark ?? '');
		setEditShowPublicly(showPublicly ?? false);
		setEditMode(true);
	}, [ showPublicly, remark, privileges ]);

	if (remarkQuery?.loading) {
		return <>Laden...</>;
	} else if (!editMode) {
		return <div style={{ borderRadius: '4px', border: '1px solid var(--col-grey-200)', margin: '8px 0', padding: '4px' }} onDoubleClick={(e) => { e.preventDefault(); e.stopPropagation(); onStartEdit(); }}>
			{remark == null || remark.trim().length == 0 ?
				<span style={{ color: 'var(--col-grey-400)', fontStyle: 'italic', fontSize: 'var(--fs-11)' }}>Geen opmerkingen</span> :
				<pre style={{ fontWeight: 'bold', color: 'var(--col-red-600)' }}>{remark}</pre>}
		</div>;
	} else {
		return <Form onSubmit={() => {
			saveRemark({ variables: { planningId, trackId, remark: editRemark, showPublicly: editShowPublicly } })
				.finally(() => {
					setEditMode(false);
					setEditRemark(remark);
					setEditShowPublicly(showPublicly);
					remarkQuery.refetch();
				});
		}}>
			<div style={{ paddingBottom: 'var(--u-16)' }}>
				<textarea autoFocus={true} style={{ resize: 'vertical', width: '100%', margin: '8px 0', minHeight: '100px' }} onChange={e => setEditRemark(e.target.value)} value={editRemark} />
				<div>
					<RemarkViewLevel showPublicly={editShowPublicly} setShowPublicly={setEditShowPublicly} />
				</div>
				<ButtonArea>
					<Button type="submit" level="primary"><span className="fa fa-check" />&nbsp; Opslaan</Button>
					<Button onClick={() => setEditMode(false)}>Annuleren</Button>
				</ButtonArea>
			</div>
		</Form>;
	}
}