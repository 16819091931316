import React, { useCallback, useContext, useState } from 'react';
import { Button, Form } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';
import ButtonArea from '../../lib/forms/ButtonArea';
import { PrivilegesContext } from '../../../context/PrivilegesContext';

export default function DayRemark({ viewId, dayStart, remark, onRefresh = () => {} }) {
	const privileges = useContext(PrivilegesContext);
	const [ editMode, setEditMode ] = useState(false);
	const [ editRemark, setEditRemark ] = useState(remark);

	const [ saveRemark ] = useMutation(gql`mutation Mutation($startOfDay: Instant!, $remark: String!, $viewId: Int!) {
		changeDayRemark(viewId: $viewId, startOfDay: $startOfDay, remark: $remark) {
			id, remark, remarkType, createdAt
		}
	}`);

	const onStartEditing = useCallback(() => {
		if (!privileges.has('WRITE_WEEK_REMARKS')) return;
		setEditRemark(remark ?? '');
		setEditMode(true);
	}, [ remark ]);

	if (!editMode) {
		return <div style={{ borderRadius: '4px', border: '1px solid var(--col-grey-200)', margin: '8px 0', padding: '4px' }} onDoubleClick={onStartEditing}>
			{remark == null || remark.trim().length == 0 ?
				<span style={{ color: 'var(--col-grey-400)', fontStyle: 'italic', fontSize: 'var(--fs-11)' }}>Geen opmerkingen</span> :
				<pre style={{ fontWeight: 'bold', color: 'var(--col-red-600)', whiteSpace: 'pre-wrap' }}>{remark}</pre>}
		</div>;
	} else {
		return <Form onSubmit={() => {
			saveRemark({ variables: { viewId: parseInt(viewId, 10), startOfDay: dayStart.toUTC().toISO(), remark: editRemark } })
				.finally(() => {
					setEditMode(false);
					onRefresh();
				});
		}}>
			<textarea style={{ resize: 'vertical', width: '100%', margin: '8px 0', minHeight: '100px' }} onChange={e => setEditRemark(e.target.value)} value={editRemark} />
			<div style={{ margin: '-24px 0 0 0' }}>
				<ButtonArea>
					<Button type="submit" level="primary"><span className="fa fa-check" /></Button>
					<div style={{ marginRight: '-8px' }}>
						<Button onClick={() => setEditMode(false)}><span className="fa fa-times" /></Button>
					</div>
				</ButtonArea>
			</div>
		</Form>;
	}
}