import { Button, ButtonArea, DestructiveButton, Form, LoadingOverlay, Modal } from '@atrocit/scl';
import { gql, useMutation, useQuery } from '@apollo/client';

export default function DuplicateWageTableModal({ id, onClose }) {
	const [ addEditWageTable, addEditWageTableMutation ] = useMutation(gql`mutation Mutation($id: ID, $wageTable: WageTableInput!) {
        addEditWageTable(id: $id, wageTable: $wageTable) { id }
    }`);

	const wageTableQuery = useQuery(gql`query Query($id: ID!){
        wageTable(id: $id){
            id,
	        applyFrom,
            roles {
                id,
            },
            earlyShiftRegularTotal,
            lateShiftRegularTotal,
            nightShiftRegularTotal,
            dayShiftRegularTotal,
            earlyShiftRegularHourly,
            lateShiftRegularHourly,
            nightShiftRegularHourly,
            dayShiftRegularHourly,
            earlyShiftRegularOvertimeHour,
            lateShiftRegularOvertimeHour,
            nightShiftRegularOvertimeHour,
            dayShiftRegularOvertimeHour,
            earlyShiftRegularPremium,
            lateShiftRegularPremium,
            nightShiftRegularPremium,
            dayShiftRegularPremium,
            earlyShiftSaturdayTotal,
            lateShiftSaturdayTotal,
            nightShiftSaturdayTotal,
            dayShiftSaturdayTotal,
            earlyShiftSaturdayHourly,
            lateShiftSaturdayHourly,
            nightShiftSaturdayHourly,
            dayShiftSaturdayHourly,
            earlyShiftSaturdayOvertimeHour,
            lateShiftSaturdayOvertimeHour,
            nightShiftSaturdayOvertimeHour,
            dayShiftSaturdayOvertimeHour,
            earlyShiftSaturdayPremium,
            lateShiftSaturdayPremium,
            nightShiftSaturdayPremium,
            dayShiftSaturdayPremium,
            earlyShiftSundayTotal,
            lateShiftSundayTotal,
            nightShiftSundayTotal,
            dayShiftSundayTotal,
            earlyShiftSundayHourly,
            lateShiftSundayHourly,
            nightShiftSundayHourly,
            dayShiftSundayHourly,
            earlyShiftSundayOvertimeHour,
            lateShiftSundayOvertimeHour,
            nightShiftSundayOvertimeHour,
            dayShiftSundayOvertimeHour,
            earlyShiftSundayPremium,
            lateShiftSundayPremium,
            nightShiftSundayPremium,
            dayShiftSundayPremium,
        }
    }`, { variables: { id } });
	const wageTable = wageTableQuery?.data?.wageTable ?? null;

	function submitTable() {
		// takes all the values from the original table, puts them in a variable and returns that.
		const { id, __typename, ...wageTableData } = wageTable;
		const wageTableDuplicate = {
			...wageTableData,
			active: false,
			applyFrom: wageTable.applyFrom,
			roles: wageTable.roles.map(r => r.id),
		};
		addEditWageTable({ variables: { wageTable: wageTableDuplicate } }).then(() => onClose());
	}

	return <Modal isOpen={true} title="Loontabel dupliceren" onRequestClose={onClose}>
		{(wageTableQuery.loading || addEditWageTableMutation.loading) && <LoadingOverlay />}
		<Form onSubmit={() => submitTable()}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Deze loontabel dupliceren?<br /><br />
			</div>

			<ButtonArea>
				<Button onClick={onClose}>Annuleren</Button>
				<Button level="primary" type="submit">Dupliceren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}