import ButtonArea from '../../lib/forms/ButtonArea';
import {
	Button,
	Checkbox,
	ErrorBanner,
	Form,
	InputField,
	LoadingOverlayProvider,
	LoadingOverlayTrigger,
	Modal,
} from '@atrocit/scl';
import React, { useContext, useEffect, useState } from 'react';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import ColorDot from '../../lib/ui/ColorDot';
import enforceNumberWithTwoDecimals from '../../administration/wagetables/EnforceNumberService';
import * as xlsx from 'xlsx-js-style';
import { DateTime } from 'luxon';
import { useParams } from 'react-router-dom';
import UserContext from '../../../context/UserContext';

export default function ExportPayslipModal({ isOpen, onClose, planning }) {
	const { date } = useParams();
	const [ selectedWorkers, setSelectedWorkers ] = useState(new Set());
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(null);
	const uniqueWorkerIds = new Set(planning.spots.filter(s => s.dockWorker != null && s.dockWorker.user != null).map(spot => spot.dockWorker.user.id));
	const user = useContext(UserContext);

	const initializeSalaries = () => {
		const initialSalaries = {};
		planning.spots.forEach(spot => {
			if (spot.dockWorker && spot.dockWorker.user) {
				let calculatedWage = 0;
				if (spot.workRecord.calculatedWageTarget != null && spot.workRecord.calculatedPremiumTarget != null) {
					// later down the line add overtime hours and calculations for custom times
					calculatedWage = parseFloat(spot.workRecord.calculatedWageTarget) + parseFloat(spot.workRecord.calculatedPremiumTarget);
				}
				initialSalaries[spot.dockWorker.user.id] = calculatedWage.toFixed(2); // format the result to 2 decimal places
			}
		});
		return initialSalaries;
	};

	const [ salaries, setSalaries ] = useState(initializeSalaries);

	const handleSelectAllChange = () => {
		if (uniqueWorkerIds.size == selectedWorkers.size) {
			// Deselect all
			setSelectedWorkers(new Set());
		} else {
			// Select all
			const allWorkerIds = planning.spots
				.filter(s => s.dockWorker != null && s.dockWorker.user != null)
				.map(spot => spot.dockWorker.user.id);
			setSelectedWorkers(new Set(allWorkerIds));
		}
	};

	const handleCheckboxChange = (userId) => {
		setSelectedWorkers(prev => {
			const newSelectedWorkers = new Set(prev);
			if (newSelectedWorkers.has(userId)) {
				newSelectedWorkers.delete(userId);
			} else {
				newSelectedWorkers.add(userId);
			}
			return newSelectedWorkers;
		});
	};

	const handleSalaryChange = (userId, value) => {
		const newSalary = enforceNumberWithTwoDecimals(value);
		setSalaries(prev => ({
			...prev,
			[userId]: newSalary,
		}));
	};

	return <Modal title="Loonbewijzen exporteren" isOpen={isOpen} onRequestClose={onClose}>
		<LoadingOverlayProvider>
			 {loading && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				setLoading(true);
				const workbook = xlsx.utils.book_new();
				const wsData = [];
				let succesfulWrite = false;

				const borderStyle = {
					style: "medium",
				};
				const boldStyle = { font: { bold: true } };
				const linkStyle = { font: { color: { rgb: "0000FF" }, underline: true } };
				const redStyle = { font: { bold: true, color: { rgb: "FF0000" } } };
				const largeBoldStyle = { font: { bold: true, sz: 12 } };
				const extraLargeBoldStyle = { font: { bold: true, sz: 14 } };

				const addBorders = (startRow, endRow, ws) => {
					const columns = [ 'A', 'B', 'C', 'D' ];
					columns.forEach(col => {
						// Apply borders to the top row
						if (ws[`${col}${startRow}`]) {
							ws[`${col}${startRow}`].s = { border: { ...ws[`${col}${startRow}`].s?.border, top: borderStyle } };
						} else {
							ws[`${col}${startRow}`] = { s: { border: { top: borderStyle } } };
						}
						// Apply borders to the bottom row
						if (ws[`${col}${endRow}`]) {
							ws[`${col}${endRow}`].s = { border: { ...ws[`${col}${endRow}`].s?.border, bottom: borderStyle } };
						} else {
							ws[`${col}${endRow}`] = { s: { border: { bottom: borderStyle } } };
						}
					});

					// Apply borders to the left and right columns
					for (let row = startRow; row <= endRow; row++) {
						if (ws[`A${row}`]) {
							ws[`A${row}`].s = { border: { ...ws[`A${row}`].s?.border, left: borderStyle } };
						} else {
							ws[`A${row}`] = { s: { border: { left: borderStyle } } };
						}
						if (ws[`D${row}`]) {
							ws[`D${row}`].s = { border: { ...ws[`D${row}`].s?.border, right: borderStyle } };
						} else {
							ws[`D${row}`] = { s: { border: { right: borderStyle } } };
						}
					}
				};

				let currentRow = 1; // Track the current row
				let isBoortmalt = user.email.includes('boortmalt.com');

				let index = 1;
				selectedWorkers.forEach(s => {
					const spot = planning.spots.find(sp => sp.dockWorker.user.id == s);
					const salary = salaries[s].replace('.', ',');

					const dataSet = [
						[ 'Naam:', spot.dockWorker.user.fullName ?? '', 'Loonboeknummer:', spot.dockWorker.workbookNr ?? '' ],
						[ '', '', '', '' ],
						[ '', '', 'Datum:', DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day').toFormat("dd-MM-yyyy").toString() ?? '' ],
						[ '', '', '', '' ],
						[ 'FIRMASTEMPEL:', '', 'Loon:', '€' + salary ],
						[ '', '', '', '' ],
						[ '', '', 'Handtekening:', '' ],
						[ '', '', '', '' ],
						[ '', '', '', '' ],
						[ '', '', (isBoortmalt ? 'Vincent Dierckx' : 'Hans Peeters'), ('CEELBAAS ') + (isBoortmalt ? 'Boortmalt' : 'ATO') ],
						[ '', '', 'Zandvoort 2 K' + (isBoortmalt ? '350' : '364:'), '' ],
						[ '', '', (isBoortmalt ? 'vincent.dierckx@boortmalt.com' : 'ceelbaas@ato-antwerp.com'), '' ],
						[ '', 'LOONBEWIJS', '', '' ],
					];

					// Add the data to wsData
					dataSet.forEach(row => {
						wsData.push(row);
						currentRow++;
					});

					// Add three empty lines
					wsData.push([ '', '', '', '' ], [ '', '', '', '' ], [ '', '', '', '' ]);
					currentRow += 3;

					// Add empty rows to make sure we don't split up the payslips across pages when printing
					if (index % 3 == 0) {
						wsData.push(
							[ '', '', '', '', '', '' ],
							[ '', '', '', '', '', '' ],
							[ '', '', '', '', '', '' ],
						);
						currentRow += 3;
					}
					index++;
					succesfulWrite = true;
				});

				if (succesfulWrite) {
					const ws = xlsx.utils.aoa_to_sheet(wsData);

					// Apply borders to each block
					let row = 1;
					let index = 1;
					selectedWorkers.forEach(() => {
						const startRow = row;
						row += 13; // Adjust based on the number of rows in each block + the empty lines
						const endRow = row - 1;
						addBorders(startRow, endRow, ws);

						// Apply styles to specific cells
						ws[`B${startRow}`].s = { ...ws[`B${startRow}`].s, ...largeBoldStyle }; // User's full name
						ws[`D${startRow}`].s = { ...ws[`D${startRow}`].s, ...redStyle }; // workbookNr
						ws[`D${startRow + 2}`].s = { ...ws[`D${startRow + 2}`].s, ...boldStyle }; // Date
						ws[`B${startRow + 12}`].s = { ...ws[`B${startRow + 12}`].s, ...extraLargeBoldStyle }; // LOONBEWIJS
						ws[`C${startRow + 11}`].s = { ...ws[`C${startRow + 11}`].s, ...linkStyle }; // Email address


						row += 3; // Skip the empty lines

						// Adjust for additional empty rows added for page breaks
						if (index % 3 == 0) {
							row += 3;
						}
						index++;
					});

					// column widths
					ws['!cols'] = [
						{ wch: 12 },
						{ wch: 24 },
						{ wch: 26 },
						{ wch: 16 },
					];

					xlsx.utils.book_append_sheet(workbook, ws, 'Loonbewijzen ' + DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day').toFormat("yyyy-MM-dd").toString() + ' ' + planning.shiftDefinition.name);
					xlsx.writeFile(workbook, 'Loonbewijzen ' + DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('day').toFormat("yyyy-MM-dd").toString() + ' ' + planning.shiftDefinition.name + '.xlsx', { compression: true });
				} else {
					// show some error to the user
					setError('Exporteren is niet gelukt. Vernieuw de pagina en probeer het opnieuw.');
				}

				setLoading(false);
			}}>
				{error && <>
					<ErrorBanner>
						<span style={{ fontStyle: 'italic' }}>{error}</span>
					</ErrorBanner>
					<br />
				</>}
				<span>Voor welke arbeiders in deze shift wil je loonbewijzen afdrukken?</span>
				<br />
				<span>Controleer of de geschatte bedragen juist zijn voordat je verder gaat.</span>
				<br />
				<br />
				<table className="table" style={{ minWidth: '100%' }}>
					<thead>
						<tr>
							<th width="40"><Checkbox value={uniqueWorkerIds.size == selectedWorkers.size} onChange={handleSelectAllChange} /></th>
							<th>Rol</th>
							<th>Naam</th>
							<th>Loon</th>
						</tr>
					</thead>
					<tbody>
						{planning.spots.filter(s => s.dockWorker != null && s.dockWorker.user != null).map(spot => <tr key={spot.id}>
							<td>
								<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
									<Checkbox
										onChange={() => handleCheckboxChange(spot.dockWorker.user.id)}
										value={selectedWorkers.has(spot.dockWorker.user.id)}
									/>
								</div>
							</td>
							<td>{spot.role == null ? "-" :
								<div>
									<><ColorDot hex={spot.role.colorCode} /> {spot.role.shortCode}</>
								</div>}
							</td>
							<td>{spot.dockWorker.user.fullName}</td>
							<td>
								€<InputField
									type="string"
									value={salaries[spot.dockWorker.user.id] || ''}
									onChange={(value) => handleSalaryChange(spot.dockWorker.user.id, value)}
									style={{ width: '100px' }}
								/>
							</td>
						</tr>)}
					</tbody>
				</table>

				<ButtonArea style={{ justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 'var(--u-16)' }}>
						<ValidatedButton type="submit" level="primary" validations={[
							{ valid: selectedWorkers.size > 0, error: 'Je moet minstens één arbeider selecteren' },
						]}><span className="fa fa-download" />&nbsp; Exporteren</ValidatedButton>
					</div>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}