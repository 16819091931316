import { Form, WithLabel, InputField, Checkbox } from "@atrocit/scl";
import React, { useEffect, useState } from "react";
import ButtonArea from "../lib/forms/ButtonArea";
import ValidatedButton from "../lib/forms/ValidatedButton";
import { useNavigate } from "react-router";
import { gql, useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import SelectField from '../lib/selectfields/SelectField';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import RoleSelectField from '../lib/selectfields/RoleSelectField';
import AuthRoleSelectField from '../lib/selectfields/AuthRoleField';

export default function AddEditUser() {
	const navigate = useNavigate();
	const { id } = useParams();

	const [ email, setEmail ] = useState('');
	const [ firstName, setFirstName ] = useState('');
	const [ fullName, setFullName ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ authRole, setAuthRole ] = useState('');
	const [ isDockWorker, setIsDockWorker ] = useState(false);
	const [ workbookNr, setWorkbookNr ] = useState('');
	const [ category, setCategory ] = useState('');
	const [ allowedRoles, setAllowedRoles ] = useState([]);
	const [ cepaWages, setCepaWages ] = useState(false);
	const [ editingMode, setEditingMode ] = useState(false);

	const { data, loading, refetch } = useQuery(gql`query Query($id: Int!){
        user(id: $id){
            id,
            email,
            firstName,
            fullName,
	        phoneNumber,
	        authRoles { id },
	        dockWorkerRole {
		        id, workbookNr, category,
		        cepaWages,
		        allowedRoles {
			        id
                }
            },
        }
    }`, { variables: { id } });

	const [ addUser ] = useMutation(gql`mutation Mutation($email: String, $firstName: String!, $fullName: String!, $phoneNumber: String, $authRole: ID!, $isDockWorker: Boolean!, $workbookNr: String, $category: String, $cepaWages: Boolean!, $allowedRoles: [ Int! ]!) {
        addUser(email: $email, firstName: $firstName, fullName: $fullName, phoneNumber: $phoneNumber, authRoleId: $authRole, isDockWorker: $isDockWorker, workbookNr: $workbookNr, category: $category, cepaWages: $cepaWages, allowedRoles: $allowedRoles) { id }
    } `);

	const [ editUser ] = useMutation(gql `mutation Mutation($id: Int!, $email: String, $firstName: String!, $fullName: String!, $phoneNumber: String, $authRole: ID!, $isDockWorker: Boolean!, $workbookNr: String, $category: String, $cepaWages: Boolean!, $allowedRoles: [ Int! ]!) {
        editUser(userId: $id, email: $email, firstName: $firstName, fullName: $fullName, phoneNumber: $phoneNumber, authRoleId: $authRole, isDockWorker: $isDockWorker, workbookNr: $workbookNr, category: $category, cepaWages: $cepaWages, allowedRoles: $allowedRoles) { id }
    }`);

	useEffect(() => {
		if (data == null) return;
		setEmail(data?.user.email || '');
		setFirstName(data?.user.firstName);
		setFullName(data?.user.fullName);
		setPhoneNumber(data?.user.phoneNumber);
		setAuthRole(data?.user.authRoles[0]?.id);
		setIsDockWorker(data?.user?.dockWorkerRole != null);
		setWorkbookNr(data?.user?.dockWorkerRole != null ? data?.user?.dockWorkerRole.workbookNr : '');
		setCategory(data?.user?.dockWorkerRole != null ? data?.user?.dockWorkerRole.category : '');
		setCepaWages(data?.user?.dockWorkerRole != null ? data?.user?.dockWorkerRole.cepaWages : false);
		setEditingMode(true);
		setAllowedRoles((data?.user?.dockWorkerRole?.allowedRoles ?? []).map(r => r.id));
	}, [ data ]);

	return <>
		<TopBar title="Gebruiker toevoegen" />
		<div className='page'>
			<div className="app-ui">
				<PageTop
					breadcrumbSegments={[
						{ link: '/users', label: 'Gebruikers' },
						{ link: '', label: editingMode ? data?.user.fullName : 'Nieuwe gebruiker' },
					]} />
				<Form
					style={{ width: 'var(--u-768)', justifyContent: 'center', alignItems: 'center', marginTop: 'var(--u-24)' }}
					onSubmit={() => {
						const roleIds = allowedRoles.map(ar => parseInt(ar, 10));
						if (editingMode) {
							editUser({ variables: { id, email: email == '' ? null : email, firstName, fullName, phoneNumber, authRole, isDockWorker, workbookNr, category, cepaWages, allowedRoles: roleIds } }).then(() => navigate('/users'));
						} else {
							addUser({ variables: { email: email == '' ? null : email, firstName, fullName, phoneNumber, authRole, isDockWorker, workbookNr, category, cepaWages, allowedRoles: roleIds } }).then(() => navigate('/users'));
						}
					}}>
					<WithLabel label={'E-mail'}>
						<InputField type={'email'} onChange={setEmail} value={email} />
					</WithLabel>
					<WithLabel label={'Voornaam'}>
						<InputField onChange={setFirstName} value={firstName} />
					</WithLabel>
					<WithLabel label={'Volledige naam'}>
						<InputField onChange={setFullName} value={fullName} />
					</WithLabel>
					<WithLabel label="Telefoonnummer">
						<InputField onChange={setPhoneNumber} value={phoneNumber} />
					</WithLabel>
					<WithLabel label="Gebruikerstype">
						<AuthRoleSelectField
							isMulti={false}
							onChange={setAuthRole}
							value={authRole} />
					</WithLabel>
					<WithLabel label="Havenwerker?">
						<Checkbox value={isDockWorker} onChange={() => setIsDockWorker(!isDockWorker)} />
					</WithLabel>
					{isDockWorker && <>
						<WithLabel label="Werkboeknummer">
							<InputField onChange={setWorkbookNr} value={workbookNr} />
						</WithLabel>
						<WithLabel label="Categorie">
							<InputField onChange={setCategory} value={category} />
						</WithLabel>
						<WithLabel label="Toegestane rollen">
							<RoleSelectField isMulti={true} onChange={newRoles => setAllowedRoles(newRoles)} value={allowedRoles} />
						</WithLabel>
						<WithLabel label="Verloning via CEPA">
							<Checkbox onChange={setCepaWages} value={cepaWages} />
						</WithLabel>
					</>}
					<ButtonArea>
						<ValidatedButton
		                    type="submit"
							level="primary"
		                    validations={[
		                        { valid: firstName.length > 0, error: "Een naam mag niet leeg zijn" },
		                        { valid: fullName.length > 0, error: "Een volledige naam mag niet leeg zijn" },
			                    { valid: authRole != null && authRole != '', error: 'Gebruikerstype mag niet leeg zijn' },
		                    ]}>
							<span className="fa fa-check" />&nbsp; Gebruiker opslaan
						</ValidatedButton>
					</ButtonArea>
				</Form>
			</div>
		</div>
	</>;
}