import FormattedDateTime from '../lib/formatting/FormattedDateTime';
import { AbsenceType } from '../absence/AbsenceType';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';

export default function AbsenceSummary({ absences, date }) {

	const weekStartDate = DateTime.fromFormat(date, 'yyyy-MM-dd').startOf('week').minus({ days: 2 }); // Saturday, start of week
	const weekEndDate = weekStartDate.plus({ days: 7 }); // Friday, end of week

	const absencesQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
        absencesInRange(start: $start, end: $end) {
            id, start, end, user { id, firstName, fullName }, absenceType, absenceStatus, remark
        }
    }`, { variables: { start: weekStartDate.toUTC().toISO(), end: weekEndDate.toUTC().toISO() } });
	const furtherAbsences = absencesQuery?.data?.absencesInRange ?? [];

	// is the user absent on a given date?
	function isAbsent(user, date) {
		return furtherAbsences.some(a => a.user.id == user.id && DateTime.fromISO(a.start) <= date && date <= DateTime.fromISO(a.end));
	}

	return <table className="table table-fw">
		<colgroup>
			<col width="24" />
			<col width="170" />
			<col width="135" />
			<col width="135" />
			<col width="90" />
			<col width="*" />
			<col width="24" />
			<col width="24" />
			<col width="24" />
			<col width="24" />
			<col width="24" />
			<col width="24" />
			<col width="24" />
		</colgroup>
		<thead>
			<tr>
				<th />
				<th>Wie</th>
				<th>Van</th>
				<th>Tot</th>
				<th>Type</th>
				<th>Opmerking</th>
				<th>Za</th>
				<th>Zo</th>
				<th>Mo</th>
				<th>Di</th>
				<th>Wo</th>
				<th>Do</th>
				<th>Vr</th>
			</tr>
		</thead>
		<tbody>
			{absences.map(a => <tr key={a.id}>
				<td className="tbl-center">{a.absenceStatus == 'REQUESTED' ? <span className="fa fa-question-circle" style={{ color: 'var(--col-grey-700)' }} /> : <span className="fa fa-check-circle" style={{ color: 'var(--col-primary-600)' }} />}</td>
				<td>{a.user?.fullName}</td>
				<td><FormattedDateTime value={a.start} /></td>
				<td><FormattedDateTime value={a.end} /></td>
				<td><AbsenceType type={a.absenceType} /></td>
				<td>{a.remark}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 1 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 2 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 3 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 4 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 5 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
				<td className="tbl-center">{isAbsent(a.user, weekStartDate.plus({ days: 6 })) ? <span className="fa fa-plane" style={{ color: 'var(--col-grey-500)' }} /> : ''}</td>
			</tr>)}

			{absences.length == 0 && <tr>
				<td colSpan={4}>Geen absenten vandaag</td>
			</tr>}
		</tbody>
	</table>;
}