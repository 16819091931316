import React from 'react';
import SelectField from '../../lib/selectfields/SelectField';

export default function SpotRemarkViewLevel({ remarkVisibility, setRemarkVisibility }) {
	return <SelectField
		options={[
			{ value: 'PUBLIC', label: 'Zichtbaar voor alle arbeiders', icon: 'fa fa-users' },
			{ value: 'WORKER_IN_SPOT_AND_ADMIN', label: 'Alleen zichtbaar voor de ceelbaas, HR en deze arbeider', icon: 'fa fa-user' },
			{ value: 'ADMIN_ONLY', label: 'Alleen zichtbaar voor de ceelbaas en HR', icon: 'fa fa-lock' },
		]}
		formatOptionLabel={(option) => <>&nbsp;<span className={option.icon} />&nbsp;{option.label}</>}
		onChange={setRemarkVisibility}
		value={remarkVisibility} />;
}