import { Button, ButtonArea, DestructiveButton, Form, LoadingOverlay, Modal } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';

export default function DeleteSpecialDay({ id, onClose }) {
	const [ deleteSpecialDay, deleteSpecialDayMutation ] = useMutation(gql`mutation Mutation($id: ID!) {
		deleteSpecialDay(id: $id)
	}`);

	return <Modal isOpen={true} title="Feestdag verwijderen" onRequestClose={onClose}>
		{deleteSpecialDayMutation.loading && <LoadingOverlay />}
		<Form onSubmit={() => deleteSpecialDay({ variables: { id } }).then(() => onClose())}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Hiermee wordt deze feestdag verwijderd. Dit kan niet ongedaan gemaakt worden. Weet je het zeker?<br /><br />
			</div>

			<ButtonArea>
				<Button onClick={onClose}>Annuleren</Button>

				<DestructiveButton level="primary" type="submit">
					<span className="fa fa-trash" />&nbsp; Verwijderen
				</DestructiveButton>
			</ButtonArea>
		</Form>
	</Modal>;
}