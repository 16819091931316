import TopBar from '../TopBar';
import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../context/UserContext';
import { useParams } from 'react-router-dom';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import { DateTime } from 'luxon';
import {
	Button,
	DateField,
	DestructiveButton,
	Form,
	LoadingOverlay,
	Modal,
	SelectField,
	WithLabel,
} from '@atrocit/scl';
import ValidatedButton from '../lib/forms/ValidatedButton';
import ButtonArea from '../lib/forms/ButtonArea';
import { useNavigate } from 'react-router';
import { FormattedDate } from 'react-intl';
import { AbsenceType } from './AbsenceType';
import AbsenceStatusIndicator from "./AbsenceStatusIndicator";

export default function ViewAbsenceRequest() {
	const user = useContext(userContext);
	const navigate = useNavigate();
	const { id } = useParams();
	const isNew = id == null;
	const [ startDate, setStartDate ] = useState(DateTime.now().startOf('day').startOf('day'));
	const [ endDate, setEndDate ] = useState(DateTime.now().plus({ days: 1 }).startOf('day').endOf('day'));
	const [ absenceStatus, setAbsenceStatus ] = useState('DRAFT');
	const [ absenceType, setAbsenceType ] = useState('');
	const [ remark, setRemark ] = useState('');
	const [ submitModalOpen, setSubmitModalOpen ] = useState(false);
	const [ deleteModalOpen, setDeleteModalOpen ] = useState(false);
	const [ editMode, setEditMode ] = useState(isNew);
	const [ editable, setEditable ] = useState(true);

	const [ getMyAbsence, { loading: loadingQuery } ] = useLazyQuery(gql`query Query($id: Int!) {
		myAbsence(id: $id) {
			id, user { id }, start, end, cepaLinesForWeekend, absenceStatus, absenceType, remark
		}
	}`);

	const [ addAbsenceMutation, { loading: loadingMutation } ] = useMutation(gql`mutation Mutation($user: Int!, $start: Instant!, $end: Instant!, $weekendLines: Boolean!, $absenceStatus: AbsenceStatus!, $absenceType: AbsenceType!, $remark: String) {
		addAbsence(userId: $user, start: $start, end: $end, weekendLines: $weekendLines, status: $absenceStatus, absenceType: $absenceType, remark: $remark) { id }
	}`);

	const [ changeAbsenceMutation, { loading: loadingMutation2 } ] = useMutation(gql`mutation Mutation($aid: Int!, $user: Int!, $start: Instant!, $end: Instant!, $weekendLines: Boolean!, $absenceStatus: AbsenceStatus!, $absenceType: AbsenceType!, $remark: String) {
        changeAbsence(absenceId: $aid, userId: $user, start: $start, end: $end, weekendLines: $weekendLines, status: $absenceStatus, absenceType: $absenceType, remark: $remark) { id }
    }`);

	const [ deleteAbsenceMutation, { loading: loadingMutation3 } ] = useMutation(gql`mutation Mutation($aid: Int!) {
        deleteAbsence(absenceId: $aid)
    }`);

	function onSave() {
		if (!editable) return;
		if (!isNew) {
			changeAbsenceMutation({
				variables: {
					aid: id,
					user: user.id,
					start: startDate.toUTC().toISO(),
					end: endDate.toUTC().toISO(),
					weekendLines: false,
					absenceStatus: absenceStatus,
					absenceType: absenceType,
					remark,
				},
			}).finally(() => setEditMode(false));
		} else {
			addAbsenceMutation({
				variables: {
					user: user.id,
					start: startDate.toUTC().toISO(),
					end: endDate.toUTC().toISO(),
					weekendLines: false,
					absenceStatus: absenceStatus,
					absenceType: absenceType,
					remark,
				},
			}).then(r => navigate('/myAbsences/' + r.data.addAbsence.id)).finally(() => setEditMode(false));
		}
	}

	function onSubmit() {
		if (!editable || editMode) return;
		changeAbsenceMutation({
			variables: {
				aid: id,
				user: user.id,
				start: startDate.toUTC().toISO(),
				end: endDate.toUTC().toISO(),
				weekendLines: false,
				absenceStatus: 'REQUESTED',
				absenceType: absenceType,
				remark,
			},
		}).finally(() => navigate('/myAbsences'));
	}

	function onDelete() {
		if (!editable) return;
		if (!isNew) {
			deleteAbsenceMutation({
				variables: {
					aid: id,
				},
			}).finally(() => navigate('/myAbsences'));
		}
	}

	useEffect(() => {
		if (isNew) return;
		getMyAbsence({ variables: { id } })
			.then(({ data }) => {
				const absence = data?.myAbsence;
				setStartDate(DateTime.fromISO(absence.start));
				setEndDate(DateTime.fromISO(absence.end));
				setAbsenceStatus(absence?.absenceStatus);
				setAbsenceType(absence?.absenceType);
				setRemark(absence?.remark);
				if (absence?.absenceStatus == 'DENIED' || absence?.absenceStatus == 'APPROVED') {
					setEditable(false);
				}
			});
	}, [ id, editMode ]);

	return <>
		{(loadingQuery || loadingMutation || loadingMutation2 || loadingMutation3) && <LoadingOverlay />}
		{submitModalOpen && <Modal title="Aanvraag indienen" isOpen={submitModalOpen} onRequestClose={() => setSubmitModalOpen(false)}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Weet je zeker dat je de aanvraag wilt indienen?<br />
				De aanvraag zal zichtbaar worden voor de ceelbaas en voor HR.<br /><br />
			</div>

			<ButtonArea>
				<Button level="primary" onClick={() => onSubmit()}><span className="fa fa-paper-plane" />&nbsp; Indienen</Button>
				<Button onClick={() => setSubmitModalOpen(false)}>Annuleren</Button>
			</ButtonArea>
		</Modal>}

		{deleteModalOpen && <Modal title="Aanvraag verwijderen" isOpen={deleteModalOpen} onRequestClose={() => setDeleteModalOpen(false)}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Weet je zeker dat je de aanvraag wilt verwijderen?<br /><br />
			</div>

			<ButtonArea>
				<DestructiveButton level="primary" className="btn-danger" onClick={() => onDelete()}><span className="fa fa-trash" />&nbsp; Verwijderen</DestructiveButton>
				<Button onClick={() => setDeleteModalOpen(false)}>Annuleren</Button>
			</ButtonArea>
		</Modal>}

		<TopBar title={editMode ? (isNew ? 'Nieuwe absentieaanvraag' : 'Absentieanvraag bewerken') : 'Absentieaanvraag'} />
		<div className="grey-page-bg">
			<div className="page page-mobile">

				<br />
				<Button onClick={() => navigate('/myAbsences')}><span className="fa fa-arrow-left" />&nbsp; Terug</Button>
				<br />
				<br />

				{editMode && editable ?
					<Form onSubmit={() => onSave()}>
						<WithLabel label="Van">
							<DateField
								onChange={d => {
									const target = d?.startOf('day');
									setStartDate(target);
									if (target != null && endDate != null && target > endDate) {
										setEndDate(target.endOf('day'));
									}
								}}
								value={startDate}
							/>
						</WithLabel>
						<WithLabel label="Tot">
							<DateField
								onChange={d => {
									const target = d?.endOf('day');
									setEndDate(target);
									if (target != null && startDate != null && target < startDate) {
										setStartDate(target.startOf('day'));
									}
								}}
								value={endDate}
							/>
						</WithLabel>

						<WithLabel label="Type">
							<SelectField
								options={[
									{ value: 'VACATION', label: 'Vakantie' },
									{ value: 'SICK_LEAVE', label: 'Ziekte' },
									{ value: 'SENIORITY', label: 'Anciënniteit' },
									{ value: 'TIME_FOR_TIME', label: 'HVD' },
									{ value: 'SAVINGS_RECORD', label: 'Briefje' },
									{ value: 'UNEMPLOYMENT', label: 'DOP' },
									{ value: 'OFFICIAL_REASON', label: 'Attest' },
									{ value: 'PARENTAL_LEAVE', label: 'Ouderschap' },
									{ value: 'TIME_CREDIT', label: 'Tijdkrediet' },
									{ value: 'SPECIAL_LEAVE', label: 'Klein verlet' },
									{ value: 'PARTTIME_3_5', label: '3/5' },
									{ value: 'PARTTIME_4_5', label: '4/5' },
									{ value: 'OTHER', label: 'Overig' },
								]}
								onChange={setAbsenceType}
								value={absenceType} />
						</WithLabel>

						<WithLabel label="Toelichting">
							<input
								className="scl-form-element"
								type="text"
								onChange={e => setRemark(e.target.value)}
								value={remark} />
						</WithLabel>

						<ButtonArea>
							<ValidatedButton
								level="primary"
								type="submit"
								validations={[
									{ valid: absenceType != null && absenceType.length > 0, error: "De type mag niet leeg zijn" },
									{ valid: startDate != null, error: "De startdatum mag niet leeg zijn" },
									{ valid: endDate != null, error: "De einddatum mag niet leeg zijn" },
									{ valid: startDate <= endDate, error: "De startdatum mag niet later zijn dan de einddatum" },
								]}>
								<span className="fa fa-check" />&nbsp; Opslaan
							</ValidatedButton>
							<Button onClick={() => (isNew ? navigate('/myAbsences') : setEditMode(false))}>Annuleren</Button>
						</ButtonArea>
					</Form>
					:
					<>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
							<h2><AbsenceType type={absenceType} /></h2>
							<AbsenceStatusIndicator status={absenceStatus} />
						</div>
						<br />
						<br />
						<WithLabel label="Periode">
							<span style={{ whiteSpace: 'nowrap' }}><FormattedDate value={DateTime.fromISO(startDate).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="long"/></span>
							{startDate < endDate && <>
								<span style={{ margin: '0 var(--u-4)', color: 'var(--col-grey-500)', fontSize: 'var(--fs-9)' }}> t/m </span>
								<span style={{ whiteSpace: 'nowrap' }}><FormattedDate value={DateTime.fromISO(endDate).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="long"/></span>
							</>}
						</WithLabel>

						<br />

						<WithLabel label="Toelichting">
							<span>{remark.trim() || '-'}</span>
						</WithLabel>

						<br />

						<ButtonArea style={{ flexWrap: 'wrap', flexDirection: 'row', justifyContent: 'space-between' }}>
							<div style={{ display: 'flex', gap: 'var(--u-32)' }}>
								{!editMode && <Button onClick={() => setDeleteModalOpen(true)} disabled={!editable}><span className="fa fa-trash" /></Button>}
								{!editMode && <Button onClick={() => setEditMode(true)} disabled={!editable}><span className="fa fa-pencil" /></Button>}
							</div>
							{!editMode && <Button level="primary" onClick={() => setSubmitModalOpen(true)} disabled={absenceStatus != 'DRAFT'}><span className="fa fa-paper-plane" />&nbsp; Indienen</Button>}
						</ButtonArea>
						<br />
						<small>
							{(absenceStatus == 'APPROVED' || absenceStatus == 'DENIED') &&
								<span>Deze aanvraag is al {absenceStatus == 'APPROVED' ? 'goedgekeurd' : 'afgewezen'} en kan niet meer bewerkt of verwijderd worden.</span>}
							{(absenceStatus == 'REQUESTED') &&
								<span>Deze aanvraag is al aangevraagd. Het kan nog steeds bewerkt of verwijderd worden.</span>}
						</small>
					</>
				}
			</div>
		</div>
	</>;
}