import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import TopBar from '../TopBar';
import { BarChart, Bar, Cell, CartesianGrid, LabelList, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { WithLabel, DateField } from '@atrocit/scl';

export default function SavingsRecordsStats({ savingsRecordFlowStats }) {
	const [ start, setStart ] = useState(DateTime.now().minus({ month: 1 }).startOf('month'));
	const [ end, setEnd ] = useState(DateTime.now().minus({ month: 1 }).endOf('month'));

	const recordsQuery = useQuery(gql`
		query Query($lowerbound: Instant!, $upperbound: Instant!) {
			savingsRecordFlowStats(lowerbound: $lowerbound, upperbound: $upperbound) {
				date,
				wageAdded,
				premiumAdded,
				wagePaid,
				premiumPaid,
			}
			
			savingRecordTotalsAtDate(date: $lowerbound) {
				wageTotal,
				premiumTotal
			}
		}
	`, { variables: { lowerbound: start.toUTC().toISO(), upperbound: end.toUTC().toISO() } });
	const records = recordsQuery?.data?.savingsRecordFlowStats ?? [];
	const startingTotals = recordsQuery?.data?.savingRecordTotalsAtDate ?? { wageTotal: 0, premiumTotal: 0 };

	const dataPoints = useMemo(() => {
		const dataPoints = [];
		let runningTotalWage = startingTotals.wageTotal;
		let runningTotalPremium = startingTotals.premiumTotal;
		for (let i = 0; i < records.length; i++) {
			const r = records[i];
			runningTotalWage += r.wageAdded + r.wagePaid;
			runningTotalPremium += r.premiumAdded + r.premiumPaid;
			dataPoints.push({
				...r,
				runningTotalWage,
				runningTotalPremium,
				runningGrandTotal: runningTotalWage + runningTotalPremium,
				netEffect: r.wageAdded + r.premiumAdded + r.wagePaid + r.premiumPaid,
			});
		}
		return dataPoints;
	}, [ records, startingTotals ]);


	return <>
		<TopBar title={'Briefjesstatistieken'} />

		<div className="grey-page-bg">
			<div className="page">

				<div style={{ display: 'flex', gap: 'var(--u-16)' }}>
					<WithLabel label="Van">
						<DateField onChange={d => setStart(d.startOf('day'))} value={start} />
					</WithLabel>
					<WithLabel label="Tot">
						<DateField onChange={d => setEnd(d.endOf('day'))} value={end} />
					</WithLabel>
				</div>

				<br />
				<h2 style={{ paddingLeft: 'var(--u-64)' }}>Per dag in/uit (€)</h2>
				<BarChart data={dataPoints} width={1200} height={200}>
					<CartesianGrid vertical={false} />

					<Tooltip
						cursor={false}
						labelStyle={{ fontWeight: 'bold' }}
						labelFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd-MM-yyyy')}
						formatter={(value, name, props) => [ Number(value).toFixed(1), name ]} />
					<Bar dataKey="wageAdded" stackId="in" name="Briefjes gezet (loon)" fill="var(--col-green-600)" />
					<Bar dataKey="premiumAdded" stackId="in" name="Briefjes gezet (premie)" fill="var(--col-green-400)" />
					<Bar dataKey="wagePaid" stackId="out" name="Briefjes uitbetaald (loon)" fill="var(--col-red-600)" />
					<Bar dataKey="premiumPaid" stackId="out" name="Briefjes uitbetaald (premie)" fill="var(--col-red-400)" />
					<XAxis dataKey="date" tickLine={false} tickMargin={10} axisLine={true} tickFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd MMM')} />
					<YAxis domain={[ 0, 'dataMax' ]} scale="linear" tickFormatter={(value: any) => '€ ' + Math.round(value)} />
				</BarChart>

				<br /><br />
				<h2 style={{ paddingLeft: 'var(--u-64)' }}>Per dag in/uit netto (€)</h2>
				<BarChart data={dataPoints} width={1200} height={200}>
					<CartesianGrid vertical={false} />
					<Tooltip
						cursor={false}
						labelStyle={{ fontWeight: 'bold' }}
						labelFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd-MM-yyyy')}
						formatter={(value, name, props) => [ Number(value).toFixed(1), name ]} />
					<Bar dataKey="netEffect" name="Saldomutatie" fill="var(--col-blue-800)">
						<LabelList position="top" dataKey="netEffect" formatter={(value: any) => (value >= 0 ? '+' : '-') + '€' + Math.abs(Math.round(value))} fillOpacity={1} fontSize={10} />
						{dataPoints.map((p: any) => <Cell key={p.date} fill={p.netEffect >= 0 ? 'var(--col-green-500)' : 'var(--col-red-500)'} />)}
					</Bar>
					<XAxis dataKey="date" tickLine={false} tickMargin={10} axisLine={true} tickFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd MMM')} />
					<YAxis padding={{ bottom: 20, top: 15 }} scale="linear" tickFormatter={(value: any) => '€ ' + Math.round(value)} />
				</BarChart>

				<br /><br />
				<h2 style={{ paddingLeft: 'var(--u-64)' }}>Saldo-ontwikkeling per dag (€)</h2>
				<LineChart data={dataPoints} width={1200} height={200}>
					<CartesianGrid vertical={false} />
					<Tooltip
						cursor={false}
						labelStyle={{ fontWeight: 'bold' }}
						labelFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd-MM-yyyy')}
						formatter={(value, name, props) => [ Number(value).toFixed(1), name ]} />
					<Line dataKey="runningGrandTotal" stroke="var(--col-blue-500)" name="Loon+premie saldo" type="natural" strokeWidth={2} dot={false} formatter={(value: any) => '€ ' + value.toFixed(2)} />
					<XAxis dataKey="date" tickLine={false} tickMargin={10} axisLine={true} tickFormatter={(value: any) => DateTime.fromISO(value).toFormat('dd MMM')} />
					<YAxis scale="linear" domain={[ (dataMin: number) => dataMin / 1.01, (dataMax: number) => dataMax * 1.01 ]} tickFormatter={(value: any) => '€ ' + Math.round(value / 1000) + 'k'} />
				</LineChart>
			</div>
		</div>
	</>;
}