import ColorDot from '../ui/ColorDot';
import SelectField from './SelectField';
import React from 'react';

export default function ColorSelectField({ onChange, value }) {
	return <SelectField
		options={[
			{ value: '#811', label: 'Bordeaux' },
			{ value: '#d22', label: 'Rood' },
			{ value: '#fa0', label: 'Oranje' },
			{ value: '#dc0', label: 'Geel' },
			{ value: '#3b0', label: 'Groen' },
			{ value: '#150', label: 'Donkergroen' },
			{ value: '#088', label: 'Turquoise' },
			{ value: '#07f', label: 'Blauw' },
			{ value: '#028', label: 'Marineblauw' },
			{ value: '#a3f', label: 'Paars' },
			{ value: '#c38', label: 'Roze' },
			{ value: '#840', label: 'Bruin' },
			{ value: '#ccc', label: 'Grijs' },
			{ value: '#333', label: 'Zwart' },
		]}
		formatOptionLabel={option => <span>&nbsp;<ColorDot hex={option.value} /> &nbsp;{option.label}</span>}
		onChange={col => onChange(col?.value)}
		value={value} />;
}