import TopBar from '../../TopBar';
import PageTop from '../../lib/ui/PageTop';
import { gql, useQuery } from '@apollo/client';
import { FormattedDate } from 'react-intl';
import ColorDot from '../../lib/ui/ColorDot';
import { Button, DestructiveButton, LinkButton, WithLabel } from '@atrocit/scl';
import React, { useState } from 'react';
import DeleteWageTableModal from './DeleteWageTableModal';
import DuplicateWageTableModal from './DuplicateWageTableModal';
import RoleSelectField from '../../lib/selectfields/RoleSelectField';

const PAGE_SIZE = 20;

export default function WageTableOverview() {
	const [ page, setPage ] = useState(1);
	const [ deleteModalId, setDeleteModalId ] = useState(null);
	const [ duplicateWageTableId, setDuplicateWageTableId ] = useState(null);
	const [ selectedRoles, setSelectedRoles ] = useState([ ]);

	const wageTableQuery = useQuery(gql`query Query($offset: Int!, $limit: Int!, $roleIds: [Int]) {
		wageTables(offset: $offset, limit: $limit, roleIds: $roleIds) {
			id,
			active,
			applyFrom,
			roles { 
				id,
				colorCode,
				shortCode,
			}
		}
	}`, { variables: { offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, roleIds: selectedRoles.map(role => parseInt(role, 10)) } });
	const wageTables = wageTableQuery?.data?.wageTables ?? [];

	function overlapsForRole(wageTable, wageTables) {

		if (wageTable.active === false) {
			return false;
		}

		// Filter out the current wageTable from the list and any non active tables
		const otherWageTables = wageTables.filter(wt => wt.id !== wageTable.id && wt.active);

		// Check for overlaps
		for (let otherWageTable of otherWageTables) {
			if (otherWageTable.applyFrom === wageTable.applyFrom) {
				// Check if any roles are in common
				const commonRoles = otherWageTable.roles.filter(role => wageTable.roles.some(r => r.id === role.id));
				if (commonRoles.length > 0) {
					return true;
				}
			}
		}

		return false;
	}

	return <>
		{deleteModalId != null && <DeleteWageTableModal id={deleteModalId} onClose={() => { setDeleteModalId(null); wageTableQuery.refetch(); }} />}
		{duplicateWageTableId != null && <DuplicateWageTableModal id={duplicateWageTableId} onClose={() => { setDuplicateWageTableId(null); wageTableQuery.refetch(); }} />}
		<TopBar title="Alle CEPA loontabellen" />
		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/administration', label: 'CEPA' }, { link: '/administration/wagetables', label: 'Loontabellen' } ]}>
					<LinkButton to="/administration/wagetables/create"><span className="fa fa-plus" />&nbsp; Nieuwe loontabel</LinkButton>
				</PageTop>

				<WithLabel label="Rol filter">
					<RoleSelectField onChange={setSelectedRoles} value={selectedRoles} isMulti={true}/>
				</WithLabel>

				<table className="table table-fw">
					<colgroup>
						<col width="40" />
						<col width="90" />
						<col width="*" />
						<col width="120" />
					</colgroup>
					<thead>
						<tr>
							<th>Actief</th>
							<th>Ingangsdatum</th>
							<th>Rollen</th>
							<th>Acties</th>
						</tr>
					</thead>
					<tbody>
						{wageTables != null && wageTables.length > 0 ?
							wageTables.map(wt => <tr key={wt.id}>
								<td>
									<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
										{wt.active ? <span className="fa fa-check-circle green" /> : <span className="fa fa-times-circle red" />}
										{overlapsForRole(wt, wageTables) && <span title="Er is nog een actieve tabel met dezelfde ingangsdatum die betrekking heeft op enkele van dezelfde rollen." className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} />}
									</div>
								</td>
								<td><FormattedDate value={wt.applyFrom} day="2-digit" month="2-digit" year="numeric" /></td>
								<td>
									<div style={{ display: 'flex', flexDirection: 'row', gap: '12px', flexWrap: 'wrap' }}>
										{wt.roles
											.sort((a, b) => a.shortCode.localeCompare(b.shortCode))
											.map(role => (
												<div key={role.id} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px' }}>
													<ColorDot hex={role.colorCode || '#fff'} />
													{role.shortCode}
												</div>
											))}
									</div>
								</td>

								<td>
									<div style={{ display: 'flex', gap: 'var(--u-8)', justifyContent: 'center' }}>
										<LinkButton to={'/administration/wagetables/edit/' + wt.id}><span className="fa fa-pencil" /></LinkButton>
										<Button onClick={() => setDuplicateWageTableId(wt.id)}><span className="fa fa-files-o"/></Button>
										<DestructiveButton onClick={() => setDeleteModalId(wt.id)}><span className="fa fa-trash-o" /></DestructiveButton>
									</div>
								</td>
							</tr>)
							:
							<tr>
								<td colSpan="4" className="text-center">Geen loontabellen gevonden.</td>
							</tr>
						}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}