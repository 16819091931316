import RoleSelectField from '../../lib/selectfields/RoleSelectField';
import DockWorkerSelectField from '../../lib/selectfields/DockWorkerSelectField';
import SpotRemark from './SpotRemark';
import { gql, useMutation } from '@apollo/client';
import { FormattedTime } from 'react-intl/lib';
import React from 'react';

export default function ReadOnlySpot({ spot, canChangeRemark = false, duplicateUserIds, absentUserIds, alreadyPlannedUserIds, usersInAdjacentShift }) {
	const roleId = spot?.role?.id;
	const dockWorkerId = spot?.dockWorker?.id;

	const [ changeSpotRemark ] = useMutation(gql`mutation Mutation($spotId: Int!, $remark: String!) {
        changeSpotRemark(spotId: $spotId, remark: $remark) { id }
    }`);

	return <div className="spot">
		<div style={{ display: 'grid', gridTemplateColumns: '12px 96px 1fr 24px', gap: 'var(--u-4)' }}>
			<div style={{ alignItems: 'center', display: 'flex' }}>
				{spot.externalReference != null && spot.externalReference.length > 0 && <span className="fa fa-paper-plane" style={{ fontSize: '0.8em', color: 'var(--col-grey-600)' }} title="Extern ingeschoten" />}
			</div>
			<label style={{ padding: 0 }}>
				<RoleSelectField disabled={true} value={roleId} />
			</label>
			<label style={{ padding: 0 }}>
				<DockWorkerSelectField
					disabled={true}
					restrictedRoleId={roleId}
					value={dockWorkerId}
					alreadyPlannedUserIds={alreadyPlannedUserIds}
					duplicateUserIds={duplicateUserIds}
					absentUserIds={absentUserIds}
					usersInAdjacentShift={usersInAdjacentShift} />
			</label>
			<div />
		</div>
		{spot.start && spot.end && <div style={{ display: 'grid', gridTemplateColumns: '12px 1fr 24px', gap: 'var(--u-4)', minHeight: '24px', alignItems: 'center' }}>
			<div />
			<div className='date-time-display' style={{ verticalAlign: 'baseline' }}>Aangepaste tijden: <FormattedTime value={spot.start} /> - <FormattedTime value={spot.end} /></div>
		</div>}
		<div style={{ display: 'grid', gridTemplateColumns: '12px 1fr 24px', gap: 'var(--u-4)' }}>
			<div />
			<SpotRemark remark={spot.remark?.trim() ?? ''} onUpdateRemark={!canChangeRemark ? null : newRemark => changeSpotRemark({ variables: { spotId: spot.id, remark: newRemark } })} />
		</div>
	</div>;
}