import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import ColorDot from '../ui/ColorDot';

export default function RoleSelectField({ disabled, onChange, value, placeholder = '', isMulti, ...props }) {
	const rolesQuery = useQuery(gql`{ roles { id, shortCode, name, colorCode } }`);

	const options = useMemo(() => {
		return (rolesQuery?.data?.roles ?? []).map(r => ({
			value: r.id,
			label: r.shortCode,
			original: r,
		}));
	}, [ rolesQuery?.data?.roles ]);

	const onChangeCb = useCallback((obj) => {
		if (Array.isArray(obj)) {
			onChange(obj.map(o => o?.value), obj.map(o => o?.original));
		} else {
			onChange(obj?.value ?? null, obj?.original);
		}
	}, [ onChange ]);

	return <Select
		{...props}
		isDisabled={disabled}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={rolesQuery.loading}
		openMenuOnFocus={true}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		isMulti={isMulti}
		formatOptionLabel={data => <><ColorDot hex={data?.original?.colorCode} /> {data?.original?.shortCode}</>}
		value={Array.isArray(value) ? value.map(id => options.find(o => o.value == id)) : options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }} />;
}

// function CustomRoleOption({ innerProps, isDisabled, data }) {
// 	const role = data?.original;
// 	return <div {...innerProps} >
// 		<div>
// 			{role?.colorCode ?? 'no col'}
// 		</div>
// 		<div>
// 			{role?.shortCode}
// 		</div>
// 	</div>;
// }

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};