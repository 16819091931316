import React, { useState } from 'react';
import TopBar from '../../TopBar';
import { FormattedDate, FormattedTime } from 'react-intl';
import PageTop from '../../lib/ui/PageTop';
import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import ViewSpot from './ViewSpot';
import RoleSummary from '../RoleSummary';
import SelectField from '../../lib/selectfields/SelectField';
import { InfoBanner, LoadingOverlay, WithLabel } from '@atrocit/scl';
import { DateTime } from 'luxon';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';

export function compareSpotsForSorting(a, b) {
	// We want items with a planning to come first (so external spots go to the end)
	if (a.planning == null && b.planning != null) {
		return 1; // Move 'a' to the end
	} else if (a.planning != null && b.planning == null) {
		return -1; // Keep 'a' before 'b'
	} else {
		return a.listIndex - b.listIndex;
	}
}

export default function ViewShift() {
	const { id } = useParams();
	const [ extraInfo, setExtraInfo ] = useState('REMARK');

	const planningQuery = useQuery(gql`query Query($id: Int!) {
		findRemarksByPlanning(planningId: $id) {
			id,
			remark,
		}
		
		planning(id: $id) {
            id,
			shiftDefinition {
				code,
            },
            view {
                id,
                attachedTracks {
                    id,
                    readOnly,
                    displayName,
                    track {
                        id,
                        name
                    }
                },
            },
            status,
            shiftStart,
            shiftEnd,
        },
		
		planSpotsForPlanning(id: $id) {
			id,
			listIndex,
			remark,
			remarkVisibility,
            shiftCode,
            start,
            end,
			track {
				id,
			},
			dockWorker {
				id,
				user {
					id, firstName, fullName
				},
				workbookNr,
            },
			role {
				id, shortCode, name, colorCode
            },
			planning {
				shiftDefinition {
					code
                }
            }
		}
	}`, { variables: { id } });
	const planning = planningQuery?.data?.planning;
	const planSpots = (planningQuery?.data?.planSpotsForPlanning || []).filter(ps => ps.planning?.shiftDefinition?.code == planning?.shiftDefinition?.code || ps.shiftCode == planning?.shiftDefinition?.code).sort((a, b) => compareSpotsForSorting(a, b));
	const remarks = planningQuery?.data?.findRemarksByPlanning ?? [];

	return <>
		<TopBar title={<span>Shift <FormattedDate value={planning?.shiftStart} day="2-digit" month="2-digit" /> <FormattedTime value={planning?.shiftStart} /> - <FormattedTime value={planning?.shiftEnd} /></span>} />
		{planningQuery.loading && <LoadingOverlay />}

		<div className="grey-page-bg">
			<div className="page-fw">
				<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]} excludeOnThese={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]}>
					<PageTop style={{ paddingLeft: '16px', paddingRight: '16px' }}
						breadcrumbSegments={[
							{ link: '/myShifts', label: 'Mijn shifts' },
							{ link: '/shifts/view/' + id, label: 'Shift bekijken' },
						]}>
					</PageTop>
				</PrivilegeBarrier>

				<PrivilegeBarrier privileges={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]}>
					<PageTop style={{ paddingLeft: '16px', paddingRight: '16px' }}
						breadcrumbSegments={[
							{ link: '/shifts/week/' + (planning?.shiftStart == null ? '' : DateTime.fromISO(planning.shiftStart).toFormat('kkkk-WW')), label: 'Shifts' },
							{ link: '/shifts/view/' + id, label: 'Shift bekijken' },
						]}>

						<div style={{ minWidth: '200px' }}>
							<WithLabel label="Extra informatie">
								<SelectField
									options={[ { value: 'NONE', label: 'Geen' }, { value: 'REMARK', label: 'Opmerking' }, { value: 'WORKBOOK_NR', label: 'Werkboeknr' }, { value: 'ALL', label: 'Alles' } ]}
									onChange={ei => setExtraInfo(ei?.value)}
									value={extraInfo} />
							</WithLabel>
						</div>
					</PageTop>
				</PrivilegeBarrier>

				<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-4)', paddingTop: 'var(--u-4)' }}>
					{remarks.filter(r => r.remark?.trim().length > 0).map(r => <InfoBanner key={r.id}>{r.remark}</InfoBanner>)}
				</div>

				{planning != null && <div className="crew-grid-wrapper" style={{ overflowX: 'auto' }}>
					<div className="crew-grid" style={{ padding: '16px' }}>
						<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 'var(--u-8)', minWidth: '130px', width: '130px' }}>
							<h3 style={{ color: 'var(--col-grey-800)', marginBottom: '10px' }}><FormattedTime value={planning.shiftStart} /> - <FormattedTime value={planning.shiftEnd} /></h3>
							<RoleSummary spots={planSpots} />
						</div>

						{planning.view.attachedTracks.map(at => <AttachedTrack planningId={planning.id} attachedTrack={at} planSpots={planSpots} extraInfo={extraInfo} key={at.id} />)}
					</div>
				</div>}
			</div>
		</div>
	</>;
}

function AttachedTrack({ planningId, attachedTrack, planSpots, extraInfo }) {
	const remarkQuery = useQuery(gql`query Query($planningId: Int!, $trackId: Int!) {
		findRemarksByPlanningTrack(planningId: $planningId, trackId: $trackId) {
			id,
			remark,
		}
	}`, { variables: { planningId, trackId: attachedTrack.track?.id } });
	const remarks = remarkQuery?.data?.findRemarksByPlanningTrack ?? [];

	return <div className="crew-new-column">
		<h3>{attachedTrack.displayName}</h3>
		<div className="crew-col">
			<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-4)', paddingBottom: 'var(--u-4)' }}>
				{remarks.filter(r => r.remark?.trim().length > 0).map(remark => <InfoBanner key={remark.id}>{remark.remark}</InfoBanner>)}
			</div>

			{planSpots.filter(s => s.track?.id == attachedTrack.track?.id).map((spot, idx) => <ViewSpot key={spot.id} spot={spot} idx={idx} extraInfo={extraInfo} />)}
		</div>
	</div>;
}