export default function TopBar({ title, children }) {
	return <>
		<div className="topbar">
			<div className="topbar-inner">
				<span className="topbar-title">{title}</span>
				<div className="topbar-controls">
					{children}
				</div>
			</div>
		</div>
		<div className="topbar-spacer" />
	</>;
}