import { Button, Form, InfoBanner, LoadingOverlay, Modal, WithLabel } from '@atrocit/scl';
import DockWorkerSelectField from '../lib/selectfields/DockWorkerSelectField';
import { useState } from 'react';
import ButtonArea from '../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import ValidatedButton from '../lib/forms/ValidatedButton';

export default function AttachUnattachedWageRecords({ selection, onClose }) {
	const [ dockWorker, setDockWorker ] = useState(null);

	const [ attachRecords, attachRecordsMutation ] = useMutation(gql`mutation Mutation($wageRecordIds: [ Int! ]!, $dockWorkerId: Int!) {
		attachWageRecords(wageRecordIds: $wageRecordIds, dockWorkerId: $dockWorkerId) { id }
	}`);

	return <Modal isOpen={true} title="Loonbewijzen koppelen" onRequestClose={onClose}>
		<Form onSubmit={() => {
			attachRecords({ variables: { wageRecordIds: [ ...selection ], dockWorkerId: dockWorker } })
				.finally(onClose);
		}}>
			<InfoBanner>{selection.size} geselecteerde loonbewijzen aan gebruiker koppelen</InfoBanner>
			<br />

			<WithLabel label="Gebruiker">
				<DockWorkerSelectField onChange={setDockWorker} value={dockWorker} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					validations={[
						{ valid: dockWorker != null, error: 'Gebruiker mag niet leeg zijn' },
					]}
					level="primary"
					type="submit">
					<span className="fa fa-link" />&nbsp; Bewijzen koppelen
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
		{attachRecordsMutation.loading && <LoadingOverlay />}
	</Modal>;
}