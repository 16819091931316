import TopBar from '../TopBar';
import React, { useEffect, useState } from 'react';
import PageTop from '../lib/ui/PageTop';
import { gql, useQuery } from '@apollo/client';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { DateTime } from 'luxon';
import { Button, Checkbox, InfoBanner } from '@atrocit/scl';
import AttachUnattachedWageRecords from './AttachUnattachedWageRecords';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';

export default function UnattachedWageRecords() {
	const [ selection, setSelection ] = useState(new Set());
	const [ showAttachModal, setShowAttachModal ] = useState(false);

	const unattachedWageRecordsQuery = useQuery(gql`{
		unattachedWageRecords {
			id,
			lastName,
			firstName,
			applied,
			createdAt,
			wage,
			premium,
			category,
			function,
			description
        }
	}`);
	const records = unattachedWageRecordsQuery?.data?.unattachedWageRecords ?? [];

	useEffect(() => {
		setSelection(new Set());
	}, [ records ]);

	return <>
		<TopBar title={'Loonbewijzen'} />

		{showAttachModal && <AttachUnattachedWageRecords key={showAttachModal} selection={selection} onClose={() => { setShowAttachModal(false); unattachedWageRecordsQuery.refetch(); }} />}

		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/wages', label: 'Loonbewijzen' }, { link: '/wages/unattached', label: 'Ongekoppeld' } ]}>
					<PrivilegeBarrier privileges={[ 'WRITE_WAGE_RECORDS' ]}>
						<Button level="primary" disabled={selection.size == 0} onClick={() => setShowAttachModal(true)}><span className="fa fa-link" />&nbsp; Koppelen{selection.size > 0 && <>&nbsp; <span className="badge badge-primary">{selection.size}</span></>}</Button>
					</PrivilegeBarrier>
				</PageTop>

				<InfoBanner>
					Al deze bewijzen zijn correct ingelezen, maar konden op basis van het werkboeknummer niet gekoppeld worden aan de juiste gebruikersaccount. Controleer of de werkboeknummers juist ingevuld staan om in de toekomst de loonbewijzen automatisch te koppelen, en koppel onderstaande bewijzen handmatig om ze zichtbaar te maken voor de gebruiker in kwestie.
				</InfoBanner>
				<br />

				<table className="table table-fw">
					<colgroup>
						<col width="40" />
						<col width="200" />
						<col width="130" />
						<col width="130" />
						<col width="90" />
						<col width="90" />
						<col width="70" />
						<col width="70" />
						<col width="*" />
					</colgroup>
					<thead>
						<tr>
							<th></th>
							<th>Naam  <span className="fa fa-caret-up" /></th>
							<th>Eff. datum</th>
							<th>Geregistreerd</th>
							<th className="tbl-align-right">Loon</th>
							<th className="tbl-align-right">Premie</th>
							<th>Cat.</th>
							<th>Func.</th>
							<th>Beschrijving</th>
						</tr>
					</thead>
					<tbody>
						{records.map(r => <tr key={r.id}>
							<td>
								<div style={{ margin: '-4px 0 -4px 0', padding: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
									<Checkbox onChange={() => (selection.has(r.id) ? setSelection(new Set([ ...selection ].filter(x => x != r.id))) : setSelection(new Set([ ...selection, r.id ])))} value={selection.has(r.id)} />
								</div>
							</td>
							<td>{r.lastName}, {r.firstName}</td>
							<td><FormattedDate value={DateTime.fromISO(r.applied).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td><FormattedDate value={DateTime.fromISO(r.createdAt).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td className={"tbl-align-right" + (r.wage == 0 ? ' tbl-faded' : '')}>
								<FormattedNumber value={r.wage} style="currency" currency="EUR" />
							</td>
							<td className={'tbl-align-right' + (r.premium == 0 ? ' tbl-faded' : '')}>
								<FormattedNumber value={r.premium} style="currency" currency="EUR" />
							</td>
							<td>{r.category}</td>
							<td>{r.function}</td>
							<td>{r.description}</td>
						</tr>)}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}