import { useCallback, useContext, useEffect, useState } from 'react';
import { Button, Form, InputField } from '@atrocit/scl';
import { PrivilegesContext } from '../../../context/PrivilegesContext';

export default function SpotRemark({ remark, onUpdateRemark, editMode = null, setEditMode = null }) {
	const privileges = useContext(PrivilegesContext);
	const [ localRemark, setLocalRemark ] = useState(null);

	useEffect(() => {
		if (editMode) setEditMode(false);
	}, [ remark ]);

	const startEditMode = useCallback(() => {
		if (onUpdateRemark == null || editMode == null) return;
		if (!privileges.has('PLANSPOT_CHANGE_REMARK')) return;
		setEditMode(true);
		setLocalRemark(remark);
	}, [ setEditMode, remark ]);

	return <div>
		{editMode && <Form onSubmit={() => { onUpdateRemark(localRemark); setEditMode(false); }} style={{ paddingTop: 'var(--u-4)', display: 'flex', gap: 'var(--u-4)' }}>
			<InputField onChange={setLocalRemark} value={localRemark} />
			<Button level="primary" type="submit"><span className="fa fa-check" /></Button>
		</Form>}
		{!editMode && <div onDoubleClick={startEditMode} style={{ minHeight: remark != null ? 'var(--u-16)' : '' }}>
			{(remark == null || remark == '') && onUpdateRemark && <Button onClick={startEditMode} level="tertiary" style={{ paddingTop: '4px', margin: 0, minHeight: 0, fontSize: '9pt', height: '16px' }}>
				<span className="fa fa-plus" />&nbsp; Opmerking
			</Button>}
			<span style={{ fontWeight: 'bold', color: 'var(--col-red-600)' }}>{remark}</span>
		</div>}
	</div>;
}