import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import AbsenceWeek from './AbsenceWeek';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import {
	Button,
	Checkbox,
	LinkButton,
	LoadingOverlayProvider,
	LoadingOverlayTrigger,
	usePersistentState,
} from '@atrocit/scl';
import { gql, useQuery } from '@apollo/client';
import ButtonArea from '../lib/forms/ButtonArea';
import AddEditAbsence from './AddEditAbsence';
import WeekSelector from '../lib/forms/WeekSelector';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';
import RoleSelectField from '../lib/selectfields/RoleSelectField';

export default function AbsenceCalendar() {
	const [ startDate, setStartDate ] = useState(DateTime.now().plus({ days: 2 }).startOf('week'));
	const [ addEditAbsenceModalOpen, setAddEditAbsenceModalOpen ] = useState(false);
	const [ absenceEditId, setAbsenceEditId ] = useState(null);
	const [ roleFilter, setRoleFilter ] = useState(null);
	const [ showDeniedAbsences, setShowDeniedAbsences ] = usePersistentState(false, 'calendar.showDeniedAbsences');

	const absenceQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!, $role: Int) {
		absencesInRangeForRole(start: $start, end: $end, role: $role) {
			id,
			user {
				id,
				fullName,
				dockWorkerRole {
					workbookNr,
                }
			},
			start,
			end,
			absenceStatus,
			absenceType,
			remark,
            cepaLinesForWeekend,
			workerRequested,
		}
	}`, { variables: { start: startDate.minus({ days: 2 }).toUTC().toISO(), end: startDate.minus({ days: 2 }).plus({ weeks: 1 }).toUTC().toISO(), role: roleFilter != null ? parseInt(roleFilter, 10) : null } });
	const rawAbsences = absenceQuery?.data?.absencesInRangeForRole ?? [];
	const filteredRawAbsences = showDeniedAbsences ? rawAbsences : rawAbsences.filter(a => a.absenceStatus != 'DENIED');
	const absences = useMemo(() => {
		return filteredRawAbsences.map(a => ({
			...a,
			start: DateTime.fromISO(a.start),
			end: DateTime.fromISO(a.end),
		}));
	}, [ filteredRawAbsences ]);

	const specialDaysQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		specialDays(start: $start, end: $end) {
			id, date, name, colorCode
		}
	}`, { variables: { start: startDate.minus({ days: 2 }).toUTC().toISO(), end: startDate.minus({ days: 2 }).plus({ weeks: 1 }).toUTC().toISO() } });
	const specialDays = specialDaysQuery?.data?.specialDays ?? [];

	return <>
		<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
			{addEditAbsenceModalOpen && <AddEditAbsence absenceId={absenceEditId} onClose={() => { setAddEditAbsenceModalOpen(false); setAbsenceEditId(null); absenceQuery.refetch(); }} />}
		</PrivilegeBarrier>
		<TopBar title="Absentiekalender" />
		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/absences', label: 'Absenties' }, { link: '/absences/calendar', label: 'Kalender' }, { link: '/absences/calendar', label: 'Weekoverzicht' } ]}>
					<div style={{ display: 'flex', gap: 'var(--u-16)', alignItems: 'center' }}>
						<LinkButton to="/absences/calendar/month"><span className="fa fa-search-minus" />&nbsp; Per maand</LinkButton>
						<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
							<Button onClick={() => setAddEditAbsenceModalOpen(true)}><span className="fa fa-plus" />&nbsp; Absentie registreren</Button>
						</PrivilegeBarrier>
					</div>
				</PageTop>

				<ButtonArea style={{ justifyContent: 'space-between' }}>
					<WeekSelector startOfWeek={startDate} setStartOfWeek={setStartDate}/>
					<div style={{ display: 'flex', alignItems: 'center', alignContent: 'space-between' }}>
						<span>Rolfilter:</span>
						&nbsp;&nbsp;
						<div style={{ width: '92px' }}><RoleSelectField onChange={setRoleFilter} value={roleFilter}/></div>
						<div class="bar-divider">
					        <span class="divider-line"></span>
						</div>
						<span>Afgewezen absenties:</span>
						&nbsp;&nbsp;
						<div><Checkbox onChange={setShowDeniedAbsences} value={showDeniedAbsences} /></div>
					</div>
				</ButtonArea>

				<br />
				<div style={{ position: 'relative', zIndex: 0 }}>
					<LoadingOverlayProvider>
						{absenceQuery.loading && <LoadingOverlayTrigger />}
						<AbsenceWeek specialDays={specialDays} absences={absences} start={startDate.minus({ days: 2 })} onEdit={(id) => { setAbsenceEditId(id); setAddEditAbsenceModalOpen(true); }} showDeniedAbsences={showDeniedAbsences} />
					</LoadingOverlayProvider>
				</div>
			</div>
		</div>
	</>;
}