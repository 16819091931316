import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import { Button, Checkbox, LinkButton, usePersistentState } from '@atrocit/scl';
import { gql, useQuery } from '@apollo/client';
import ButtonArea from '../lib/forms/ButtonArea';
import AddEditAbsence from './AddEditAbsence';
import AbsenceMonth from './AbsenceMonth';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';
import RoleSelectField from '../lib/selectfields/RoleSelectField';

export default function AbsenceMonthCalendar() {
	const [ startDate, setStartDate ] = useState(DateTime.now().startOf('month'));
	const [ addEditAbsenceModalOpen, setAddEditAbsenceModalOpen ] = useState(false);
	const [ absenceEditId, setAbsenceEditId ] = useState(null);
	const [ roleFilter, setRoleFilter ] = useState(null);
	const [ showDeniedAbsences, setShowDeniedAbsences ] = usePersistentState(false, 'calendar.showDeniedAbsences');

	const absenceQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!, $role: Int) {
        absencesInRangeForRole(start: $start, end: $end, role: $role) {
            id,
            user {
                id,
	            fullName,
	            dockWorkerRole {
					workbookNr,
                }
            },
            start,
            end,
            absenceStatus,
            absenceType,
            remark,
	        workerRequested,
        }

        specialDays(start: $start, end: $end) {
            id, date, name, colorCode
        }
    }`, { variables: { start: startDate.toUTC().toISO(), end: startDate.plus({ months: 1 }).toUTC().toISO(), role: roleFilter != null ? parseInt(roleFilter, 10) : null } });
	const rawAbsences = absenceQuery?.data?.absencesInRangeForRole ?? [];
	const filteredRawAbsences = showDeniedAbsences ? rawAbsences : rawAbsences.filter(a => a.absenceStatus != 'DENIED');
	const specialDays = absenceQuery?.data?.specialDays ?? [];
	const absences = useMemo(() => {
		return filteredRawAbsences.map(a => ({
			...a,
			start: DateTime.fromISO(a.start),
			end: DateTime.fromISO(a.end),
		}));
	}, [ filteredRawAbsences ]);

	return <>
		<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
			{addEditAbsenceModalOpen && <AddEditAbsence absenceId={absenceEditId} onClose={() => { setAddEditAbsenceModalOpen(false); setAbsenceEditId(null); absenceQuery.refetch(); }} />}
		</PrivilegeBarrier>
		<TopBar title="Absentiekalender" />
		<div className="grey-page-bg">
			<div className="page">
				<PageTop breadcrumbSegments={[ { link: '/absences', label: 'Absenties' }, { link: '/absences/calendar', label: 'Kalender' }, { link: '/absences/calendar/month', label: 'Maandoverzicht' } ]}>
					<div style={{ display: 'flex', gap: 'var(--u-16)', alignItems: 'center' }}>
						<LinkButton to="/absences/calendar"><span className="fa fa-search-plus" />&nbsp; Per week</LinkButton>
						<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
							<Button onClick={() => setAddEditAbsenceModalOpen(true)}><span className="fa fa-plus" />&nbsp; Absentie registreren</Button>
						</PrivilegeBarrier>
					</div>
				</PageTop>

				<ButtonArea style={{ justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-8)' }}>
						<Button onClick={() => setStartDate(sd => sd.minus({ months: 1 }))}><span className="fa fa-chevron-left" /></Button>
						<div style={{ width: 'var(--u-128)', textAlign: 'center' }}>
							{startDate.monthLong}, {startDate.weekYear}
						</div>
						<Button onClick={() => setStartDate(sd => sd.plus({ months: 1 }))}><span className="fa fa-chevron-right" /></Button>
					</div>
					<div style={{ display: 'flex', alignItems: 'center', alignContent: 'space-between' }}>
						<span>Rolfilter:</span>
						&nbsp;&nbsp;
						<div style={{ width: '92px' }}><RoleSelectField onChange={setRoleFilter} value={roleFilter}/></div>
						<div class="bar-divider">
					        <span class="divider-line"></span>
						</div>
						<span>Afgewezen absenties:</span>
						&nbsp;&nbsp;
						<div><Checkbox onChange={setShowDeniedAbsences} value={showDeniedAbsences} /></div>
					</div>
				</ButtonArea>

				<br />
				<AbsenceMonth specialDays={specialDays} absences={absences} start={startDate} onEdit={(id) => { setAbsenceEditId(id); setAddEditAbsenceModalOpen(true); }} showDeniedAbsences={showDeniedAbsences} />
			</div>
		</div>
	</>;
}