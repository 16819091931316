import React, { useContext, useEffect, useState } from 'react';
import userContext from '../../context/UserContext';
import { gql, useLazyQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import TopBar from '../TopBar';
import EmptyState from '../lib/EmptyState';
import hammockImg from '../../assets/hammock_blue.png';
import { LinkButton, LoadingOverlay, Pagination, Button } from '@atrocit/scl';
import { AbsenceType } from './AbsenceType';
import { FormattedDate, FormattedRelativeTime } from 'react-intl';
import PageTop from '../lib/ui/PageTop';
import HorizontalScrollContainer from '../lib/ui/HorizontalScrollContainer';
import AbsenceStatusIndicator from "./AbsenceStatusIndicator";

export default function MyAbsences() {
	const user = useContext(userContext);
	const [ loading, setLoading ] = useState(false);
	const [ page, setPage ] = useState(1);
	const [ lastFetch, setLastFetch ] = useState(DateTime.now());

	const [ doQuery, absenceQuery ] = useLazyQuery(gql`query Query($page: Int!) {
		myAbsences(page: $page) {
			totalElements,
			content {
				id,
				user {
					id,
					fullName,
					dockWorkerRole {
						workbookNr,
	                }
				},
				start,
				end,
				absenceStatus,
				absenceType,
				remark,
	            cepaLinesForWeekend,
			}
		}
	}`, { variables: { page, userId: user.id } });
	useEffect(() => {
		doQuery();
	}, []);

	const absences = absenceQuery?.data?.myAbsences.content ?? [];
	const isEmpty = absences != null && absences.length == 0;

	useEffect(() => {
		setLastFetch(DateTime.now());
	}, [ absenceQuery.data ]);

	useEffect(() => {
		const int = setInterval(() => doQuery(), 60 * 1000);
		return () => clearInterval(int);
	}, [ doQuery ]);

	const refreshSecondsAgo = Math.round(DateTime.now().diff(lastFetch, [ 'seconds' ]).toObject().seconds);
	const refreshUnit = (() => {
		if (Math.abs(refreshSecondsAgo) <= 60) return 'second';
		if (Math.abs(refreshSecondsAgo) > 60) return 'minute';
		if (Math.abs(refreshSecondsAgo) > 3600) return 'hour';
		if (Math.abs(refreshSecondsAgo) > 3600 * 24) return 'day';
		if (Math.abs(refreshSecondsAgo) > 3600 * 24 * 7) return 'day';
		return 'week';
	})();

	return <>
		{absenceQuery.loading && <LoadingOverlay/>}
		<TopBar title="Mijn absenties">
			 <Button
				onClick={() => {
					doQuery();
					setLoading(true);
					setTimeout(() => setLoading(false), 500);
				}}
				style={{ minWidth: '40px', textAlign: 'center' }}>
				<span className={"fa fa-refresh" + (loading || absenceQuery.loading ? ' fa-spin' : '')} />
			 </Button>
		</TopBar>

		<div className="grey-page-bg">
			<div className="page" style={{ padding: '0 8px' }}>
				<PageTop breadcrumbSegments={[
					{
						link: '/myAbsences',
						label: 'Mijn absenties',
					},
				]}>
					<div style={{ paddingBottom: 'var(--u-16)' }}>
						<LinkButton
							to='/myAbsences/new'
							style={{ minWidth: '40px', textAlign: 'center' }}>
							<span className="fa fa-plus"/>&nbsp;Nieuwe aanvraag
						</LinkButton>
					</div>
				</PageTop>

				{isEmpty && <EmptyState img={hammockImg} title="Geen absenties aangevraagd" description="Er is geen absenties in het huidige jaar."/>}

				<br/>
				{!isEmpty &&
					<>
						<HorizontalScrollContainer>
							<table className="mobile-table">
								<thead>
									<tr>
										<th className="fit">Status</th>
										<th className="fit">Type</th>
										<th className="fit">Van &darr;</th>
										<th className="fit">T/m</th>
										<th className="fit">Toelichting</th>
										<th className="stick-right" />
									</tr>
								</thead>
								<tbody>
									{absences.map(a => <tr key={a.id}>
										<td className="fit">
											<AbsenceStatusIndicator status={a.absenceStatus} fullText={false} />
										</td>
										<td className="fit"><AbsenceType type={a.absenceType}/></td>
										<td className="fit"><FormattedDate value={DateTime.fromISO(a.start).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short"/></td>
										<td className="fit">{a.start < a.end ? <FormattedDate value={DateTime.fromISO(a.end).toJSDate()} day="2-digit" month="2-digit" year="numeric" weekday="short"/> : '-'}</td>
										<td className="fit">{a.remark}</td>
										<td className="fit stick-right">
											<LinkButton level='primary' to={'/myAbsences/' + a.id}><span className="fa fa-pencil"/></LinkButton>
										</td>
									</tr>)}
								</tbody>
							</table>
						</HorizontalScrollContainer>
						<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)' }}>
							<Pagination page={page} pageSize={25} setPage={setPage} total={absenceQuery?.data?.myAbsences?.totalElements ?? 0} />
						</div>
					</>
				}

				<div style={{ textAlign: 'center', color: 'var(--col-grey-500)', padding: 'var(--u-32) 0' }}>
					<FormattedRelativeTime updateIntervalInSeconds={1} value={Math.min(-1 * refreshSecondsAgo, -1)} unit={refreshUnit} /> ververst
				</div>
			</div>
		</div>
	</>;
}