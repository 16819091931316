import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import ColorDot from '../ui/ColorDot';

export default function AuthRoleSelectField({ disabled, onChange, value, placeholder = '', isMulti, ...props }) {
	const authRolesQuery = useQuery(gql`{ authRoles { id, name } }`);

	const options = useMemo(() => {
		return (authRolesQuery?.data?.authRoles ?? []).map(r => ({
			value: r.id,
			label: r.name,
			original: r,
		}));
	}, [ authRolesQuery?.data?.authRoles ]);

	const onChangeCb = useCallback((obj) => {
		if (Array.isArray(obj)) {
			onChange(obj.map(o => o?.value), obj.map(o => o?.original));
		} else {
			onChange(obj?.value ?? null, obj?.original);
		}
	}, [ onChange ]);

	return <Select
		{...props}
		isDisabled={disabled}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={authRolesQuery.loading}
		openMenuOnFocus={true}
		isClearable={true}
		options={options}
		onChange={onChangeCb}
		isMulti={isMulti}
		formatOptionLabel={data => <>{data.label}</>}
		value={Array.isArray(value) ? value.map(id => options.find(o => o.value == id)) : options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }} />;
}

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};