import React from 'react';

export default function HorizontalScrollContainer({ children, minWidth }) {
	return <div className="horizontal-scroll-container-wrapper" style={{ display: 'flex', flexDirection: 'column', pointerEvents: 'none', maxWidth: '100%' }}>
		<div className="horizontal-scroll-container" style={{ position: 'relative', pointerEvents: 'auto' }}>
			<div className="horizontal-scroll-container-content" style={{ minWidth: minWidth }}>
				{children}
			</div>
			<div className="horizontal-scroll-container-dummy-overflow" />
		</div>
	</div>;
}
