import {
	Button,
	ButtonArea,
	Checkbox,
	DateField,
	Form,
	InputField,
	LoadingOverlay,
	LoadingOverlayTrigger,
	Modal,
	WithLabel,
} from '@atrocit/scl';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { gql, useLazyQuery, useMutation } from '@apollo/client';
import ColorSelectField from '../../lib/selectfields/ColorSelectField';

export default function AddEditSpecialDay({ id, onClose }) {
	const [ date, setDate ] = useState(DateTime.now().startOf('day'));
	const [ affectsWages, setAffectsWages ] = useState(true);
	const [ name, setName ] = useState('');
	const [ colorCode, setColorCode ] = useState('#fa0');

	const [ getSpecialDay, getSpecialDayQuery ] = useLazyQuery(gql`query Query($id: ID!) {
		specialDay(id: $id) { date, affectsWages, name, colorCode }
	}`);

	const [ addEditSpecialDay, addEditSpecialDayMutation ] = useMutation(gql`mutation Mutation($id: ID, $date: Instant!, $affectsWages: Boolean!, $name: String!, $colorCode: String!) {
		addEditSpecialDay(id: $id, date: $date, affectsWages: $affectsWages, name: $name, colorCode: $colorCode) { id }
	}`);

	useEffect(() => {
		if (id == null) return;
		getSpecialDay({ variables: { id } })
			.then(({ data }) => {
				const sd = data?.specialDay;
				if (sd == null) return;

				setDate(DateTime.fromISO(sd.date));
				setAffectsWages(sd.affectsWages);
				setName(sd.name);
				setColorCode(sd.colorCode);
			});
	}, [ id ]);

	return <Modal isOpen={true} title={id == null ? 'Feestdag maken' : 'Feestdag bewerken'} onRequestClose={onClose}>
		{addEditSpecialDayMutation.loading && <LoadingOverlay />}
		<Form onSubmit={() => {
			addEditSpecialDay({ variables: { id, date: date.toISO(), affectsWages, name, colorCode } })
				.then(() => onClose());
		}}>
			<WithLabel label="Datum">
				<DateField onChange={setDate} value={date} />
			</WithLabel>
			<WithLabel label="Affecteert lonen">
				<Checkbox value={affectsWages} onChange={setAffectsWages} />
			</WithLabel>
			<WithLabel label="Naam">
				<InputField onChange={setName} value={name} />
			</WithLabel>
			<WithLabel label="Kleur">
				<ColorSelectField
					onChange={setColorCode}
					value={colorCode} />
			</WithLabel>

			<ButtonArea>
				<Button onClick={onClose}>Annuleren</Button>
				<ValidatedButton
					level="primary"
					type="submit"
					validations={[
						{ valid: date != null, error: "Er moet een datum ingevuld zijn" },
						{ valid: name != null && name.length > 0, error: 'Er moet een naam ingevuld zijn' },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
			</ButtonArea>
		</Form>
	</Modal>;
}