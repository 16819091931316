import { gql, useMutation } from '@apollo/client';
import { Button, Form, LoadingOverlayProvider, LoadingOverlayTrigger, Modal } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';
import { useState } from 'react';

export default function DeleteShift({ isOpen, onClose, id }) {
	const [ deleteShift ] = useMutation(gql`mutation Mutation($planningId: Int!) {
        deletePlanning(planningId: $planningId)
    }`);
	const [ loading, setLoading ] = useState(false);

	return <Modal isOpen={isOpen} title="Shift verwijderen" onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loading && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				setLoading(true);
				deleteShift({ variables: { planningId: id } })
					.finally(() => { setLoading(false); onClose(); });
			}}>
				<p style={{ maxWidth: 'var(--u-384)', marginTop: 0 }}>Je staat op het punt een shift te verwijderen. Deze actie kan niet ongedaan gemaakt worden. Weet je zeker dat je door wilt gaan?</p>

				<ButtonArea>
					<Button
						type="submit"
						level="primary"
						className="btn-danger">
						<span className="fa fa-trash-o" />&nbsp; Verwijderen
					</Button>
					<Button onClick={() => onClose()}>Behouden</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}