import React, { useEffect, useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Button, LoadingOverlay, LoadingOverlayProvider, LoadingOverlayTrigger, Pagination } from '@atrocit/scl';
import DeleteUserModal from './DeleteUserModal';
import { Link } from "react-router-dom";
import LinkButton from '../lib/forms/LinkButton';
import PageTop from '../lib/ui/PageTop';
import TopBar from '../TopBar';
import SearchField from '../lib/forms/SearchField';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';

const PAGE_SIZE = 20;

export default function UsersOverview() {
	const [ page, setPage ] = useState(1);
	const [ query, setQuery ] = useState('');
	const [ deleteUserId, setDeleteUserId ] = useState(null);
	const [ showDeleteUserModal, setShowDeleteUserModal ] = useState(false);

	useEffect(() => {
		if (page != 1) setPage(1);
	}, [ query ]);

	const userQuery = useQuery(gql`query Query($query: String, $offset: Int!, $limit: Int!) {
		usersPageableAndQueryable(query: $query, offset: $offset, limit: $limit) {
			totalElements,
			content {
				id,
				fullName,
				phoneNumber,
				email,
				authRoles {
					id, name
                }
				dockWorkerRole {
					id, workbookNr, category
                },
				deletedOn
            }
		}
	}`, { variables: { query, offset: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE } });
	const totalElements = userQuery?.data?.usersPageableAndQueryable?.totalElements ?? 0;
	const users = userQuery?.data?.usersPageableAndQueryable?.content ?? [];

	return <>
		<TopBar title="Gebruikers" />
		<div className="page">
			{showDeleteUserModal && <DeleteUserModal isOpen={showDeleteUserModal} onClose={() => setShowDeleteUserModal(false)} userId={deleteUserId} refetch={() => userQuery.refetch()} />}

			<PageTop
				breadcrumbSegments={[
					{ link: '/users', label: 'Gebruikers' },
					{ link: '/users', label: 'Overzicht' },
				]}>
				<PrivilegeBarrier privileges={[ 'WRITE_USERS' ]}>
					<LinkButton to='/users/new'><span className="fa fa-plus" />&nbsp; Gebruiker toevoegen</LinkButton>
				</PrivilegeBarrier>
			</PageTop>
			<br />
			<SearchField value={query} onChange={setQuery} hasResults={users.length > 0} />
			<br />

			<div style={{ position: 'relative', zIndex: 0 }}>
				<LoadingOverlayProvider>
					{userQuery.loading && <LoadingOverlayTrigger />}

					<table className="table table-fw">
						<colgroup>
							<col width="*" />
							<col width="*" />
							<col width="150" />
							<col width="100" />
							<col width="70" />
							<col width="*" />
							<col width="130" />
							<col width="50" />
						</colgroup>
						<thead>
							<tr>
								<th>Volledige naam</th>
								<th>E-mail</th>
								<th>Telefoonnr</th>
								<th>Werkboeknr</th>
								<th>Categ.</th>
								<th>Rechten</th>
								<th></th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{users.map(u => <tr key={u.id}>
								<td>{u.deletedOn != null ? <><span className="fa fa-archive" />&nbsp; </> : ''}{u.fullName}</td>
								<td>{u.email}</td>
								<td>{u.phoneNumber}</td>
								<td>{u.dockWorkerRole?.workbookNr}</td>
								<td>{u.dockWorkerRole?.category}</td>
								<td>{u.authRoles.map(ar => ar.name).join(', ')}</td>
								<td>
									<PrivilegeBarrier privileges={[ 'WRITE_USERS' ]}>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<Link className={"scl-button scl-button-secondary"} to={'/users/' + u.id}><span className="fa fa-pencil" />&nbsp; Bewerken</Link>
										</div>
									</PrivilegeBarrier>
								</td>
								<td>
									<PrivilegeBarrier privileges={[ 'WRITE_USERS' ]}>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											{u.deletedOn == null && <Button onClick={() => { setDeleteUserId(u.id); setShowDeleteUserModal(true); }}><i className="fa fa-archive"></i></Button>}
										</div>
									</PrivilegeBarrier>
								</td>
							</tr>)}
							{!userQuery.loading && users.length == 0 && <tr>
								<td colSpan={7}>Geen resultaten</td>
							</tr>}
						</tbody>
					</table>

					<div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: 'var(--u-16)', gap: 'var(--u-24)', alignItems: 'center' }}>
						<div>
							<span><span>Gebruikers {(page - 1) * PAGE_SIZE + 1} t/m {Math.min((page * PAGE_SIZE), totalElements)} getoond, van {totalElements} totaal</span></span>
						</div>
						<Pagination page={page} setPage={setPage} pageSize={PAGE_SIZE} total={totalElements} />
					</div>

				</LoadingOverlayProvider>
			</div>

			<br /><br /><br /><br />
		</div>
	</>;
}