import ButtonArea from '../../lib/forms/ButtonArea';
import {
	Button,
	Checkbox,
	Form,
	InputField,
	LoadingOverlayProvider,
	LoadingOverlayTrigger,
	Modal,
	WithLabel,
} from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import ValidatedButton from '../../lib/forms/ValidatedButton';

export default function ChangeSpotTimesModal({ spot, isOpen, onClose, privileges, planningShiftStartDate, planningShiftEndDate }) {
	const [ changeSpotTimes, { loading: loadingMutation } ] = useMutation(gql`mutation Mutation($spotId: Int!, $start: Instant, $end: Instant, $warningOnWageCalculation: Boolean) {
        changeSpotTimes(spotId: $spotId, start: $start, end: $end, warningOnWageCalculation: $warningOnWageCalculation) { id }
    }`);
	const [ start, setStart ] = useState(getHoursMinutesFromDateString(spot.start));
	const [ end, setEnd ] = useState(getHoursMinutesFromDateString(spot.end));
	const [ startInstant, setStartInstant ] = useState(null);
	const [ endInstant, setEndInstant ] = useState(null);
	const [ doNotCalculateWages, setDoNotCalculateWages ] = useState(spot.workRecord?.warningOnWageCalculationTarget);

	function clearTimes() {
		setStart('');
		setEnd('');
		setDoNotCalculateWages(false);
	}

	useEffect(() => {
		// use the planningShiftStartDate to create a new Date object with the time from the input fields (I assume it will always be the day of today)
		const tempStartInstant = start ? getDateFromTimeAndDate(start, planningShiftStartDate) : null;
		const tempEndInstant = end ? getDateFromTimeAndDate(end, planningShiftStartDate) : null;

		if (tempStartInstant != null || tempEndInstant != null) {
			setDoNotCalculateWages(true);
		}

		// see which of startInstant - 1 day, startInstant or startInstant + 1 day is the closest to the planningShiftStartDate; this is to better approximate what day the user is referring to, since they only fill in times
		// example: if the shift times are 00:00 - 07:00 and the user inputs 23:00 - 06:00, the user probably means to move the shift back one hour, so that means starting the day before at 23:00
		const adjustedStartInstant = tempStartInstant ? getClosestDateForDateTime(tempStartInstant, planningShiftStartDate) : null;

		// if the end time is before the start time, it probably means that the shift runs into the next day, so add a day to the instant (this way end will always be after start)
		let adjustedEndInstant = tempEndInstant;
		if (adjustedStartInstant && adjustedEndInstant && adjustedStartInstant > adjustedEndInstant) {
			adjustedEndInstant = new Date(adjustedEndInstant.getTime());
			adjustedEndInstant.setDate(adjustedEndInstant.getDate() + 1);
		}

		// Update state
		setStartInstant(adjustedStartInstant);
		setEndInstant(adjustedEndInstant);
	}, [ start, end, planningShiftStartDate ]);

	return <Modal title="Shifttijden overschrijven" isOpen={isOpen} onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loadingMutation && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				changeSpotTimes({ variables: { spotId: spot.id, start: startInstant, end: endInstant, warningOnWageCalculation: doNotCalculateWages } })
					.finally(() => onClose());
			}}>
				<span>Weet je zeker dat je de tijden van deze shift voor deze plaats wilt overschrijven?</span>
				<br />
				<span>Laat de velden leeg om de shifttijden weer te gebruiken.</span>
				<br />
				<br />
				<div style={{ display: 'flex', justifyContent: 'space-around' }}>
					<WithLabel label="Van">
						<InputField type="time" value={start} onChange={setStart} />
					</WithLabel>
					<WithLabel label="Tot">
						<InputField type="time" value={end} onChange={setEnd} />
					</WithLabel>
				</div>
				<br />
				{(!spot.workRecordExempt && start == '' && end == '') ? <div style={{ display: 'flex', flexDirection: 'column' }}>
					<WithLabel label="Lonen niet automatisch berekenen:">
						<Checkbox type="time" value={doNotCalculateWages} onChange={setDoNotCalculateWages} />
					</WithLabel>
				</div> : <span>Lonen worden niet automatisch berekend. <br /><br /></span>}
				<ButtonArea style={{ justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', flexDirection: 'row-reverse', gap: 'var(--u-16)' }}>
						<ValidatedButton type="submit" level="primary" validations={[
							{ valid: (start == '' || end == '') || startInstant <= new Date(planningShiftEndDate) && endInstant >= new Date(planningShiftStartDate), error: 'De ingevoerde tijden moeten de shifttijden overlappen' },
							{ valid: start == '' || startInstant >= new Date(new Date(planningShiftStartDate).getTime() - 8 * 60 * 60 * 1000), error: 'Van kan niet meer dan 8 uur voor de starttijd van de shift zijn' },
							{ valid: end == '' || endInstant <= new Date(new Date(planningShiftEndDate).getTime() + 8 * 60 * 60 * 1000), error: 'Tot kan niet meer dan 8 uur na de eindtijd van de shift zijn' },
							{ valid: privileges.has('PLANSPOT_CHANGE_TIMES'), error: 'Je hebt niet de vereiste rechten om de tijden van deze plaats te wijzigen.' },
							{ valid: (start == null && end == null) || (start && end) || (start.trim().length == 0 && end.trim().length == 0), error: 'Beide tijden moeten ingesteld worden, of beide velden moeten leeg zijn.' },
							{ valid: !(start != null && end != null && start !== '' && end !== '' && start === end), error: 'Shift moet langer dan een minuut zijn.' },
						]}><span className="fa fa-check" />&nbsp; Bewerken</ValidatedButton>
						<Button onClick={clearTimes}><span className="fa fa-trash" />&nbsp; Leegmaken</Button>
					</div>
					<Button onClick={onClose}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}

// Use to return a string in hh:mm format from a Date object
function getHoursMinutesFromDateString(dateString) {
	if (dateString == null) return '';

	const date = new Date(dateString);
	if (!(date instanceof Date)) return '';

	const hours = date.getHours();
	const minutes = date.getMinutes();

	// Pad single digits with leading zero
	const paddedHours = hours.toString().padStart(2, '0');
	const paddedMinutes = minutes.toString().padStart(2, '0');

	// Combine hours and minutes in hh:mm format
	return paddedHours + ':' + paddedMinutes;
}

// Used to turn output of InputField into a Date object I can send to the backend
function getDateFromTimeAndDate(timeString, dateString) {
	const dateOfDay = new Date(dateString);
	if (!(dateOfDay instanceof Date)) return null;

	// Extract hours and minutes from the time string
	const parts = timeString.split(':');
	const hours = parseInt(parts[0], 10);
	const minutes = parseInt(parts[1], 10);

	// Create a new Date object based on dateOfDay
	const date = new Date(dateOfDay.getTime());

	// Set hours and minutes based on the input string
	date.setHours(hours, minutes, 0, 0); // Resets seconds and milliseconds to 0

	return date;
}

// Used to determine the closest dateTime to a referenceDate
function getClosestDateForDateTime(dateTime, referenceDateString) {
	if (dateTime == null || referenceDateString == null) return null;

	const referenceDate = new Date(referenceDateString);
	if (!(referenceDate instanceof Date) || !(dateTime instanceof Date)) return null;

	const previousDay = new Date(dateTime.getTime());
	previousDay.setDate(previousDay.getDate() - 1);

	const nextDay = new Date(dateTime.getTime());
	nextDay.setDate(nextDay.getDate() + 1);

	const diffToPrevious = Math.abs(previousDay - referenceDate);
	const diffToCurrent = Math.abs(dateTime - referenceDate);
	const diffToNext = Math.abs(nextDay - referenceDate);

	switch (Math.min(diffToPrevious, diffToCurrent, diffToNext)) {
	case diffToPrevious: return previousDay;
	case diffToCurrent: return dateTime;
	case diffToNext: return nextDay;
	default: return dateTime; // should never get here but just in case (haha see what I did there)
	}
}