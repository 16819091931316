import { useParams } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import PageTop from '../lib/ui/PageTop';
import TopBar from '../TopBar';
import { FormattedDate, FormattedNumber } from 'react-intl';
import React from 'react';
import { LinkButton, WithLabel } from '@atrocit/scl';

export default function ViewSavingsRecord() {
	const { id } = useParams();

	const savingsRecordQuery = useQuery(gql`query Query($id: ID!) {
		savingRecord(id: $id) {
			id,
			createdAt,
			applied,
			wage,
			premium,
			dockWorkerRole {
				id,
				workbookNr,
				user {
					id,
					fullName,
				},
            },
			remark,
		}
		
		affectedSavingRecordsWage(id: $id) {
			savingRecord {
				id,
				applied,
				wage,
				premium,
				remark,
            },
			amount,
		}
		
		affectedSavingRecordsPremium(id: $id) {
			savingRecord {
				id,
				applied,
				wage,
				premium,
				remark,
            },
			amount,
		}
	}`, { variables: { id } });
	const savingRecord = savingsRecordQuery?.data?.savingRecord;
	const affectedSavingRecordsWage = savingsRecordQuery?.data?.affectedSavingRecordsWage ?? [];
	const affectedSavingRecordsPremium = savingsRecordQuery?.data?.affectedSavingRecordsPremium ?? [];

	const totalWageAffected = affectedSavingRecordsWage.map(({ savingRecord, amount }) => ((savingRecord?.wage > 0 ? 1 : -1) * amount)).reduce((tot, amount) => tot + amount, 0);
	const totalPremiumAffected = affectedSavingRecordsPremium.map(({ savingRecord, amount }) => ((savingRecord?.premium > 0 ? 1 : -1) * amount)).reduce((tot, amount) => tot + amount, 0);

	return <div>
		<TopBar title="Briefje" />

		<div className="page">
			<PageTop
				breadcrumbSegments={[
					{ label: 'Briefjes', link: '/savings' },
					{ label: savingRecord?.dockWorkerRole?.user?.fullName ?? '', link: '/savings' },
					{ label: <FormattedDate value={savingRecord?.applied} day="2-digit" month="2-digit" year="numeric" />, link: '/savings/' + id },
				]} />

			<div style={{ background: 'var(--col-grey-50)', borderRadius: '4px', padding: 'var(--u-16)', display: 'grid', gap: 'var(--u-16)', gridTemplateColumns: '1fr var(--u-192) var(--u-192)', alignItems: 'center' }}>
				<div>
					<h2 style={{ margin: 0, padding: 'var(--u-4) 0' }}>{savingRecord?.dockWorkerRole?.user?.fullName}</h2>
					<span><FormattedDate value={savingRecord?.applied} day="2-digit" month="2-digit" year="numeric" />, <FormattedDate value={savingRecord?.applied} weekday="long" /></span><br />
					<span style={{ fontStyle: 'italic' }}>{savingRecord?.remark}</span>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className={"fancy-number" + (savingRecord?.wage < 0 ? ' fancy-number-neg' : '')}>
						<span className="fancy-number-description">LOON</span><br />
						<FormattedNumber value={savingRecord?.wage ?? 0} style="currency" currency="EUR" />
					</div>
				</div>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<div className={"fancy-number" + (savingRecord?.premium < 0 ? ' fancy-number-neg' : '')}>
						<span className="fancy-number-description">PREMIE</span><br />
						<FormattedNumber value={savingRecord?.premium ?? 0} style="currency" currency="EUR" />
					</div>
				</div>
			</div>
			<br />

			{savingRecord?.wage != 0 && <>
				<h2>{totalWageAffected > 0 ? 'Loon afkomstig van' : 'Loon ingezet voor'}</h2>
				<table className="table table-fw">
					<col width="140" />
					<col width="120" />
					<col width="120" />
					<col width="120" />
					<col width="*" />
					<col width="48" />
					<thead>
						<tr>
							<th>Datum</th>
							<th className="tbl-align-right">Verbruik</th>
							<th className="tbl-align-right">Resterend</th>
							<th className="tbl-align-right">Totaal</th>
							<th>Opmerking</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{affectedSavingRecordsWage?.map(({ savingRecord, amount }) => <tr key={savingRecord.id}>
							<td><FormattedDate value={savingRecord.applied} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td className="tbl-align-right"><FormattedNumber value={(savingRecord.wage > 0 ? 1 : -1) * (amount || 0)} style="currency" currency="EUR" /></td>
							<td className="tbl-align-right"><FormattedNumber value={savingRecord.wage - ((savingRecord.wage > 0 ? 1 : -1) * amount)} style="currency" currency="EUR" /></td>
							<td className="tbl-align-right"><FormattedNumber value={savingRecord.wage} style="currency" currency="EUR" /></td>
							<td>{savingRecord.remark}</td>
							<td className="tbl-center"><LinkButton to={'/savings/' + savingRecord.id}><span className="fa fa-eye" /></LinkButton></td>
						</tr>)}
					</tbody>
					<tfoot>
						<tr>
							<td></td>
							<td className="bold tbl-align-right"><FormattedNumber value={totalWageAffected} style="currency" currency="EUR" /></td>
							<td className="bold tbl-align-right"><FormattedNumber value={(savingRecord?.wage ?? 0) + totalWageAffected} style="currency" currency="EUR" /></td>
							<td className="bold tbl-align-right"></td>
							<td></td>
							<td></td>
						</tr>
					</tfoot>
				</table>

				<br />
			</>}

			{savingRecord?.premium != 0 && <>
				<h2>{totalPremiumAffected > 0 ? 'Premie afkomstig van' : 'Premie ingezet voor'}</h2>
				<table className="table table-fw">
					<col width="140" />
					<col width="120" />
					<col width="120" />
					<col width="120" />
					<col width="*" />
					<col width="48" />
					<thead>
						<tr>
							<th>Datum</th>
							<th className="tbl-align-right">Verbruik</th>
							<th className="tbl-align-right">Resterend</th>
							<th className="tbl-align-right">Totaal</th>
							<th>Opmerking</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{affectedSavingRecordsPremium.map(({ savingRecord, amount }) => <tr key={savingRecord.id}>
							<td><FormattedDate value={savingRecord.applied} day="2-digit" month="2-digit" year="numeric" weekday="short" /></td>
							<td className="tbl-align-right"><FormattedNumber value={(savingRecord.premium > 0 ? 1 : -1) * (amount || 0)} style="currency" currency="EUR" /></td>
							<td className="tbl-align-right"><FormattedNumber value={savingRecord.premium - ((savingRecord.premium > 0 ? 1 : -1) * amount)} style="currency" currency="EUR" /></td>
							<td className="tbl-align-right"><FormattedNumber value={savingRecord.premium} style="currency" currency="EUR" /></td>
							<td>{savingRecord.remark}</td>
							<td className="tbl-center"><LinkButton to={'/savings/' + savingRecord.id}><span className="fa fa-eye" /></LinkButton></td>
						</tr>)}
					</tbody>
					<tfoot>
						<tr>
							<td></td>
							<td className="bold tbl-align-right"><FormattedNumber value={totalPremiumAffected} style="currency" currency="EUR" /></td>
							<td className="bold tbl-align-right"><FormattedNumber value={(savingRecord?.premium ?? 0) + totalPremiumAffected} style="currency" currency="EUR" /></td>
							<td className="bold tbl-align-right"></td>
							<td></td>
							<td></td>
						</tr>
					</tfoot>
				</table>
			</>}
		</div>
	</div>;

}