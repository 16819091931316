import React from 'react';

export default function AbsenceStatusIndicator({ status, fullText = true }) {
	if (status == 'DRAFT') {
		return <div className="containerstay-status containerstay-status-draft">
			<span className="fa fa-pencil-square-o" />{fullText ? <>&nbsp; Concept</> : ''}
		</div>;
	} else if (status == 'REQUESTED') {
		return <div className="containerstay-status containerstay-status-requested">
			<span className="fa fa-question-circle" />{fullText ? <>&nbsp; Aangevraagd</> : ''}
		</div>;
	} else if (status == 'APPROVED') {
		return <div className="containerstay-status containerstay-status-approved">
			<span className="fa fa-check fa-check-circle green" />{fullText ? <>&nbsp; Goedgekeurd</> : ''}
		</div>;
	} else if (status == 'DENIED') {
		return <div className="containerstay-status containerstay-status-denied">
			<span className="fa fa-times-circle red" />{fullText ? <>&nbsp; Afgewezen</> : ''}
		</div>;
	} else {
		return <span className="fa fa-question-circle"/>;
	}
}