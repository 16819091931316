import React, { Fragment } from 'react';
import HoverBubble from '../ui/HoverBubble';

export default function withValidationHoc(Component) {
	return function WithValidation({ children, validations = [], icon, ...props }) {
		return <HoverBubble content={validations.filter(v => !v.valid).map(v => <Fragment key={v.error}><span key={v.error}>{v.error}</span><br /></Fragment>)}>
			<Component
				disabled={props.disabled || validations.filter(v => !v.valid).length > 0}
				icon={icon == null ? null : validations.filter(v => !v.valid).length > 0 ? 'exclamation-triangle' : icon}
				{...props}>
				{children}
			</Component>
		</HoverBubble>;
	};
}