import React from 'react';
import { Link } from 'react-router-dom';

export default function Breadcrumbs({ segments }) {
	const parts = [];
	for (let i = 0; i < segments.length; i++) {
		if (i > 0) parts.push(<Separator key={'s' + i} />);
		parts.push(<Segment key={i} {...segments[i]} />);
	}

	return <div className="breadcrumbs">
		{parts}
	</div>;
}

function Segment({ link, label }) {
	return <div className="breadcrumb-segment">
		<Link to={link}>{label}</Link>
	</div>;
}

function Separator() {
	return <div className="breadcrumb-separator">&rsaquo;</div>;
}