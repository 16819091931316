import { Button, Form, InputField, Modal, WithLabel } from '@atrocit/scl';
import DockWorkerSelectField from '../lib/selectfields/DockWorkerSelectField';
import { useState } from 'react';
import DateField from '../lib/forms/DateField';
import { DateTime } from 'luxon';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';
import { useMutation, gql } from '@apollo/client';

export default function AddSavingRecordModal({ isOpen, onClose }) {
	const [ dockWorker, setDockWorker ] = useState(null);
	const [ applied, setApplied ] = useState(DateTime.now().startOf('day'));
	const [ wage, setWage ] = useState('0.00');
	const [ premium, setPremium ] = useState('0.00');
	const [ remark, setRemark ] = useState('');

	const numWage = parseFloat(wage.replace(',', '.'));
	const numPremium = parseFloat(premium.replace(',', '.'));

	const [ addSavingRecord ] = useMutation(gql`mutation Mutation($dockWorkerId: Int!, $applied: Instant!, $wage: Float!, $premium: Float!, $remark: String) {
		addSavingRecord(dockWorkerId: $dockWorkerId, applied: $applied, wage: $wage, premium: $premium, remark: $remark) { id }
	}`);

	return <Modal title="Nieuw briefje" isOpen={isOpen}>
		<Form onSubmit={() => {
			addSavingRecord({ variables: { dockWorkerId: dockWorker, applied: applied.toUTC().toISO(), wage: numWage, premium: numPremium, remark } })
				.finally(onClose);
		}}>
			<WithLabel label="Havenwerker">
				<DockWorkerSelectField onChange={dw => setDockWorker(dw)} value={dockWorker} />
			</WithLabel>
			<WithLabel label="Datum">
				<DateField onChange={setApplied} value={applied} />
			</WithLabel>
			<div className="col-2">
				<WithLabel label="Loon">
					<InputField onChange={setWage} value={wage} pattern="^\-?\d+([\.\,]\d{2})?$" />
				</WithLabel>
				<WithLabel label="Premie">
					<InputField onChange={setPremium} value={premium} pattern="^\-?\d+([\.\,]\d{2})?$" />
				</WithLabel>
			</div>
			<WithLabel label="Opmerking">
				<InputField onChange={setRemark} value={remark} />
			</WithLabel>

			<ButtonArea>
				<ValidatedButton
					level="primary"
					type="submit"
					validations={[
						{ valid: dockWorker != null, error: 'Vul havenwerker in' },
						{ valid: applied != null, error: 'Vul datum in' },
						{ valid: !isNaN(numWage), error: 'Loon moet een valide getal zijn' },
						{ valid: !isNaN(numPremium), error: 'Premie moet een valide getal zijn' },
					]}>
					<span className="fa fa-check" />&nbsp; Opslaan
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}