import { InputField, WithLabel } from '@atrocit/scl';

export default function SearchField({ value, onChange, hasResults = true, ...props }) {
	return <WithLabel label="Zoeken">
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative' }}>
			<InputField onChange={onChange} value={value} {...props}/>
			<div style={{ position: 'absolute', right: 0, top: 0, bottom: 0, paddingRight: 'var(--u-8)', color: 'var(--col-grey-400)', display: 'flex', alignItems: 'center' }}>
				{hasResults
					? <span className="fa fa-search" />
					: <>
						<span style={{ fontSize: '12px', whiteSpace: 'nowrap' }}>Geen resultaten &nbsp;</span>
						<a href="#" className="field-clear-btn fa fa-times" style={{ fontSize: '18px', textDecoration: 'none' }} onClick={() => onChange('')}/>
					</>}
			</div>
		</div>
	</WithLabel>;
}