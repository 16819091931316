import ButtonArea from '../../lib/forms/ButtonArea';
import { Button, Form, LoadingOverlayProvider, LoadingOverlayTrigger, Modal } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';

export default function DeleteSpotModal({ spotId, isOpen, onClose }) {
	const [ deleteSpot ] = useMutation(gql`mutation Mutation($spotId: Int!) {
		deleteSpot(spotId: $spotId)
	}`);
	const [ loading, setLoading ] = useState(false);

	return <Modal title="Plaats verwijderen" isOpen={isOpen} onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loading && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				setLoading(true);
				deleteSpot({ variables: { spotId } })
					.finally(() => { setLoading(false); onClose(); });
			}}>
				<span>Weet je zeker dat je deze plaats wilt verwijderen?</span>

				<ButtonArea>
					<Button type="submit" level="primary"><span className="fa fa-trash" />&nbsp; Verwijderen</Button>
					<Button onClick={onClose}>Behouden</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}