import { useState } from 'react';
import { DateTime } from 'luxon';
import { gql, useQuery, useSubscription } from '@apollo/client';
import TopBar from '../../TopBar';
import AddShift from '../modals/AddShift';
import DuplicateShift from '../modals/DuplicateShift';
import DeleteShift from '../modals/DeleteShift';
import { Button, LoadingOverlay, LoadingOverlayProvider, LoadingOverlayTrigger, usePersistentState } from '@atrocit/scl';
import PageTop from '../../lib/ui/PageTop';
import ButtonArea from '../../lib/forms/ButtonArea';
import { useParams } from 'react-router-dom';
import DuplicateDay from '../modals/DuplicateDay';
import { useNavigate } from 'react-router';
import ShiftsWeekDay from './ShiftsWeekDay';
import WeekRemark from './WeekRemark';
import ViewSelectField from '../../lib/selectfields/ViewSelectField';
import WeekSelector from '../../lib/forms/WeekSelector';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';

export default function ShiftsWeekOverview() {
	const navigate = useNavigate();
	const { weekYear } = useParams();

	// The week number + year is encoded in the URL
	const startOfWeek = (weekYear ? DateTime.fromFormat(weekYear, 'kkkk-WW') : (DateTime.now().plus({ days: 2 })).startOf('week'));
	const setStartOfWeek = (dtOrFn) => {
		const newDt = typeof dtOrFn == 'function' ? dtOrFn(startOfWeek) : dtOrFn;
		navigate('/shifts/week/' + newDt.toFormat('kkkk-WW'));
	};

	const [ showAddShiftModal, setShowAddShiftModal ] = useState(null);
	const [ duplicateShiftId, setDuplicateShiftId ] = useState(null);
	const [ deleteShiftId, setDeleteShiftId ] = useState(null);
	const [ duplicateDay, setDuplicateDay ] = useState(null);
	const [ view, setView ] = usePersistentState(null, 'calendar.selectedView'); // TODO: figure out a way to default fill this to user default

	const viewSubscription = useSubscription(gql`subscription Subscription($viewId: Int!, $start: Instant!, $end: Instant!) {
		viewSubscription(viewId: $viewId, startDate: $start, endDate: $end) {
			view {
				id, name,
				shiftDefinitions {
					id,
					name,
					startTime,
					endTime,
                }
            },
			plannings {
				id,
                view {
                    id, name,
                },
                shiftDefinition {
                    id,
                    name,
                    startTime,
                    endTime
                }
                shiftStart,
                shiftEnd,
                spots {
                    id,
                    track { id },
                    role { id, shortCode }
                    dockWorker { id }
                },
                status
            },
			planSpots {
				id,
				track { id },
				planning { id }
				role { id, shortCode }
            }
		}
	}`, { variables: { viewId: view, start: startOfWeek.minus({ days: 2 }).toUTC().toISO(), end: startOfWeek.plus({ weeks: 1 }).minus({ days: 2 }).toUTC().toISO() }, skip: view == null });
	const viewObject = viewSubscription?.data?.viewSubscription?.view ?? null;
	const shifts = viewSubscription?.data?.viewSubscription?.plannings ?? [];

	const specialDaysQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		specialDays(start: $start, end: $end) {
			id, date, name, colorCode
		}
	}`, { variables: { start: startOfWeek.minus({ days: 2 }).toUTC().toISO(), end: startOfWeek.plus({ weeks: 1 }).minus({ days: 2 }).toUTC().toISO() } });
	const specialDays = specialDaysQuery?.data?.specialDays ?? [];

	return <div style={{ height: '100%' }}>
		<TopBar title={'Week ' + startOfWeek.toFormat('WW, kkkk')} />
		<div className="grey-page-bg" style={{ height: '100%' }}>
			<div className="page">
				<AddShift key={showAddShiftModal} view={viewObject} isOpen={showAddShiftModal != null} options={showAddShiftModal} onClose={() => { setShowAddShiftModal(null); }} />
				<DuplicateShift isOpen={duplicateShiftId != null} onClose={() => { setDuplicateShiftId(null); }} id={duplicateShiftId} />
				<DeleteShift isOpen={deleteShiftId != null} onClose={() => { setDeleteShiftId(null); }} id={deleteShiftId} />
				<DuplicateDay
					key={duplicateDay}
					isOpen={duplicateDay != null}
					shifts={shifts.filter(s => duplicateDay != null && DateTime.fromISO(s.shiftStart) >= duplicateDay && DateTime.fromISO(s.shiftStart) < duplicateDay.plus({ days: 1 }))}
					onClose={() => { setDuplicateDay(null); }} />

				<PageTop breadcrumbSegments={[ { link: '/shifts', label: 'Shifts' }, { link: '/shifts', label: 'Overzicht' } ]} />

				<ButtonArea>
					<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-8)' }}>
						<div style={{ marginRight: '16px' }}>
							<div style={{ minWidth: '150px' }}>
								<ViewSelectField onChange={setView} value={view} />
							</div>
						</div>

						<WeekSelector setStartOfWeek={setStartOfWeek} startOfWeek={startOfWeek} />
					</div>
				</ButtonArea>
				<br /><br />

				<div style={{ position: 'relative', zIndex: 0 }}>
					<LoadingOverlayProvider>
						{viewSubscription.loading && <LoadingOverlayTrigger />}
						<div className="shift-wk" style={{ display: 'grid', gridTemplateColumns: 'repeat(7, 1fr)', gap: 'var(--u-4)' }}>
							{[ 0, 1, 2, 3, 4, 5, 6 ].map(x => x - 2).map(offset => <ShiftsWeekDay
								key={offset}
								view={viewObject}
								dayStart={startOfWeek.plus({ days: offset })}
								shifts={shifts.filter(s => DateTime.fromISO(s.shiftStart) >= startOfWeek.plus({ days: offset }) && DateTime.fromISO(s.shiftStart) < startOfWeek.plus({ days: offset + 1 }))}
								setAddShift={setShowAddShiftModal}
								setDuplicateDay={setDuplicateDay}
								setDuplicateShiftId={setDuplicateShiftId}
								setDeleteShiftId={setDeleteShiftId}
								specialDays={specialDays} />)}
						</div>
					</LoadingOverlayProvider>
				</div>

				<div>
					<PrivilegeBarrier privileges={[ 'READ_WEEK_REMARKS' ]}>
						<WeekRemark viewId={viewObject?.id} weekStart={startOfWeek} />
					</PrivilegeBarrier>
				</div>
			</div>
		</div>
	</div>;
}