import React, { useCallback, useContext, useState } from 'react';
import { Button, Form } from '@atrocit/scl';
import { gql, useMutation, useQuery } from '@apollo/client';
import ButtonArea from '../../lib/forms/ButtonArea';
import { PrivilegesContext } from '../../../context/PrivilegesContext';

export default function WeekRemark({ viewId, weekStart }) {
	const privileges = useContext(PrivilegesContext);
	const [ editRemark, setEditRemark ] = useState(null);

	const weekRemarkQuery = useQuery(gql`query Query($viewId: Int!, $lowerbound: Instant!, $upperbound: Instant!) {
        findRemarksByWeek(viewId: $viewId, lowerbound: $lowerbound, upperbound: $upperbound) {
            id,
            remarkType,
            remark,
            createdAt,
        }
    }`, { variables: { viewId: parseInt(viewId, 10), lowerbound: weekStart.startOf('day').toUTC().toISO(), upperbound: weekStart.startOf('day').plus({ days: 6 }).endOf('day').toUTC().toISO() }, skip: viewId == null });
	const remark = (weekRemarkQuery?.data?.findRemarksByWeek ?? [])[0]?.remark ?? '';

	const [ saveRemark ] = useMutation(gql`mutation Mutation($viewId: Int!, $startOfWeek: Instant!, $remark: String!) {
        changeWeekRemark(viewId: $viewId, startOfWeek: $startOfWeek, remark: $remark) {
            id, remark, remarkType, createdAt
        }
    }`);

	const onStartEditing = useCallback(() => {
		if (!privileges.has('WRITE_WEEK_REMARKS')) return;
		setEditRemark(remark ?? '');
	}, [ remark ]);

	if (editRemark == null) {
		return <div style={{ borderRadius: '4px', border: '1px solid var(--col-grey-200)', margin: '8px 0', padding: '4px' }} onDoubleClick={onStartEditing}>
			{remark == null || remark.trim().length == 0 ?
				<span style={{ color: 'var(--col-grey-400)', fontStyle: 'italic', fontSize: 'var(--fs-11)' }}>Geen opmerkingen</span> :
				<pre style={{ fontWeight: 'bold', color: 'var(--col-red-600)' }}>{remark}</pre>}
		</div>;
	} else {
		return <Form onSubmit={() => {
			saveRemark({ variables: { viewId: parseInt(viewId, 10), startOfWeek: weekStart.toUTC().toISO(), remark: editRemark } })
				.finally(() => {
					setEditRemark(null);
					weekRemarkQuery.refetch();
				});
		}}>
			<textarea style={{ resize: 'vertical', width: '100%', margin: '8px 0', minHeight: '100px' }} onChange={e => setEditRemark(e.target.value)} value={editRemark} />
			<div style={{ margin: '-16px 0' }}>
				<ButtonArea>
					<Button type="submit" level="primary"><span className="fa fa-check" />&nbsp; Opslaan</Button>
					<Button onClick={() => setEditRemark(null)}>Annuleren</Button>
				</ButtonArea>
			</div>
		</Form>;
	}
}