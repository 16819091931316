import { Button, Form, LoadingOverlayProvider, LoadingOverlayTrigger, Modal } from '@atrocit/scl';
import { useState } from 'react';
import { DateTime } from 'luxon';
import ButtonArea from '../../lib/forms/ButtonArea';
import { gql, useMutation } from '@apollo/client';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import FormattedDateTime from '../../lib/formatting/FormattedDateTime';
import { FormattedTime } from 'react-intl';


export default function AddShift({ isOpen, options, onClose }) {
	// const [ startDate, setStartDate ] = useState(options?.from ?? DateTime.now().plus({ days: 1 }).startOf('day'));
	// const [ endDate, setEndDate ] = useState(options?.to ?? DateTime.now().plus({ days: 1 }).startOf('day'));

	const [ addShift ] = useMutation(gql`mutation Mutation($viewId: Int!, $shiftDefinitionId: Int!, $startDate: Instant!, $endDate: Instant!) {
        addPlanning(viewId: $viewId, shiftDefinitionId: $shiftDefinitionId, shiftStart: $startDate, shiftEnd: $endDate) { id }
	}`);
	const [ loading, setLoading ] = useState(false);

	const startDate = (options?.dayStart ?? DateTime.now().startOf('day')).set({ hours: options?.shiftDefinition?.startTime.split(':')[0], minutes: options?.shiftDefinition?.startTime.split(':')[1] });
	const initialEndDate = (options?.dayStart ?? DateTime.now().startOf('day')).set({ hours: options?.shiftDefinition?.endTime.split(':')[0], minutes: options?.shiftDefinition?.endTime.split(':')[1] });
	const endDate = initialEndDate < startDate ? initialEndDate.plus({ days: 1 }) : initialEndDate;

	return <Modal isOpen={isOpen} title="Shift maken" onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loading && <LoadingOverlayTrigger />}
			{isOpen && <>
				<Form onSubmit={() => {
					setLoading(true);
					addShift({
						variables: {
							viewId: parseInt(options.view?.id, 10),
							shiftDefinitionId: parseInt(options.shiftDefinition?.id, 10),
							startDate: startDate.toUTC().toISO(),
							endDate: endDate.toUTC().toISO(),
						},
					}).finally(() => { setLoading(false); onClose(); });
				}}>
					<span className="bold">{options.shiftDefinition?.name}</span><br />
					<FormattedDateTime value={startDate} /> &ndash; <FormattedTime value={endDate} /><br /><br />

					<ButtonArea>
						<ValidatedButton
							type="submit"
							level="primary"
							validations={[
								{ valid: startDate == null || endDate == null || startDate < endDate, error: 'Starttijd moet voor eindtijd liggen' },
								{ valid: startDate != null, error: 'Starttijd mag niet leeg zijn' },
								{ valid: endDate != null, error: 'Eindtijd mag niet leeg zijn' },
							]}>
							<span className="fa fa-check" />&nbsp; Aanmaken</ValidatedButton>
						<Button onClick={() => onClose()}>Annuleren</Button>
					</ButtonArea>
				</Form>
			</>}
		</LoadingOverlayProvider>
	</Modal>;
}