import { WarningBanner } from '@atrocit/scl';

export default function Warnings({ absentUserIds, duplicateUsers, planning }) {
	if (planning == null) return <div></div>;

	const plannedAbsentUsers = planning.spots
		.map(s => s.dockWorker?.user)
		.filter(user => user != null)
		.filter(user => absentUserIds.has(user.id));

	return <div style={{ padding: 'var(--u-8) var(--u-16) 0 var(--u-16)', display: 'flex', gap: 'var(--u-8)', flexDirection: 'column' }}>
		{plannedAbsentUsers.length > 0 && <WarningBanner>
			De volgende gebruikers zijn absent, maar toch gepland: <DisplayUsersAlphabetically users={plannedAbsentUsers} />
		</WarningBanner>}

		{duplicateUsers.length > 0 && <WarningBanner>
			De volgende gebruikers zijn duplicaat ingepland: <DisplayUsersAlphabetically users={duplicateUsers} />
		</WarningBanner>}
	</div>;
}

function DisplayUsersAlphabetically({ users }) {
	return [ ...new Set(users.map(u => u.fullName)) ].join(', ');
}


