import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import {
	ButtonArea,
	Checkbox,
	DateField,
	InputField,
	LinkButton,
	LoadingOverlayProvider,
	LoadingOverlayTrigger,
	WithLabel,
} from '@atrocit/scl';
import TopBar from '../../TopBar';
import RoleSelectField from '../../lib/selectfields/RoleSelectField';
import PageTop from '../../lib/ui/PageTop';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import enforceNumberWithTwoDecimals from './EnforceNumberService';

export default function AddEditWageTable() {
	const navigate = useNavigate();
	const { id } = useParams();
	const [ isActive, setIsActive ] = useState(false);
	const [ applyFrom, setApplyFrom ] = useState(null);
	const [ roles, setRoles ] = useState([]);
	const [ editingMode, setEditingMode ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	// i promise i'm not a bad developer, however:
	const [ earlyShiftRegularTotal, setEarlyShiftRegularTotal ] = useState("0");
	const [ lateShiftRegularTotal, setLateShiftRegularTotal ] = useState("0");
	const [ nightShiftRegularTotal, setNightShiftRegularTotal ] = useState("0");
	const [ dayShiftRegularTotal, setDayShiftRegularTotal ] = useState("0");
	const [ earlyShiftRegularHourly, setEarlyShiftRegularHourly ] = useState("0");
	const [ lateShiftRegularHourly, setLateShiftRegularHourly ] = useState("0");
	const [ nightShiftRegularHourly, setNightShiftRegularHourly ] = useState("0");
	const [ dayShiftRegularHourly, setDayShiftRegularHourly ] = useState("0");
	const [ earlyShiftRegularOvertimeHour, setEarlyShiftRegularOvertimeHour ] = useState("0");
	const [ lateShiftRegularOvertimeHour, setLateShiftRegularOvertimeHour ] = useState("0");
	const [ nightShiftRegularOvertimeHour, setNightShiftRegularOvertimeHour ] = useState("0");
	const [ dayShiftRegularOvertimeHour, setDayShiftRegularOvertimeHour ] = useState("0");
	const [ earlyShiftRegularPremium, setEarlyShiftRegularPremium ] = useState("0");
	const [ lateShiftRegularPremium, setLateShiftRegularPremium ] = useState("0");
	const [ nightShiftRegularPremium, setNightShiftRegularPremium ] = useState("0");
	const [ dayShiftRegularPremium, setDayShiftRegularPremium ] = useState("0");
	const [ earlyShiftSaturdayTotal, setEarlyShiftSaturdayTotal ] = useState("0");
	const [ lateShiftSaturdayTotal, setLateShiftSaturdayTotal ] = useState("0");
	const [ nightShiftSaturdayTotal, setNightShiftSaturdayTotal ] = useState("0");
	const [ dayShiftSaturdayTotal, setDayShiftSaturdayTotal ] = useState("0");
	const [ earlyShiftSaturdayHourly, setEarlyShiftSaturdayHourly ] = useState("0");
	const [ lateShiftSaturdayHourly, setLateShiftSaturdayHourly ] = useState("0");
	const [ nightShiftSaturdayHourly, setNightShiftSaturdayHourly ] = useState("0");
	const [ dayShiftSaturdayHourly, setDayShiftSaturdayHourly ] = useState("0");
	const [ earlyShiftSaturdayOvertimeHour, setEarlyShiftSaturdayOvertimeHour ] = useState("0");
	const [ lateShiftSaturdayOvertimeHour, setLateShiftSaturdayOvertimeHour ] = useState("0");
	const [ nightShiftSaturdayOvertimeHour, setNightShiftSaturdayOvertimeHour ] = useState("0");
	const [ dayShiftSaturdayOvertimeHour, setDayShiftSaturdayOvertimeHour ] = useState("0");
	const [ earlyShiftSaturdayPremium, setEarlyShiftSaturdayPremium ] = useState("0");
	const [ lateShiftSaturdayPremium, setLateShiftSaturdayPremium ] = useState("0");
	const [ nightShiftSaturdayPremium, setNightShiftSaturdayPremium ] = useState("0");
	const [ dayShiftSaturdayPremium, setDayShiftSaturdayPremium ] = useState("0");
	const [ earlyShiftSundayTotal, setEarlyShiftSundayTotal ] = useState("0");
	const [ lateShiftSundayTotal, setLateShiftSundayTotal ] = useState("0");
	const [ nightShiftSundayTotal, setNightShiftSundayTotal ] = useState("0");
	const [ dayShiftSundayTotal, setDayShiftSundayTotal ] = useState("0");
	const [ earlyShiftSundayHourly, setEarlyShiftSundayHourly ] = useState("0");
	const [ lateShiftSundayHourly, setLateShiftSundayHourly ] = useState("0");
	const [ nightShiftSundayHourly, setNightShiftSundayHourly ] = useState("0");
	const [ dayShiftSundayHourly, setDayShiftSundayHourly ] = useState("0");
	const [ earlyShiftSundayOvertimeHour, setEarlyShiftSundayOvertimeHour ] = useState("0");
	const [ lateShiftSundayOvertimeHour, setLateShiftSundayOvertimeHour ] = useState("0");
	const [ nightShiftSundayOvertimeHour, setNightShiftSundayOvertimeHour ] = useState("0");
	const [ dayShiftSundayOvertimeHour, setDayShiftSundayOvertimeHour ] = useState("0");
	const [ earlyShiftSundayPremium, setEarlyShiftSundayPremium ] = useState("0");
	const [ lateShiftSundayPremium, setLateShiftSundayPremium ] = useState("0");
	const [ nightShiftSundayPremium, setNightShiftSundayPremium ] = useState("0");
	const [ dayShiftSundayPremium, setDayShiftSundayPremium ] = useState("0");

	const [ addEditWageTable ] = useMutation(gql`mutation Mutation($id: ID, $wageTable: WageTableInput!) {
        addEditWageTable(id: $id, wageTable: $wageTable) { id }
    }`);

	const wageTableQuery = useQuery(gql`query Query($id: ID!){
        wageTable(id: $id){
            id,
	        active,
            applyFrom,
            roles { 
	            id,
            },
            earlyShiftRegularTotal,
            lateShiftRegularTotal,
            nightShiftRegularTotal,
            dayShiftRegularTotal,
            earlyShiftRegularHourly,
            lateShiftRegularHourly,
            nightShiftRegularHourly,
            dayShiftRegularHourly,
            earlyShiftRegularOvertimeHour,
            lateShiftRegularOvertimeHour,
            nightShiftRegularOvertimeHour,
            dayShiftRegularOvertimeHour,
            earlyShiftRegularPremium,
            lateShiftRegularPremium,
            nightShiftRegularPremium,
            dayShiftRegularPremium,
            earlyShiftSaturdayTotal,
            lateShiftSaturdayTotal,
            nightShiftSaturdayTotal,
            dayShiftSaturdayTotal,
            earlyShiftSaturdayHourly,
            lateShiftSaturdayHourly,
            nightShiftSaturdayHourly,
            dayShiftSaturdayHourly,
            earlyShiftSaturdayOvertimeHour,
            lateShiftSaturdayOvertimeHour,
            nightShiftSaturdayOvertimeHour,
            dayShiftSaturdayOvertimeHour,
            earlyShiftSaturdayPremium,
            lateShiftSaturdayPremium,
            nightShiftSaturdayPremium,
            dayShiftSaturdayPremium,
            earlyShiftSundayTotal,
            lateShiftSundayTotal,
            nightShiftSundayTotal,
            dayShiftSundayTotal,
            earlyShiftSundayHourly,
            lateShiftSundayHourly,
            nightShiftSundayHourly,
            dayShiftSundayHourly,
            earlyShiftSundayOvertimeHour,
            lateShiftSundayOvertimeHour,
            nightShiftSundayOvertimeHour,
            dayShiftSundayOvertimeHour,
            earlyShiftSundayPremium,
            lateShiftSundayPremium,
            nightShiftSundayPremium,
            dayShiftSundayPremium,
        }
    }`, { variables: { id } });
	const wageTable = wageTableQuery?.data?.wageTable ?? null;

	useEffect(() => {
		if (wageTable == null) return;
		setIsActive(wageTable.active);
		setApplyFrom(DateTime.fromISO(wageTable.applyFrom));
		setRoles(wageTable.roles.map(r => r.id));
		setEditingMode(true);

		setEarlyShiftRegularTotal(wageTable.earlyShiftRegularTotal ?? "0");
		setLateShiftRegularTotal(wageTable.lateShiftRegularTotal ?? "0");
		setNightShiftRegularTotal(wageTable.nightShiftRegularTotal ?? "0");
		setDayShiftRegularTotal(wageTable.dayShiftRegularTotal ?? "0");
		setEarlyShiftRegularHourly(wageTable.earlyShiftRegularHourly ?? "0");
		setLateShiftRegularHourly(wageTable.lateShiftRegularHourly ?? "0");
		setNightShiftRegularHourly(wageTable.nightShiftRegularHourly ?? "0");
		setDayShiftRegularHourly(wageTable.dayShiftRegularHourly ?? "0");
		setEarlyShiftRegularOvertimeHour(wageTable.earlyShiftRegularOvertimeHour ?? "0");
		setLateShiftRegularOvertimeHour(wageTable.lateShiftRegularOvertimeHour ?? "0");
		setNightShiftRegularOvertimeHour(wageTable.nightShiftRegularOvertimeHour ?? "0");
		setDayShiftRegularOvertimeHour(wageTable.dayShiftRegularOvertimeHour ?? "0");
		setEarlyShiftRegularPremium(wageTable.earlyShiftRegularPremium ?? "0");
		setLateShiftRegularPremium(wageTable.lateShiftRegularPremium ?? "0");
		setNightShiftRegularPremium(wageTable.nightShiftRegularPremium ?? "0");
		setDayShiftRegularPremium(wageTable.dayShiftRegularPremium ?? "0");
		setEarlyShiftSaturdayTotal(wageTable.earlyShiftSaturdayTotal ?? "0");
		setLateShiftSaturdayTotal(wageTable.lateShiftSaturdayTotal ?? "0");
		setNightShiftSaturdayTotal(wageTable.nightShiftSaturdayTotal ?? "0");
		setDayShiftSaturdayTotal(wageTable.dayShiftSaturdayTotal ?? "0");
		setEarlyShiftSaturdayHourly(wageTable.earlyShiftSaturdayHourly ?? "0");
		setLateShiftSaturdayHourly(wageTable.lateShiftSaturdayHourly ?? "0");
		setNightShiftSaturdayHourly(wageTable.nightShiftSaturdayHourly ?? "0");
		setDayShiftSaturdayHourly(wageTable.dayShiftSaturdayHourly ?? "0");
		setEarlyShiftSaturdayOvertimeHour(wageTable.earlyShiftSaturdayOvertimeHour ?? "0");
		setLateShiftSaturdayOvertimeHour(wageTable.lateShiftSaturdayOvertimeHour ?? "0");
		setNightShiftSaturdayOvertimeHour(wageTable.nightShiftSaturdayOvertimeHour ?? "0");
		setDayShiftSaturdayOvertimeHour(wageTable.dayShiftSaturdayOvertimeHour ?? "0");
		setEarlyShiftSaturdayPremium(wageTable.earlyShiftSaturdayPremium ?? "0");
		setLateShiftSaturdayPremium(wageTable.lateShiftSaturdayPremium ?? "0");
		setNightShiftSaturdayPremium(wageTable.nightShiftSaturdayPremium ?? "0");
		setDayShiftSaturdayPremium(wageTable.dayShiftSaturdayPremium ?? "0");
		setEarlyShiftSundayTotal(wageTable.earlyShiftSundayTotal ?? "0");
		setLateShiftSundayTotal(wageTable.lateShiftSundayTotal ?? "0");
		setNightShiftSundayTotal(wageTable.nightShiftSundayTotal ?? "0");
		setDayShiftSundayTotal(wageTable.dayShiftSundayTotal ?? "0");
		setEarlyShiftSundayHourly(wageTable.earlyShiftSundayHourly ?? "0");
		setLateShiftSundayHourly(wageTable.lateShiftSundayHourly ?? "0");
		setNightShiftSundayHourly(wageTable.nightShiftSundayHourly ?? "0");
		setDayShiftSundayHourly(wageTable.dayShiftSundayHourly ?? "0");
		setEarlyShiftSundayOvertimeHour(wageTable.earlyShiftSundayOvertimeHour ?? "0");
		setLateShiftSundayOvertimeHour(wageTable.lateShiftSundayOvertimeHour ?? "0");
		setNightShiftSundayOvertimeHour(wageTable.nightShiftSundayOvertimeHour ?? "0");
		setDayShiftSundayOvertimeHour(wageTable.dayShiftSundayOvertimeHour ?? "0");
		setEarlyShiftSundayPremium(wageTable.earlyShiftSundayPremium ?? "0");
		setLateShiftSundayPremium(wageTable.lateShiftSundayPremium ?? "0");
		setNightShiftSundayPremium(wageTable.nightShiftSundayPremium ?? "0");
		setDayShiftSundayPremium(wageTable.dayShiftSundayPremium ?? "0");
	}, [ wageTable ]);

	function submitTable() {
		// takes all the values above, puts them in a variable and returns that.
		const wageTable = {
			active: isActive,
			applyFrom,
			roles,
			earlyShiftRegularTotal,
			lateShiftRegularTotal,
			nightShiftRegularTotal,
			dayShiftRegularTotal,
			earlyShiftRegularHourly,
			lateShiftRegularHourly,
			nightShiftRegularHourly,
			dayShiftRegularHourly,
			earlyShiftRegularOvertimeHour,
			lateShiftRegularOvertimeHour,
			nightShiftRegularOvertimeHour,
			dayShiftRegularOvertimeHour,
			earlyShiftRegularPremium,
			lateShiftRegularPremium,
			nightShiftRegularPremium,
			dayShiftRegularPremium,
			earlyShiftSaturdayTotal,
			lateShiftSaturdayTotal,
			nightShiftSaturdayTotal,
			dayShiftSaturdayTotal,
			earlyShiftSaturdayHourly,
			lateShiftSaturdayHourly,
			nightShiftSaturdayHourly,
			dayShiftSaturdayHourly,
			earlyShiftSaturdayOvertimeHour,
			lateShiftSaturdayOvertimeHour,
			nightShiftSaturdayOvertimeHour,
			dayShiftSaturdayOvertimeHour,
			earlyShiftSaturdayPremium,
			lateShiftSaturdayPremium,
			nightShiftSaturdayPremium,
			dayShiftSaturdayPremium,
			earlyShiftSundayTotal,
			lateShiftSundayTotal,
			nightShiftSundayTotal,
			dayShiftSundayTotal,
			earlyShiftSundayHourly,
			lateShiftSundayHourly,
			nightShiftSundayHourly,
			dayShiftSundayHourly,
			earlyShiftSundayOvertimeHour,
			lateShiftSundayOvertimeHour,
			nightShiftSundayOvertimeHour,
			dayShiftSundayOvertimeHour,
			earlyShiftSundayPremium,
			lateShiftSundayPremium,
			nightShiftSundayPremium,
			dayShiftSundayPremium,
		};
		setLoading(true);
		addEditWageTable({ variables: { id, wageTable } }).then(r => navigate('/administration/wagetables/')).finally(() => setLoading(false));
	}

	return <>
		 <div style={{ position: 'relative', zIndex: 0 }}>
			<LoadingOverlayProvider>
				{wageTableQuery.loading && <LoadingOverlayTrigger />}
			</LoadingOverlayProvider>
		 </div>

		<TopBar title={editingMode ? "Loontabel bewerken" : "Nieuwe loontabel"}/>
		<div className="grey-page-bg">
			<LoadingOverlayProvider>
				{loading && <LoadingOverlayTrigger />}
				<div className="page">
					<PageTop breadcrumbSegments={[ { link: '/administration', label: 'CEPA' }, { link: '/administration/wagetables', label: 'Loontabellen' } ]} />

					<WithLabel label="Actief">
						<Checkbox onChange={setIsActive} value={isActive} />
					</WithLabel>

					<WithLabel label="Ingangsdatum" title="De tabel met de laatste datum wordt gebruikt als er meerdere tabellen zijn voor dezelfde rol.">
						<DateField onChange={d => setApplyFrom(d.startOf('day'))} value={applyFrom} />
					</WithLabel>

					<WithLabel label="Rol">
						<RoleSelectField onChange={setRoles} value={roles} isMulti={true}/>
					</WithLabel>

					<br />
					<h3>Werkdagtabel</h3>
					<table className="table" style={{ width: '600px' }}>
						<colgroup>
							<col width="40"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
						</colgroup>
						<thead>
							<tr>
								<th>€</th>
								<th>Shift</th>
								<th>Premie</th>
								<th>Uur</th>
								<th>Overuur</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Vroeg</td>
								<td><InputField value={earlyShiftRegularTotal} onChange={value => setEarlyShiftRegularTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftRegularPremium} onChange={value => setEarlyShiftRegularPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftRegularHourly} onChange={value => setEarlyShiftRegularHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftRegularOvertimeHour} onChange={value => setEarlyShiftRegularOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Laat</td>
								<td><InputField value={lateShiftRegularTotal} onChange={value => setLateShiftRegularTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftRegularPremium} onChange={value => setLateShiftRegularPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftRegularHourly} onChange={value => setLateShiftRegularHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftRegularOvertimeHour} onChange={value => setLateShiftRegularOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Dag</td>
								<td><InputField value={dayShiftRegularTotal} onChange={value => setDayShiftRegularTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftRegularPremium} onChange={value => setDayShiftRegularPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftRegularHourly} onChange={value => setDayShiftRegularHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftRegularOvertimeHour} onChange={value => setDayShiftRegularOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Nacht</td>
								<td><InputField value={nightShiftRegularTotal} onChange={value => setNightShiftRegularTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftRegularPremium} onChange={value => setNightShiftRegularPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftRegularHourly} onChange={value => setNightShiftRegularHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftRegularOvertimeHour} onChange={value => setNightShiftRegularOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
						</tbody>
					</table>

					<br /><br />
					<h3>Zaterdagtabel</h3>
					<table className="table" style={{ width: '600px' }}>
						<colgroup>
							<col width="40"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
						</colgroup>
						<thead>
							<tr>
								<th>€</th>
								<th>Shift</th>
								<th>Premie</th>
								<th>Uur</th>
								<th>Overuur</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Vroeg</td>
								<td><InputField value={earlyShiftSaturdayTotal} onChange={value => setEarlyShiftSaturdayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSaturdayPremium} onChange={value => setEarlyShiftSaturdayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSaturdayHourly} onChange={value => setEarlyShiftSaturdayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSaturdayOvertimeHour} onChange={value => setEarlyShiftSaturdayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Laat</td>
								<td><InputField value={lateShiftSaturdayTotal} onChange={value => setLateShiftSaturdayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSaturdayPremium} onChange={value => setLateShiftSaturdayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSaturdayHourly} onChange={value => setLateShiftSaturdayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSaturdayOvertimeHour} onChange={value => setLateShiftSaturdayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Dag</td>
								<td><InputField value={dayShiftSaturdayTotal} onChange={value => setDayShiftSaturdayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSaturdayPremium} onChange={value => setDayShiftSaturdayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSaturdayHourly} onChange={value => setDayShiftSaturdayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSaturdayOvertimeHour} onChange={value => setDayShiftSaturdayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Nacht</td>
								<td><InputField value={nightShiftSaturdayTotal} onChange={value => setNightShiftSaturdayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSaturdayPremium} onChange={value => setNightShiftSaturdayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSaturdayHourly} onChange={value => setNightShiftSaturdayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSaturdayOvertimeHour} onChange={value => setNightShiftSaturdayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
						</tbody>
					</table>

					<br /><br />
					<h3>Zon-/Feestdagtabel</h3>
					<table className="table" style={{ width: '600px' }}>
						<colgroup>
							<col width="40"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
							<col width="30"/>
						</colgroup>
						<thead>
							<tr>
								<th>€</th>
								<th>Shift</th>
								<th>Premie</th>
								<th>Uur</th>
								<th>Overuur</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Vroeg</td>
								<td><InputField value={earlyShiftSundayTotal} onChange={value => setEarlyShiftSundayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSundayPremium} onChange={value => setEarlyShiftSundayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSundayHourly} onChange={value => setEarlyShiftSundayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={earlyShiftSundayOvertimeHour} onChange={value => setEarlyShiftSundayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Laat</td>
								<td><InputField value={lateShiftSundayTotal} onChange={value => setLateShiftSundayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSundayPremium} onChange={value => setLateShiftSundayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSundayHourly} onChange={value => setLateShiftSundayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={lateShiftSundayOvertimeHour} onChange={value => setLateShiftSundayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Dag</td>
								<td><InputField value={dayShiftSundayTotal} onChange={value => setDayShiftSundayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSundayPremium} onChange={value => setDayShiftSundayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSundayHourly} onChange={value => setDayShiftSundayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={dayShiftSundayOvertimeHour} onChange={value => setDayShiftSundayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
							<tr>
								<td>Nacht</td>
								<td><InputField value={nightShiftSundayTotal} onChange={value => setNightShiftSundayTotal(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSundayPremium} onChange={value => setNightShiftSundayPremium(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSundayHourly} onChange={value => setNightShiftSundayHourly(enforceNumberWithTwoDecimals(value))} type="string"/></td>
								<td><InputField value={nightShiftSundayOvertimeHour} onChange={value => setNightShiftSundayOvertimeHour(enforceNumberWithTwoDecimals(value))} type="string"/></td>
							</tr>
						</tbody>
					</table>

					<ButtonArea>
						<LinkButton to="/administration/wagetables/">Annuleren</LinkButton>
						<ValidatedButton level="primary" onClick={() => submitTable()} validations={[ { valid: applyFrom != null, error: 'Ingangsdatum mag niet leeg zijn' } ]}><span className="fa fa-check" />&nbsp; {editingMode ? 'Bewerken' : 'Opslaan'}</ValidatedButton>
					</ButtonArea>
				</div>
			</LoadingOverlayProvider>
		</div>
	</>;
}