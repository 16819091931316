import { gql, useQuery } from '@apollo/client';
import { InfoBanner } from '@atrocit/scl';

export default function ExternalRemark({ planningId, trackId }) {
	const externalRemarkQuery = useQuery(gql`query Query($planningId: Int!, $trackId: Int!) {
        findExternalRemarksByPlanningTrack(planningId: $planningId, trackId: $trackId) {
            id,
            remark,
        }
    }`, { pollInterval: 60 * 1000, variables: { planningId, trackId } });

	const externalRemark = externalRemarkQuery?.data?.findExternalRemarksByPlanningTrack?.[0]?.remark;

	if (externalRemark == null || externalRemark.trim().length == 0) return null;

	return <InfoBanner>
		<pre style={{ fontWeight: 'lighter', fontStyle: 'italic' }}>Externe opmerking:</pre>
		<span><pre style={{ fontWeight: 'bold', color: 'var(--col-red-600)' }}>{externalRemark}</pre></span>
	</InfoBanner>;

}