import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, Checkbox, DateField, Form, LoadingOverlayProvider, LoadingOverlayTrigger, Modal, WithLabel } from '@atrocit/scl';
import DateTimeField from '../../lib/forms/DateTimeField';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';

export default function DuplicateShift({ isOpen, onClose, id }) {
	const [ targetDate, setTargetDate ] = useState(DateTime.now().plus({ days: 1 }).startOf('day'));
	const [ copyRemark, setCopyRemark ] = useState(false);
	const [ copyDockWorkers, setCopyDockWorkers ] = useState(false);
	const [ copyManualSpotTimes, setCopyManualSpotTimes ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const [ duplicateShift ] = useMutation(gql`mutation Mutation($instructions: [ PlanningDuplicateInstruction! ]!) {
        duplicatePlanningInstructions(instructions: $instructions) { id }
    }`);

	const shiftQuery = useQuery(gql`query Query($id: Int!) {
		planning(id: $id) {
			id,
			shiftStart,
			shiftEnd,
		}
	}`, { variables: { id } });
	const existingShift = shiftQuery?.data?.planning;

	return <Modal isOpen={isOpen} title="Shift dupliceren" onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loading && <LoadingOverlayTrigger />}
			{shiftQuery.loading && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				if (existingShift == null) return;

				setLoading(true);
				const diffStart = DateTime.fromISO(existingShift?.shiftStart).diff(DateTime.fromISO(existingShift?.shiftStart).toLocal().startOf('day'));
				const diffEnd = DateTime.fromISO(existingShift?.shiftEnd).diff(DateTime.fromISO(existingShift?.shiftEnd).toLocal().startOf('day'));
				const startDate = targetDate.startOf('day').plus(diffStart);
				let endDate = targetDate.startOf('day').plus(diffEnd);
				if (endDate < startDate) { // if the end date is before the start date, we need to add one day
				    endDate = endDate.plus({ days: 1 });
				}
				const instructions = [
					{ fromPlanningId: id, copyRemark, copyDockWorkers, copyManualSpotTimes, start: startDate.toUTC().toISO(), end: endDate.toUTC().toISO() },
				];
				duplicateShift({ variables: { instructions } })
					.finally(() => { setLoading(false); onClose(); });
			}}>
				<WithLabel label="Datum">
					<DateField onChange={setTargetDate} value={targetDate} />
				</WithLabel>

				<div>
					<WithLabel isFake={true} label="Overnemen:">
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyRemark} onChange={setCopyRemark}/> &nbsp; <div>Opmerkingen</div>
							</div>
						</WithLabel>
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyDockWorkers} onChange={setCopyDockWorkers}/> &nbsp; <div>Personen</div>
							</div>
						</WithLabel>
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyManualSpotTimes} onChange={setCopyManualSpotTimes}/> &nbsp; <div>Aangepaste tijden</div>
							</div>
						</WithLabel>
					</WithLabel>
				</div>

				<ButtonArea>
					<ValidatedButton
						type="submit"
						level="primary"
						validations={[
							{ valid: targetDate != null, error: 'Datum mag niet leeg zijn' },
						]}>
						<span className="fa fa-check" />&nbsp; Dupliceren</ValidatedButton>
					<Button onClick={() => onClose()}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}