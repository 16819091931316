import { DateTime } from 'luxon';
import React, { useMemo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Checkbox, Form, LoadingOverlayProvider, LoadingOverlayTrigger, Modal, WithLabel } from '@atrocit/scl';
import ButtonArea from '../../lib/forms/ButtonArea';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import FormattedDateTime from '../../lib/formatting/FormattedDateTime';

export default function DuplicateDay({ isOpen, onClose, shifts = [] }) {
	const [ targetDate, setTargetDate ] = useState(shifts[0]?.shiftStart == null ? null : DateTime.fromISO(shifts[0]?.shiftStart).startOf('day'));
	const [ copyRemark, setCopyRemark ] = useState(false);
	const [ copyDockWorkers, setCopyDockWorkers ] = useState(false);
	const [ copyManualSpotTimes, setCopyManualSpotTimes ] = useState(false);
	const [ loading, setLoading ] = useState(false);

	const [ duplicateShifts ] = useMutation(gql`mutation Mutation($instructions: [ PlanningDuplicateInstruction! ]!) {
        duplicatePlanningInstructions(instructions: $instructions) { id }
    }`);

	const projection = useMemo(() => {
		if (shifts == null || shifts.length == 0) return [];
		const earliestTime = shifts.map(s => DateTime.fromISO(s.shiftStart)).sort((a, b) => (a < b ? -1 : (a == b ? 0 : 1)))[0].toLocal().startOf('day');
		// const earliestDiff = targetDate.diff(earliestTime);
		return shifts.map(s => {
			const diffStart = DateTime.fromISO(s.shiftStart).diff(earliestTime);
			const diffEnd = DateTime.fromISO(s.shiftEnd).diff(earliestTime);
			return {
				original: s,
				instruction: {
					fromPlanningId: s.id,
					copyRemark,
					copyDockWorkers,
					copyManualSpotTimes,
					start: targetDate == null || !targetDate.isValid ? null : targetDate.plus(diffStart),
					end: targetDate == null || !targetDate.isValid ? null : targetDate.plus(diffEnd),
				},
			};
		});
	}, [ shifts, targetDate, copyRemark, copyDockWorkers ]);

	return <Modal isOpen={isOpen} title="Dag dupliceren" onRequestClose={onClose}>
		<LoadingOverlayProvider>
			{loading && <LoadingOverlayTrigger />}
			<Form onSubmit={() => {
				setLoading(true);
				const instructions = projection.map(p => p.instruction).map(ins => ({
					...ins,
					start: ins.start.toUTC().toISO(),
					end: ins.end.toUTC().toISO(),
				}));
				duplicateShifts({ variables: { instructions } })
					.finally(() => { setLoading(false); onClose(); });
			}}>
				<WithLabel label="Datum">
					<input
						className="scl-form-element"
						type="date"
						onChange={e => {
							setTargetDate(e.target.value == null ? null : DateTime.fromFormat(e.target.value, 'yyyy-MM-dd'));
						}}
						defaultValue={targetDate == null ? null : targetDate.toFormat('yyyy-MM-dd')} />
				</WithLabel>
				<div>
					<WithLabel isFake={true} label="Overnemen:">
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyRemark} onChange={setCopyRemark}/> &nbsp; <div>Opmerkingen</div>
							</div>
						</WithLabel>
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyDockWorkers} onChange={setCopyDockWorkers}/> &nbsp; <div>Personen</div>
							</div>
						</WithLabel>
						<WithLabel isFake={true}>
							<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
								<Checkbox value={copyManualSpotTimes} onChange={setCopyManualSpotTimes}/> &nbsp; <div>Aangepaste tijden</div>
							</div>
						</WithLabel>
					</WithLabel>
				</div>
				<br />
				<div style={{ borderTop: '1px solid var(--col-grey-100)', paddingBottom: 'var(--u-16)' }} />

				<WithLabel isFake={true} label="Deze shifts worden gemaakt">
					<table className="table" style={{ minWidth: '100%' }}>
						<thead>
							<tr>
								<th>Plekken</th>
								<th>Ingevuld</th>
								<th>Start</th>
								<th>Eind</th>
							</tr>
						</thead>
						<tbody>
							{projection.map(s => <tr key={s.original.id}>
								<td>{s.original.spots.length}</td>
								<td>{s.original.spots.filter(spot => spot.dockWorker != null).length}</td>
								<td>{s.instruction.start == null ? '---' : <FormattedDateTime value={s.instruction.start} />}</td>
								<td>{s.instruction.end == null ? '---' : <FormattedDateTime value={s.instruction.end} />}</td>
							</tr>)}
						</tbody>
					</table>
				</WithLabel>

				<ButtonArea>
					<ValidatedButton
						type="submit"
						level="primary"
						validations={[
							{ valid: projection.filter(shift => shift.instruction.start == null).length == 0, error: 'Niet alle starttijden zijn valide' },
							{ valid: projection.filter(shift => shift.instruction.end == null).length == 0, error: 'Niet alle eindtijden zijn valide' },
						]}>
						<span className="fa fa-check" />&nbsp; Dupliceren</ValidatedButton>
					<Button onClick={() => onClose()}>Annuleren</Button>
				</ButtonArea>
			</Form>
		</LoadingOverlayProvider>
	</Modal>;
}