import { gql, useMutation } from '@apollo/client';
import { Button, ButtonArea, Form, InfoBanner, LoadingOverlay, Modal } from '@atrocit/scl';

export default function MarkDownloadedWorkRecordsAsProcessedModal({ workRecords, onClose }) {
	const [ processWorkRecords, processWorkRecordsMutation ] = useMutation(gql`mutation Mutation($workRecords: [ID!]!) {
		processWorkRecords(workRecords: $workRecords) {
			id,
			needsChange
		}
	}`);

	return <Modal isOpen={true} title="Markeer loonlijnen als verwerkt" onRequestClose={onClose}>
		{processWorkRecordsMutation.loading && <LoadingOverlay />}
		<Form onSubmit={() => processWorkRecords({ variables: { workRecords: workRecords } }).then(() => onClose())}>
			<div style={{ maxWidth: 'var(--u-512)' }}>
				Je kunt nu alle loonlijnen die werden gedownload markeren als verwerkt, of je kunt op Annuleren klikken en het later handmatig doen.<br /><br />
			</div>

			<InfoBanner>{(workRecords?.length)} loonlijnen</InfoBanner>

			<ButtonArea>
				<Button onClick={onClose}>Annuleren</Button>

				<Button level="primary" type="submit">
					<span className="fa fa-check" />&nbsp; Markeren als verwerkt
				</Button>
			</ButtonArea>
		</Form>
	</Modal>;
}