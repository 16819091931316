import React from 'react';

export default function HoverBubble({ content, children }) {
	return <div className="hover-bubble">
		{content != null && (!Array.isArray(content) || content.length > 0) && <div className="tooltip">
			{content}
		</div>}
		<div className="hover-bubble-children">
			{children}
		</div>
	</div>;
}
