export default function enforceNumberWithTwoDecimals(value) {
	// Remove any non-digit or non-decimal point characters
	let cleanValue = value.replace(/[^0-9.]/g, '');

	// Default to "0" if the cleaned value is empty or just a dot
	if (cleanValue === '' || cleanValue === '.') {
		return '0';
	}

	// Count the number of decimal points in the string
	let dotCount = (cleanValue.match(/\./g) || []).length;

	// If there are more than one decimal points, only keep the part up to the first decimal point
	// and the first two digits after it, if any
	if (dotCount > 1) {
		// Find the index of the first decimal point
		let firstDotIndex = cleanValue.indexOf('.');
		// Keep everything up to the first dot and the first two characters (if any) after it
		cleanValue = cleanValue.substring(0, firstDotIndex + 1) + cleanValue.substring(firstDotIndex + 1).replace(/\./g, '').slice(0, 2);
	} else if (dotCount === 1) {
		// If there is exactly one decimal point, split and enforce two decimals if necessary
		let [ integerPart, decimalPart ] = cleanValue.split('.');
		// If there are more than one character, delete leading zeros from the integer part
		if (integerPart.length > 1) {
			integerPart = integerPart.replace(/^0+/, '');
			if (integerPart === '') {
				integerPart = '0';
			}
		}
		// Disallow more than 5 characters in the integer part
		if (integerPart.length > 5) {
			integerPart = integerPart.slice(0, 5);
		}
		// Disallow more than 2 characters in the decimal part
		decimalPart = decimalPart.slice(0, 2);
		cleanValue = `${integerPart}.${decimalPart}`;
	} else {
		// If there are no decimal points, ensure it does not default to empty
		cleanValue = cleanValue || '0';
		// If there are more than one character, delete leading zeros from the integer part
		if (cleanValue.length > 1) {
			cleanValue = cleanValue.replace(/^0+/, '');
			if (cleanValue === '') {
				cleanValue = '0';
			}
		}
		if (cleanValue.length > 5) {
			cleanValue = cleanValue.slice(0, 5);
		}
	}

	// Return the cleaned value
	return cleanValue;
}