import React from 'react';
import Select from 'react-select';

export default function SelectField({ options, onChange, value, ...props }) {
	return <Select
		{...props}
		onChange={onChange}
		value={options.find(o => o.value == value)}
		isClearable={true}
		className="react-select"
		classNamePrefix="react-select"
		options={options} />;
}
