import { useMemo } from 'react';
import { groupBy } from 'lodash';
import { FormattedDate } from 'react-intl';
import { DateTime } from 'luxon';

export default function AbsenceMonth({ specialDays, absences, start, onEdit, showDeniedAbsences }) {
	const absThisMonth = absences.filter(abs => abs.end >= start && abs.start < start.plus({ months: 1 }));
	const absByUsers = useMemo(() => {
		const usersById = absThisMonth.reduce((users, abs) => ({ ...users, [abs.user?.id]: abs.user }), {});
		const groupedAbsences = groupBy(absThisMonth, a => a.user?.id);

		return Object.keys(groupedAbsences)
			.map(userId => ({
				absences: groupedAbsences[userId],
				user: usersById[userId],
			}))
			.sort((a, b) => a.user.fullName.localeCompare(b.user.fullName));
	}, [ absences ]);
	// This constant holds a list of lists, where for every day this month there is a list of absences for that day
	const absencesPerDayThisMonth = Array.from({ length: start.plus({ months: 1 }).diff(start, 'days').days }, (_, i) => {
		const usersAbsentToday = new Set();

		return absThisMonth.filter(a => {
			const isWithinDay = a.end >= start.plus({ days: i }) && a.start < start.plus({ days: i + 1 });

			if (isWithinDay && !usersAbsentToday.has(a.user)) { // check if this user is already absent once today
				usersAbsentToday.add(a.user);
				return true;
			}

			return false;
		});
	});

	return <div className="abs-month">
		<div className="abs-month-row abs-month-header">
			<div />
			{(() => {
				let date = start;
				const output = [];
				while (date < start.plus({ months: 1 })) {
					const isWeekend = date.weekday == 6 || date.weekday == 7;
					const ldate = date;
					const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
					const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
					output.push(<div key={date.toISO()} className={'abs-month-day-header ' + (isWeekend ? 'abs-month-day-header-weekend' : '') + (isSpecialDay ? ' abs-month-day-header-special-day' : '')} style={{ background: isSpecialDay ? sd?.colorCode : undefined, color: isSpecialDay ? 'white' : undefined }}>
						<FormattedDate value={date} weekday="short" /><br /><FormattedDate value={date} day="2-digit" />
					</div>);
					date = date.plus({ days: 1 });
				}
				return output;
			})()}
		</div>
		{/* eslint-disable-next-line no-shadow */}
		{absByUsers.map(({ absences, user }) => <AbsenceMonthUser key={user?.id} specialDays={specialDays} user={user} absences={absences} start={start} onEdit={onEdit} />)}
		<div className="abs-month-row abs-month-footer">
			<div />
			{(() => {
				let date = start;
				const output = [];
				while (date < start.plus({ months: 1 })) {
					const isWeekend = date.weekday == 6 || date.weekday == 7;
					const ldate = date;
					const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
					const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
					output.push(<div key={date.toISO()} className={'abs-month-day-footer ' + (isWeekend ? 'abs-month-day-header-weekend' : '') + (isSpecialDay ? ' abs-month-day-header-special-day' : '')} style={{ background: isSpecialDay ? sd?.colorCode : undefined, color: isSpecialDay ? 'white' : undefined }}>
						<FormattedDate value={date} weekday="short" /><br /><FormattedDate value={date} day="2-digit" />
					</div>);
					date = date.plus({ days: 1 });
				}
				return output;
			})()}
		</div>
		<div className="abs-month-row abs-month-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties aangevraagd:</div>
			{(() => {
				let date = start;
				const output = [];
				let dayCounter = 0;
				while (date < start.plus({ months: 1 })) {
					const isWeekend = date.weekday == 6 || date.weekday == 7;
					const ldate = date;
					const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
					const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
					output.push(<div key={date.toISO()} className={'abs-month-day-footer-lower ' + (isWeekend ? 'abs-month-day-header-weekend' : '') + (isSpecialDay ? ' abs-month-day-header-special-day' : '')} style={{ background: isSpecialDay ? sd?.colorCode : undefined, color: isSpecialDay ? 'white' : undefined }}>
						{absencesPerDayThisMonth[dayCounter].filter(a => a.absenceStatus == "REQUESTED").length}</div>);
					date = date.plus({ days: 1 });
					dayCounter++;
				}
				return output;
			})()}
		</div>
		<div className="abs-month-row abs-month-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties goedgekeurd:</div>
			{(() => {
				let date = start;
				const output = [];
				let dayCounter = 0;
				while (date < start.plus({ months: 1 })) {
					const isWeekend = date.weekday == 6 || date.weekday == 7;
					const ldate = date;
					const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
					const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
					output.push(<div key={date.toISO()} className={'abs-month-day-footer-lower ' + (isWeekend ? 'abs-month-day-header-weekend' : '') + (isSpecialDay ? ' abs-month-day-header-special-day' : '')} style={{ background: isSpecialDay ? sd?.colorCode : undefined, color: isSpecialDay ? 'white' : undefined }}>
						{absencesPerDayThisMonth[dayCounter].filter(a => a.absenceStatus == "APPROVED").length}</div>);
					date = date.plus({ days: 1 });
					dayCounter++;
				}
				return output;
			})()}
		</div>
		{showDeniedAbsences && <div className="abs-month-row abs-month-footer-lower">
			<div style={{ textAlign: 'left' }}>Absenties afgewezen:</div>
			{(() => {
				let date = start;
				const output = [];
				let dayCounter = 0;
				while (date < start.plus({ months: 1 })) {
					const isWeekend = date.weekday == 6 || date.weekday == 7;
					const ldate = date;
					const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
					const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
					output.push(<div key={date.toISO()} className={'abs-month-day-footer-lower ' + (isWeekend ? 'abs-month-day-header-weekend' : '') + (isSpecialDay ? ' abs-month-day-header-special-day' : '')} style={{ background: isSpecialDay ? sd?.colorCode : undefined, color: isSpecialDay ? 'white' : undefined }}>
						{absencesPerDayThisMonth[dayCounter].filter(a => a.absenceStatus == "DENIED").length}</div>);
					date = date.plus({ days: 1 });
					dayCounter++;
				}
				return output;
			})()}
		</div>}
	</div>;
}

function AbsenceMonthUser({ user, specialDays, absences, start, onEdit }) {
	return <div className="abs-month-row">
		<div>{user?.fullName} <span title={'Werkboeknummer: ' + user?.dockWorkerRole?.workbookNr} className="diff-old"> {user?.dockWorkerRole?.workbookNr} </span></div>

		{(() => {
			let date = start;
			const output = [];
			while (date < start.plus({ months: 1 })) {
				const ldate = date;
				const isSpecial = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'));
				const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
				output.push(<div key={date.toISO()}>
					<AbsenceMonthDay absences={absences} isSpecialDay={isSpecial} specialDay={sd} start={date} startOfMonth={date.minus({ days: 1 }) < start} endOfWeek={date.plus({ days: 1 }) >= start.plus({ months: 1 })} onEdit={onEdit} />
				</div>);
				date = date.plus({ days: 1 });
			}
			return output;
		})()}
	</div>;
}

function AbsenceItem({ absence, start, onEdit }) {
	const isStart = absence.start >= start && absence.start < start.plus({ days: 1 });
	const isEnd = absence.end > start && absence.end <= start.plus({ days: 1 });
	const isWeekend = start.weekday == 6 || start.weekday == 7;

	return <div className={"abs-item" + (isStart ? ' abs-item-start' : '') + (isEnd ? ' abs-item-end' : '') + (absence.absenceStatus == 'APPROVED' ? '' : (absence.absenceStatus == 'DENIED' ? ' abs-denied' : ' abs-unsure')) + (isWeekend && !absence.cepaLinesForWeekend ? ' not-cepa-weekend' : '')} onDoubleClick={() => onEdit(absence.id)}>
		<div style={{ display: 'flex', gap: 'var(--u-4)' }}>
			<div className="absence-status">
				{absence.workerRequested && <><span className="fa fa-user" title="Aangevraagd door arbeider" />&nbsp;</>}
				{absence.absenceStatus == 'REQUESTED' && <span className="fa fa-question-circle" title="Aangevraagd" />}
				{absence.absenceStatus == 'APPROVED' && <span className="fa fa-check-circle" title="Goedgekeurd" />}
				{absence.absenceStatus == 'DENIED' && <span className="fa fa-times-circle" title="Afgewezen" />}
			</div>
			{/* <div className="absence-remark">*/}
			{/*	<AbsenceType type={absence.absenceType} />{(absence.remark != null && absence.remark.trim().length > 0) ? <> - {absence.remark}</> : null}*/}
			{/* </div> */}
		</div>
	</div>;
}



function AbsenceMonthDay({ absences, start, startOfWeek, endOfWeek, onEdit, isSpecialDay, specialDay }) {
	const isWeekend = start.weekday == 6 || start.weekday == 7;
	return <div className={"abs-month-day" + (startOfWeek ? ' abs-month-day-first' : '') + (endOfWeek ? ' abs-month-day-last' : '') + (isWeekend ? ' abs-month-day-weekend' : '') + (isSpecialDay ? ' abs-month-day-special' : '')} style={{ background: isSpecialDay ? specialDay?.colorCode : undefined }}>
		{absences.filter(a => a.start < start.plus({ days: 1 }) && a.end > start).map(a => <AbsenceItem key={a.id} absence={a} start={start} onEdit={onEdit} />)}
	</div>;
}