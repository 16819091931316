import { FormattedDate } from 'react-intl';
import { DateTime } from 'luxon';
import { Tooltip } from '@atrocit/scl';
import { AbsenceType } from './AbsenceType';

export default function AbsenceYearCalendarPerUser({ specialDays, absences, start }) {
	return <div className="abs-year-calendar">
		<div className="abs-month-row abs-month-header">
			<div />
			{(() => {
				let output = [];
				const date = start.startOf('month');
				for (let i = 0; i < 31; i++) {
					const thisDate = date.plus({ days: i });
					output.push(<div key={i} className={'abs-month-day-header'}>
						<FormattedDate value={thisDate} day="2-digit" />
					</div>);
				}
				return output;
			})()}
		</div>

		{(() => {
			const output = [];
			for (let i = 0; i < 12; i++) {
				output.push(<AbsenceMonth
					key={i}
					specialDays={specialDays.filter(sd => DateTime.fromISO(sd.date) >= start.startOf('month').plus({ months: i }) && DateTime.fromISO(sd.date) < start.startOf('month').plus({ months: i + 1 }))}
					absences={absences.filter(a => DateTime.fromISO(a.start) < start.startOf('month').plus({ months: i + 1 }) && DateTime.fromISO(a.end) > start.startOf('month').plus({ months: i }))}
					start={start.startOf('month').plus({ months: i })} />);
			}
			return output;
		})()}
	</div>;
}

function AbsenceMonth({ absences, start, specialDays }) {
	return <div className="abs-month-row">
		<div>{start.toFormat('MMMM')}</div>

		{(() => {
			let date = start;
			const output = [];
			while (date < start.plus({ days: 31 })) {
				const ldate = date;
				const isSpecialDay = specialDays.some(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) <= ldate.endOf('day'));
				const sd = specialDays.filter(sd => DateTime.fromISO(sd.date) >= ldate.startOf('day') && DateTime.fromISO(sd.date) < ldate.endOf('day'))[0];
				output.push(<div key={date.toISO()}>
					<AbsenceMonthDay isSpecialDay={isSpecialDay} specialDay={sd} absences={absences} start={date} startOfMonth={date.minus({ days: 1 }) < start} endOfMonth={date.plus({ days: 1 }) >= start.plus({ months: 1 })} isOverflow={date.plus({ days: 1 }) > start.plus({ months: 1 })} />
				</div>);
				date = date.plus({ days: 1 });
			}
			return output;
		})()}
	</div>;
}

function AbsenceItem({ absence, start, onEdit }) {
	const isStart = DateTime.fromISO(absence.start) >= start && DateTime.fromISO(absence.start) < start.plus({ days: 1 });
	const isEnd = DateTime.fromISO(absence.end) > start && DateTime.fromISO(absence.end) <= start.plus({ days: 1 });

	return <div className={"abs-item" + (isStart ? ' abs-item-start' : '') + (isEnd ? ' abs-item-end' : '') + (absence.absenceStatus == 'APPROVED' ? '' : (absence.absenceStatus == 'DENIED' ? ' abs-denied' : ' abs-unsure'))} style={{ overflow: 'visible' }} onDoubleClick={() => onEdit(absence.id)}>
		<Tooltip content={<>
			{DateTime.fromISO(absence.start).toFormat('EEE dd-MM')}{DateTime.fromISO(absence.end).diff(DateTime.fromISO(absence.start), [ 'days' ]).days > 1 && <> &ndash; {DateTime.fromISO(absence.end).toFormat('EEE dd-MM')}</>}
			<br />
			<AbsenceType type={absence.absenceType} />{absence.remark != null && absence.remark.length > 0 && <> &mdash; {absence.remark}</>}
		</>}>
			<div style={{ display: 'flex', gap: 'var(--u-4)', overflow: 'visible' }}>
				<div className="absence-status">
					{absence.workerRequested && <><span className="fa fa-user" title="Aangevraagd door arbeider" />&nbsp;</>}
					{absence.absenceStatus == 'REQUESTED' && <span className="fa fa-question-circle" title="Aangevraagd" />}
					{absence.absenceStatus == 'APPROVED' && <span className="fa fa-check-circle" title="Goedgekeurd" />}
					{absence.absenceStatus == 'DENIED' && <span className="fa fa-times-circle" title="Afgewezen" />}
				</div>
			</div>
		</Tooltip>
	</div>;
}

function AbsenceMonthDay({ absences, isSpecialDay, start, startOfMonth, endOfMonth, isOverflow, onEdit, specialDay }) {
	if (isOverflow) return <div className="abs-month-day" style={{ background: 'var(--col-grey-300)' }} />;

	const isWeekend = start.weekday == 6 || start.weekday == 7;
	return <div className={"abs-month-day" + (startOfMonth ? ' abs-month-day-first' : '') + (endOfMonth ? ' abs-month-day-last' : '') + (isWeekend ? ' abs-month-day-weekend' : '') + (isSpecialDay && !endOfMonth ? ' abs-month-day-special' : '')} style={{ background: isSpecialDay ? specialDay?.colorCode : undefined }}>
		{absences.filter(a => DateTime.fromISO(a.start) < start.plus({ days: 1 }) && DateTime.fromISO(a.end) > start).map(a => <AbsenceItem key={a.id} absence={a} start={start} onEdit={onEdit} />)}
	</div>;
}