import { gql, useMutation, useQuery } from '@apollo/client';
import React, { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import { Button, LoadingOverlay } from '@atrocit/scl';
import WorkRecordDockWorker from './lib/WorkRecordsTable';
import { groupByDockWorker } from './lib/utils';
import { FormattedDate, FormattedNumber, FormattedTime } from 'react-intl';
import ColorDot from '../lib/ui/ColorDot';
import { AbsenceType } from '../absence/AbsenceType';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';

export default function ChangedWorkRecords() {
	// const [ startDate, setStartDate ] = useState(DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 2 }));
// 	const [ endDate, setEndDate ] = useState(DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 2 }));
	// const [ upperbound, setUpperbound ] = useState(DateTime.now().startOf('week').minus({ days: 2 }));

	const workRecordsQuery = useQuery(gql`{
        workRecordsWithChanges {
            id,
            needsChange,
            planSpot {
                id,
                planning {
                    id,
                },
	            start,
	            end,
            },
            dockWorkerTarget {
                id,
                workbookNr,
                category,
                user {
                    id,
                    fullName,
                }
            },
            dockWorkerEntered {
                id,
                workbookNr,
                category,
                user {
                    id,
                    fullName,
                }
            },
            startDateTarget,
	        startDateEntered,
            endDateTarget,
	        endDateEntered,
            absenceTypeTarget,
	        absenceTypeEntered,
            roleTarget {
                id, colorCode, shortCode,
            },
	        roleEntered {
		        id, colorCode, shortCode,
            }
            remarkTarget,
	        remarkEntered,
			calculatedWageTarget,
            calculatedWageEntered,
            calculatedPremiumTarget,
            calculatedPremiumEntered,
            warningOnWageCalculationTarget,
            warningOnWageCalculationEntered,
        }
    }`);
	const workRecords = workRecordsQuery?.data?.workRecordsWithChanges ?? [];

	const [ processWorkRecord ] = useMutation(gql`mutation Mutation($id: Int!) {
        processWorkRecord(workRecord: $id) {
            id,
            needsChange,
        }
    }`);

	return <>
		<TopBar title="Gewijzigde loonlijnen" />
		<div className="grey-page-bg">
			<div className="page">
				{workRecordsQuery.loading && <LoadingOverlay />}

				<PageTop breadcrumbSegments={[ { link: '/administration', label: 'CEPA' }, { link: '/administration/workrecords', label: 'Loonlijnen' }, { link: '/administration/workrecords/changes', label: 'Wijzigingen' } ]} />

				{workRecords.length > 0 && <>
					<table className="table table-fw">
						<colgroup>
							<col width="40" />
							<col width="200" />
							<col width="90" />
							<col width="40" />
							<col width="130" />
							<col width="70" />
							<col width="70" />
							<col width="100" />
							<col width="100" />
							<col width="*" />
							<col width="100" />
							<col width="100" />
						</colgroup>
						<thead>
							<tr>
								<th className="tbl-center">HALO</th>
								<th>Arbeider</th>
								<th>Werkboeknr</th>
								<th>Dag</th>
								<th>Datum</th>
								<th>Start</th>
								<th>Eind</th>
								<th>Rol</th>
								<th>Ab.type</th>
								<th>Opmerking</th>
								<th>Loon</th>
								<th>Premie</th>
							</tr>
						</thead>
						<tbody>
							{workRecords.map(r => {
								return <>
									<tr key={r.id}>
										<td className="tbl-center">
											{!r.needsChange && <span className="fa fa-check fa-check-circle green" />}
											<PrivilegeBarrier privileges={[ 'WRITE_WORK_RECORDS' ]}>
												{r.needsChange && <Button onClick={() => processWorkRecord({ variables: { id: r.id } })}><span className="fa fa-check"/></Button>}
											</PrivilegeBarrier>
										</td>
										<td>
											{r.dockWorkerTarget?.user?.fullName}
											{r.dockWorkerTarget?.id != r.dockWorkerEntered?.id && <><br /><span className="diff-old">{r.dockWorkerEntered?.user?.fullName ?? <>&mdash;</>}</span></>}
										</td>
										<td>
											{r.dockWorkerTarget?.workbookNr}
											{r.dockWorkerTarget?.id != r.dockWorkerEntered?.id && <><br /><span className="diff-old">{r.dockWorkerEntered?.workbookNr ?? <>&mdash;</>}</span></>}
										</td>
										<td>
											{r.startDateTarget == null ? null : <FormattedDate value={DateTime.fromISO(r.startDateTarget)} weekday="short" />}
											{r.startDateTarget != r.startDateEntered && <><br /><span className="diff-old">{r.startDateEntered == null ? <>&mdash;</> : <FormattedDate value={DateTime.fromISO(r.startDateEntered)} weekday="short" />}</span></>}
										</td>
										<td>
											{r.startDateTarget == null ? null : <FormattedDate value={DateTime.fromISO(r.startDateTarget)} day="2-digit" month="2-digit" year="numeric" />}
											{r.startDateTarget != r.startDateEntered && <><br /><span className="diff-old">{r.startDateEntered == null ? <>&mdash;</> : <FormattedDate value={DateTime.fromISO(r.startDateEntered)} day="2-digit" month="2-digit" year="numeric" />}</span></>}
										</td>
										<td>
											{r.startDateTarget == null ? null : <FormattedTime value={DateTime.fromISO(r.startDateTarget)} />}
											{r.startDateTarget != r.startDateEntered && <><br /><span className="diff-old">{r.startDateEntered == null ? <>&mdash;</> : <FormattedTime value={DateTime.fromISO(r.startDateEntered)} />}</span></>}
										</td>
										<td>
											{r.endDateTarget == null ? null : <FormattedTime value={DateTime.fromISO(r.endDateTarget)} />}
											{r.endDateTarget != r.endDateEntered && <><br /><span className="diff-old">{r.endDateEntered == null ? <>&mdash;</> : <FormattedTime value={DateTime.fromISO(r.endDateEntered)} />}</span></>}
										</td>
										<td>
											{r.roleTarget == null ? null : <><ColorDot hex={r.roleTarget?.colorCode || '#fff'} /> {r.roleTarget?.shortCode}</>}
											{r.roleTarget?.id != r.roleEntered?.id && <><br /><span className="diff-old"><ColorDot hex={r.roleEntered?.colorCode || '#fff'} /> {r.roleEntered?.shortCode}</span></>}
										</td>
										<td>
											{r.absenceTypeTarget == null ? null : <AbsenceType type={r.absenceTypeTarget} />}
											{r.absenceTypeTarget != r.absenceTypeEntered && <><br /><span className="diff-old">{r.absenceTypeEntered == null ? <>&mdash;</> : <AbsenceType type={r.absenceTypeEntered} />}</span></>}
										</td>
										<td title={r.remarkTarget}>
											{r.remarkTarget}
											{r.remarkTarget != r.remarkEntered && <><br /><span title={r.remarkEntered || ''} className="diff-old">{r.remarkEntered || <>&mdash;</>}</span></>}
										</td>
										<td style={{ textAlign: 'right' }}>
											{r.warningOnWageCalculationTarget && <span className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} title={`Aangepaste tijden, loonberekening mogelijk niet correct.`}/>}
											{r.calculatedWageTarget == null ? '-' : <FormattedNumber value={r.calculatedWageTarget} style="currency" currency="EUR" />}
											{r.warningOnWageCalculationTarget !== r.warningOnWageCalculationEntered ? (
												<><br /><span className="diff-old">
													{!r.warningOnWageCalculationEntered ? <>&mdash;</> :
														<span className="fa fa-warning" style={{ color: 'var(--col-orange-500)' }} title="Aangepaste tijden, loonberekening mogelijk niet correct."/>}
													{<FormattedNumber value={r.calculatedWageTarget} style="currency" currency="EUR" />}
												</span></>
											) : (
												r.calculatedWageTarget !== r.calculatedWageEntered && (
													<><br /><span className="diff-old">
														{r.calculatedWageEntered == null ? <>&mdash;</> : <FormattedNumber value={r.calculatedWageEntered} style="currency" currency="EUR" />}
													</span></>
												)
											)}
										</td>
										<td style={{ textAlign: 'right' }}>
											{r.calculatedPremiumTarget == null ? '-' : <FormattedNumber value={r.calculatedPremiumTarget} style="currency" currency="EUR" />}
											{r.calculatedPremiumTarget != r.calculatedPremiumEntered && <><br /><span className="diff-old">{r.calculatedPremiumEntered == null ? <>&mdash;</> : <FormattedNumber value={r.calculatedPremiumEntered} style="currency" currency="EUR" />}</span></>}
										</td>
									</tr>
								</>;
							})}
						</tbody>
					</table>
				</>}

				{workRecords.length == 0 && <div style={{ textAlign: 'center', color: 'var(--col-grey-500)', padding: '32px 0', fontStyle: 'italic' }}>Geen gewijzigde lijnen gevonden</div>}
			</div>
		</div>
	</>;
}