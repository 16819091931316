import { Draggable } from 'react-beautiful-dnd';
import RoleSelectField from '../../lib/selectfields/RoleSelectField';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import DockWorkerSelectField from '../../lib/selectfields/DockWorkerSelectField';
import { gql, useMutation } from '@apollo/client';
import { Button, InputField } from '@atrocit/scl';
import DeleteSpotModal from './DeleteSpotModal';
import SpotRemark from './SpotRemark';
import { PrivilegesContext } from '../../../context/PrivilegesContext';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
import ChangeSpotTimesModal from './ChangeSpotTimesModal';
import { FormattedTime } from 'react-intl/lib';
import SpotRemarkViewLevel from './SpotRemarkViewLevel';

export default function Spot({ readOnly = false, spot, idx, duplicateUserIds, absentUserIds, alreadyPlannedUserIds, usersInAdjacentShift, planning }) {
	const privileges = useContext(PrivilegesContext);
	const [ roleId, setRoleId ] = useState(spot.role?.id);
	const [ dockWorkerId, setDockWorkerId ] = useState(spot.dockWorker?.id);
	const [ showDeleteSpotModal, setShowDeleteSpotModal ] = useState(false);
	const [ remarkVisibility, setRemarkVisibility ] = useState(spot.remarkVisibility ?? 'PUBLIC');
	const [ showChangeTimesModal, setShowChangeTimesModal ] = useState(false);
	const [ editingRemark, setEditingRemark ] = useState(false);

	const [ changeSpotRole ] = useMutation(gql`mutation Mutation($spotId: Int!, $roleId: Int) {
		changeSpotRole(spotId: $spotId, roleId: $roleId) { id }
	}`);

	const [ changeSpotDockWorker ] = useMutation(gql`mutation Mutation($spotId: Int!, $dockWorkerId: Int) {
        changeSpotDockWorker(spotId: $spotId, dockWorkerId: $dockWorkerId) { id }
    }`);

	const [ changeSpotRemarkAndVisibility ] = useMutation(gql`mutation Mutation($spotId: Int!, $remark: String!, $remarkVisibility: SpotRemarkVisibility!) {
		changeSpotRemarkAndVisibility(spotId: $spotId, remark: $remark, remarkVisibility: $remarkVisibility) { id }
	}`);

	// Override user selection whenever an update from the backend comes in
	useEffect(() => {
		if (roleId == spot.role?.id && dockWorkerId == spot.dockWorker?.id) return;
		setRoleId(spot.role?.id);
		setDockWorkerId(spot.dockWorker?.id);
	}, [ spot ]);

	const updateRole = useCallback((roleId, role) => {
		if (!privileges.has('PLANSPOT_CHANGE_ROLE')) return;
		setRoleId(roleId);
		changeSpotRole({ variables: { spotId: spot.id, roleId } });
	}, [ setRoleId, spot.id, changeSpotRole ]);

	const updateDockWorker = useCallback((dwId, user) => {
		if (!privileges.has('PLANSPOT_CHANGE_DOCKWORKER')) return;
		setDockWorkerId(dwId);
		changeSpotDockWorker({ variables: { spotId: spot.id, dockWorkerId: dwId } });
	}, [ setDockWorkerId, spot.id, changeSpotDockWorker ]);

	return <>
		<DeleteSpotModal spotId={spot.id} isOpen={showDeleteSpotModal} onClose={() => setShowDeleteSpotModal(false)} />
		{showChangeTimesModal && <ChangeSpotTimesModal spot={spot} isOpen={showChangeTimesModal} onClose={() => setShowChangeTimesModal(false)} privileges={privileges} planningShiftStartDate={planning.shiftStart} planningShiftEndDate={planning.shiftEnd} />}
		<Draggable isDragDisabled={!privileges.has('PLANSPOT_CHANGE_POSITION')} draggableId={spot.id} index={idx}>
			{(provided, snapshot) => <div ref={provided.innerRef} className="spot" {...provided.draggableProps} {...provided.dragHandleProps}>
				<div style={{ display: 'grid', gridTemplateColumns: '12px 96px 1fr 24px', gap: 'var(--u-4)' }}>
					<div style={{ alignItems: 'center', display: 'flex' }}>
						<div style={{ color: 'var(--col-grey-300)' }}>
							<span className="fa fa-ellipsis-v" style={{ marginRight: '1px' }} />
							<span className="fa fa-ellipsis-v" />
						</div>
					</div>
					<label style={{ padding: 0 }}>
						<RoleSelectField disabled={readOnly || !privileges.has('PLANSPOT_CHANGE_ROLE')} onChange={updateRole} value={roleId} />
					</label>
					<label style={{ padding: 0 }}>
						<DockWorkerSelectField disabled={readOnly || !privileges.has('PLANSPOT_CHANGE_DOCKWORKER')} restrictedRoleId={roleId} onChange={updateDockWorker} value={dockWorkerId} alreadyPlannedUserIds={alreadyPlannedUserIds} duplicateUserIds={duplicateUserIds} absentUserIds={absentUserIds} usersInAdjacentShift={usersInAdjacentShift} />
					</label>
					<div>
						<PrivilegeBarrier privileges={[ 'PLANSPOT_DELETE' ]}>
							<Button onClick={() => setShowDeleteSpotModal(true)} level="tertiary" style={{ width: '24px', textAlign: 'center' }}>
								<span className="fa fa-trash" />
							</Button>
						</PrivilegeBarrier>
					</div>
				</div>
				{spot.start && spot.end && <div style={{ display: 'grid', gridTemplateColumns: '12px 1fr 24px', gap: 'var(--u-4)', minHeight: '24px', alignItems: 'center' }} onDoubleClick={() => (privileges.has('PLANSPOT_CHANGE_TIMES') ? setShowChangeTimesModal(true) : null)}>
					<div />
					<div className='date-time-display' style={{ verticalAlign: 'baseline' }}>Aangepaste tijden: <FormattedTime value={spot.start} /> - <FormattedTime value={spot.end} /></div>
				</div>}
				<div style={{ display: 'grid', gridTemplateColumns: '12px 1fr 24px', gap: 'var(--u-4)', alignItems: 'baseline', minHeight: '24px' }}>
					<div>{spot.remark != null && spot.remark.length > 0 && privileges.has('PLANSPOT_CHANGE_REMARK') &&
						<div title={spot.remarkVisibility == 'PUBLIC' ? 'Zichtbaar voor alle arbeiders.' : (spot.remarkVisibility == 'ADMIN_ONLY' ? 'Alleen zichtbaar voor de ceelbaas en HR.' : 'Alleen zichtbaar voor de ceelbaas, HR en deze arbeider.')}
						        className={spot.remarkVisibility == 'PUBLIC' ? "fa fa-users" : (spot.remarkVisibility == 'ADMIN_ONLY' ? "fa fa-lock" : "fa fa-user")}
						        style={{ marginLeft: '-2px' }} />
					}</div>
					<SpotRemark remark={spot.remark} onUpdateRemark={(newRemark) => changeSpotRemarkAndVisibility({ variables: { spotId: spot.id, remark: newRemark, remarkVisibility: remarkVisibility } })} editMode={editingRemark} setEditMode={setEditingRemark} />
					{spot.start == null && spot.end == null && privileges.has('PLANSPOT_CHANGE_TIMES') && <Button onClick={() => setShowChangeTimesModal(true)} level="tertiary" style={{ textAlign: 'center' }}><span className="fa fa-clock-o"/></Button>}
				</div>
				{editingRemark && <div style={{ display: 'grid', gridTemplateColumns: '12px 1fr 24px', gap: 'var(--u-4)', minHeight: '24px', alignItems: 'center', paddingTop: '5px' }}>
					<div />
					<label style={{ padding: 0 }}>
						<SpotRemarkViewLevel remarkVisibility={remarkVisibility} setRemarkVisibility={(vis) => setRemarkVisibility(vis.value)} />
					</label>
					<div />
				</div>}
			</div>}
		</Draggable>
	</>;
}