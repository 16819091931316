export function groupByDockWorker(workRecords) {
	const byDwId = {};
	workRecords.forEach(wr => {
		const dockWorker = wr.dockWorkerTarget ?? wr.dockWorkerEntered;
		if (byDwId[dockWorker.id] == null) {
			byDwId[dockWorker.id] = { dockWorker, records: [] };
		}

		byDwId[dockWorker.id].records.push(wr);
	});

	return Object.values(byDwId).sort((a, b) => {
		if (a.dockWorker.user.fullName.trim().toLowerCase() < b.dockWorker.user.fullName.trim().toLowerCase()) {
			return -1;
		} else if (a.dockWorker.user.fullName.trim().toLowerCase() == b.dockWorker.user.fullName.trim().toLowerCase()) {
			return 0;
		} else {
			return 1;
		}
	});
}