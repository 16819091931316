import SideBar from './nav/SideBar';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import UserContext from '../context/UserContext';
import AuthRefresh from '../context/AuthRefresh';
import { useContext, useEffect } from 'react';
import UseMagicLink from './auth/UseMagicLink';
import Login from './auth/Login';
import UsersOverview from './users/UsersOverview';
import AddEditUser from "./users/AddEditUser";
import EditDay from './shifts/edit/EditDay';
import MyShifts from './shifts/MyShifts';
import ViewShift from './shifts/view/ViewShift';
import { useNavigate } from 'react-router';
import AbsenceCalendar from './absence/AbsenceCalendar';
import RoleOverview from './roles/RoleOverview';
import AddEditRole from './roles/AddEditRole';
import ShiftsWeekOverview from './shifts/calendar/ShiftsWeekOverview';
import SavingsOverview from './savingrecords/SavingsOverview';
import WageRecordOverview from './wagerecords/WageRecordOverview';
import UnattachedWageRecords from './wagerecords/UnattachedWageRecords';
import AbsenceMonthCalendar from './absence/AbsenceMonthCalendar';
import WorkRecordsToEnter from './administration/WorkRecordsToEnter';
import ChangedWorkRecords from './administration/ChangedWorkRecords';
import WorkRecordsOverview from './administration/WorkRecordsOverview';
import AbsencesToApprove from './administration/AbsencesToApprove';
import AbsencesByUser from './absence/AbsencesByUser';
import SpecialDaysOverview from './absence/specialdays/SpecialDaysOverview';
import ConfigContext from '../context/ConfigContext';
import BoortmaltCsvGenerator from './administration/csvgenerator/BoortmaltCsvGenerator';
import AnalyticsOverview from './analytics/AnalyticsOverview';
import PrivilegeBarrier from './auth/PrivilegeBarrier';
import ViewSavingsRecord from './savingrecords/ViewSavingsRecord';
import WageTableOverview from './administration/wagetables/WageTableOverview';
import AddEditWageTable from './administration/wagetables/AddEditWageTable';
import PersonBasedView from './shifts/calendar/PersonBasedView';
import MyAbsences from './absence/MyAbsences';
import ViewAbsenceRequest from './absence/ViewAbsenceRequest';
import SavingsRecordsStats from './savingrecords/SavingsRecordsStats';

export default function RoutesInternal() {
	const config = useContext(ConfigContext);
	const user = useContext(UserContext);
	const refreshAuth = useContext(AuthRefresh);

	return <Router>
		{user == null && <div className="auth" style={{ position: 'relative' }}>
			<div style={{ position: 'fixed', inset: 0, overflow: 'hidden' }}>
				<div className="top-graphic" style={{ filter: 'grayscale(50%)' }}>
					<img src="/assets/login_bg.jpeg" style={{ width: '100%', minHeight: '70vh', objectFit: 'cover', objectPosition: 'center center', opacity: 0.2 }} alt="" />
				</div>
				<div className="bg-graphic" style={{ position: 'absolute', background: config.themeColor, height: '100vh', top: '56vh', width: '150vw', zIndex: 0, transform: 'rotate(-4deg)' }}>
				</div>
			</div>

			<div className="login-logo">
				<img src="/assets/logo_dark.png" alt="Logo" className="atrocit-logo" />
			</div>
			<h1 style={{ fontWeight: 'normal', padding: 0, margin: 0, color: config.themeColor, marginTop: '-16px', marginBottom: '16px' }}>Personeelplanner</h1>
			<div className="login-frame">
				<Routes>
					<Route element={<UseMagicLink refreshAuth={refreshAuth} />} path="/magiclink/:token" />
					<Route element={<Login />} path="*" />
				</Routes>
			</div>
		</div>}
		{user != null && <div className="app">
			<SideBar />
			<div className="app-content">
				<Routes>
					<Route element={<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><MyShifts /></PrivilegeBarrier>} path="/myShifts" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><MyAbsences /></PrivilegeBarrier>} path="/myAbsences" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><ViewAbsenceRequest /></PrivilegeBarrier>} path="/myAbsences/:id" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><ViewAbsenceRequest /></PrivilegeBarrier>} path="/myAbsences/new" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><ViewShift /></PrivilegeBarrier>} path="/shifts/view/:id" />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]}><RedirectRoute target={'/shifts/week'} /></PrivilegeBarrier>} path="/shifts" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]}><ShiftsWeekOverview /></PrivilegeBarrier>} path="/shifts/week" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]}><ShiftsWeekOverview /></PrivilegeBarrier>} path="/shifts/week/:weekYear" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_VISIBLE_PLANNINGS', 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS', 'WRITE_PLANNINGS' ]}><EditDay /></PrivilegeBarrier>} path={"/shifts/edit/:viewId/:date"} />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_VISIBLE_PLANNINGS', 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS', 'WRITE_PLANNINGS' ]}><PersonBasedView /></PrivilegeBarrier>} path={"/shifts/byperson"} />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_VISIBLE_PLANNINGS', 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS', 'WRITE_PLANNINGS' ]}><PersonBasedView /></PrivilegeBarrier>} path={"/shifts/byperson/:weekYear"} />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><WorkRecordsOverview /></PrivilegeBarrier>} path="/administration/workrecords" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><WorkRecordsToEnter /></PrivilegeBarrier>} path="/administration/workrecords/toEnter" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><ChangedWorkRecords /></PrivilegeBarrier>} path="/administration/workrecords/changes" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><Navigate to="/administration/workrecords/toEnter" /></PrivilegeBarrier>} path="/administration" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><BoortmaltCsvGenerator /></PrivilegeBarrier>} path="/administration/bmcsvgenerator" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><WageTableOverview /></PrivilegeBarrier>} path="/administration/wagetables" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><AddEditWageTable /></PrivilegeBarrier>} path="/administration/wagetables/create" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}><AddEditWageTable /></PrivilegeBarrier>} path="/administration/wagetables/edit/:id" />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_USERS' ]}><UsersOverview /></PrivilegeBarrier>} path="/users" />
					<Route element={<PrivilegeBarrier privileges={[ 'WRITE_USERS' ]}><AddEditUser /></PrivilegeBarrier>} path="/users/new" />
					<Route element={<PrivilegeBarrier privileges={[ 'WRITE_USERS' ]}><AddEditUser /></PrivilegeBarrier>} path="/users/:id" />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]}><RedirectRoute target={'/absences/calendar'} /></PrivilegeBarrier>} path="/absences" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]} ><AbsenceCalendar /></PrivilegeBarrier>} path="/absences/calendar" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]} ><AbsenceMonthCalendar /></PrivilegeBarrier>} path="/absences/calendar/month" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]} ><AbsencesToApprove /></PrivilegeBarrier>} path="/absences/approval" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]} ><AbsencesByUser /></PrivilegeBarrier>} path="/absences/byuser" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]} ><SpecialDaysOverview /></PrivilegeBarrier>} path="/absences/specialdays" />


					<Route element={<PrivilegeBarrier privileges={[ 'READ_OWN_SAVINGS_RECORD', 'READ_ALL_SAVINGS_RECORDS' ]} ><SavingsOverview /></PrivilegeBarrier>} path="/savings" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS' ]} ><SavingsRecordsStats /></PrivilegeBarrier>} path="/savings/stats" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_SAVINGS_RECORDS' ]} ><ViewSavingsRecord /></PrivilegeBarrier>} path="/savings/:id" />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_OWN_WAGE_RECORDS', 'READ_ALL_WAGE_RECORDS' ]}><WageRecordOverview /></PrivilegeBarrier>} path="/wages" />
					<Route element={<PrivilegeBarrier privileges={[ 'READ_ALL_WAGE_RECORDS' ]}><UnattachedWageRecords /></PrivilegeBarrier>} path="/wages/unattached" />

					<Route element={<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}><RoleOverview /></PrivilegeBarrier>} path="/roles" />
					<Route element={<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}><AddEditRole /></PrivilegeBarrier>} path="/roles/edit/:id" />
					<Route element={<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}><AddEditRole /></PrivilegeBarrier>} path="/roles/new" />

					<Route element={<PrivilegeBarrier privileges={[ 'READ_ANALYTICS' ]}><AnalyticsOverview /></PrivilegeBarrier>} path="/analytics" />

					<Route element={<RedirectRoute target="/myShifts" />} path="/*" />
					<Route element={<RedirectRoute target="/myShifts" />} path="/" index />
					<Route element={<RedirectRoute target="/myShifts" />} path="/" />
				</Routes>
			</div>
		</div>}
	</Router>;
}

function RedirectRoute({ target }) {
	const nav = useNavigate();
	useEffect(() => {
		nav(target);
	}, [ nav ]);
	return null;
}