import TopBar from '../../TopBar';
import { gql, useQuery } from '@apollo/client';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import React, { useState } from 'react';
import AddEditSpecialDay from './AddEditSpecialDay';
import { Button, DestructiveButton, DropdownButton } from '@atrocit/scl';
import DeleteSpecialDay from './DeleteSpecialDay';
import ColorDot from '../../lib/ui/ColorDot';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';

export const DEFAULT_SPECIAL_DAY_COLOR = '#df8c20';

export default function SpecialDaysOverview() {
	const [ addEditModalOpen, setAddEditModalOpen ] = useState(false);
	const [ editModalId, setEditModalId ] = useState(null);
	const [ deleteModalId, setDeleteModalId ] = useState(null);

	const specialDaysQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		specialDays(start: $start, end: $end) {
			id,
			date,
			affectsWages,
			name,
			colorCode
		}
	}`, { variables: { start: DateTime.now().startOf('day').toISO(), end: DateTime.now().startOf('day').plus({ years: 100 }).toISO() } });
	const specialDays = specialDaysQuery?.data?.specialDays ?? [];

	return <>
		<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
			{addEditModalOpen && <AddEditSpecialDay id={editModalId} onClose={() => { setAddEditModalOpen(false); setEditModalId(null); specialDaysQuery.refetch(); }} />}
			{deleteModalId != null && <DeleteSpecialDay id={deleteModalId} onClose={() => { setDeleteModalId(null); specialDaysQuery.refetch(); }} />}
		</PrivilegeBarrier>

		<TopBar title="Feestdagen" />

		<div className="grey-page-bg">
			<div className="page">
				<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
					<div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: 'var(--u-16)' }}>
						<Button onClick={() => setAddEditModalOpen(true)}><span className="fa fa-calendar-plus-o" />&nbsp; Feestdag toevoegen</Button>
					</div>
				</PrivilegeBarrier>

				<table className="table table-fw">
					<colgroup>
						<col width="40" />
						<col width="100" />
						<col width="120" />
						<col width="70" />
						<col width="*" />
						<col width="180" />
					</colgroup>

					<thead>
						<tr>
							<th />
							<th>Dag</th>
							<th>Datum</th>
							<th title="Affecteert lonen">Loon beïnv.</th>
							<th>Naam</th>
							<th />
						</tr>
					</thead>
					<tbody>
						{specialDays.map(sd => <tr key={sd.id}>
							<td className="tbl-center">
								<ColorDot hex={sd.colorCode || DEFAULT_SPECIAL_DAY_COLOR} />
							</td>
							<td><FormattedDate value={sd.date} weekday="long" /></td>
							<td><FormattedDate day="2-digit" month="2-digit" year="numeric" value={sd.date} /></td>
							<td style={{ textAlign: 'center' }}><span className={sd.affectsWages ? "fa fa-check-circle green" : "fa fa-times-circle red"} /></td>
							<td>{sd.name}</td>
							<td className="tbl-center">
								<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
									<div style={{ display: 'flex', gap: 'var(--u-8)', justifyContent: 'center' }}>
										<Button onClick={() => { setEditModalId(sd.id); setAddEditModalOpen(true); }}><span className="fa fa-pencil" />&nbsp; Bewerken</Button>
										<DestructiveButton onClick={() => setDeleteModalId(sd.id)}><span className="fa fa-trash-o" /></DestructiveButton>
									</div>
								</PrivilegeBarrier>
							</td>
						</tr>)}
					</tbody>
				</table>
			</div>
		</div>
	</>;
}