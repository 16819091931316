import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { LoadingOverlay } from '@atrocit/scl';
// import DeleteUserModal from './DeleteUserModal';
import { Link } from "react-router-dom";
import LinkButton from '../lib/forms/LinkButton';
import PageTop from '../lib/ui/PageTop';
import TopBar from '../TopBar';
import ColorDot from '../lib/ui/ColorDot';

export default function RoleOverview() {
	// const [ query, setQuery ] = useState('');
	// const [ deleteUserId, setDeleteUserId ] = useState(null);
	// const [ showDeleteUserModal, setShowDeleteUserModal ] = useState(false);

	const roleQuery = useQuery(gql`{
        roles {
            id, shortCode, name, colorCode
        }
    }`);
	const roles = (roleQuery?.data?.roles ?? []);

	return <>
		{roleQuery.loading && <LoadingOverlay />}
		<TopBar title="Rollen" />
		<div className="page">

			<PageTop
				breadcrumbSegments={[
					{ link: '/roles', label: 'Rollen' },
					{ link: '/roles', label: 'Overzicht' },
				]}>
				<LinkButton to='/roles/new'><span className="fa fa-plus" />&nbsp; Rol toevoegen</LinkButton>
			</PageTop>
			<br />
			<table className="table table-fw">
				<colgroup>
					<col width="40" />
					<col width="60" />
					<col width="*" />
					<col width="130" />
				</colgroup>
				<thead>
					<tr>
						<th>Kleur</th>
						<th>Code</th>
						<th>Naam</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{roles.map(r => <tr key={r.id}>
						<td>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<ColorDot hex={r.colorCode} />
							</div>
						</td>
						<td>{r.shortCode}</td>
						<td>{r.name}</td>
						<td>
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<Link className={"scl-button scl-button-secondary"} to={'/roles/edit/' + r.id}><span className="fa fa-pencil" />&nbsp; Bewerken</Link>
							</div>
						</td>
					</tr>)}
					{!roleQuery.loading && roles.length == 0 && <tr>
						<td colSpan={5}>Geen resultaten</td>
					</tr>}
				</tbody>
			</table>

		</div>
	</>;
}