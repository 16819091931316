import React from 'react';
import { NavLink } from 'react-router-dom';

export default function SideBarLink({ label, icon, to, collapsed = false, itemExpanded, children }) {
	return <>
		<NavLink className={"sidebar-link" + (itemExpanded ? ' sidebar-link-show-submenu' : '')} to={to}>
			<div className="sidebar-link-left-side">
				<span className="sidebar-link-icon"><span className={'fa fa-' + icon} /></span>
				{!collapsed && <span className="sidebar-link-label">{label}</span>}
			</div>

			{children != null && <div className="sidebar-link-arrow">
				<span className="fa fa-angle-down" />
			</div>}
		</NavLink>
		{children != null && <div className="sidebar-subitems">
			{children}
		</div>}
	</>;
}