import DropdownButton from '../../lib/forms/DropdownButton';
import LinkButton from '../../lib/forms/LinkButton';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Button } from '@atrocit/scl';
import DayRemark from './DayRemark';
import { gql, useQuery } from '@apollo/client';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
import { ShowSpecialDays } from '../../absence/AbsenceWeek';

export default function ShiftsWeekDay({ view, dayStart, shifts, setDuplicateDay, setAddShift, setDuplicateShiftId, setDeleteShiftId, specialDays }) {
	const dayRemarkQuery = useQuery(gql`query Query($viewId: Int!, $lowerbound: Instant!, $upperbound: Instant!) {
		findRemarksByDay(viewId: $viewId, lowerbound: $lowerbound, upperbound: $upperbound) {
			id,
			remarkType,
			remark,
			createdAt,
		}
	}`, { variables: { viewId: parseInt(view?.id, 10), lowerbound: dayStart.toUTC().toISO(), upperbound: dayStart.endOf('day').toUTC().toISO() }, skip: view?.id == null });
	const remark = (dayRemarkQuery?.data?.findRemarksByDay ?? [])[0]?.remark ?? '';

	return <div className="shift-wkday">
		<div className="shift-wkday-header">
			<ShowSpecialDays specialDays={specialDays} date={dayStart} />

			<div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'column' }}>
				<div style={{ paddingBottom: 'var(--u-8)', lineHeight: '1.2em' }}>
					<FormattedDate weekday="long" value={dayStart.toJSDate()} /><br />
					<FormattedDate day="2-digit" month="2-digit" value={dayStart.toJSDate()} />
				</div>

				<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS', 'PLANSPOT_CHANGE_DOCKWORKER', 'PLANSPOT_CHANGE_ROLE', 'PLANSPOT_CHANGE_REMARK', 'PLANSOT_CHANGE_POSITION', 'PLANSPOT_CREATE', 'PLANSPOT_DELETE' ]}>
					<DropdownButton mainAction={<LinkButton to={'/shifts/edit/' + view?.id + '/' + dayStart.toFormat('yyyy-MM-dd')}><span className="fa fa-pencil" />&nbsp; Bewerken</LinkButton>}>
						<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}>
							<Link to="#" onClick={() => setDuplicateDay(dayStart)}><span className="fa fa-copy" />&nbsp; Dupliceren</Link>
						</PrivilegeBarrier>
					</DropdownButton>
				</PrivilegeBarrier>
			</div>
		</div>

		{(view?.shiftDefinitions || []).slice().sort((a, b) => (a.startTime < b.startTime ? -1 : 1)).map(sd => {
			const myShifts = shifts.filter(s => s.shiftDefinition.id == sd.id);
			return <div className="shift-wkday-item">
				{myShifts.map(s => <Shift key={s.id} shift={s} setDeleteShiftId={setDeleteShiftId} setDuplicateShiftId={setDuplicateShiftId} />)}
				<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}>
					{myShifts.length == 0 && <div style={{ display: 'inline-block', margin: '0 auto' }}>
						{myShifts.length == 0 && <Button onClick={() => setAddShift({ view, shiftDefinition: sd, dayStart, from: dayStart.plus({ hours: 6 }), to: dayStart.plus({ hours: 13.5 }) })}><span className="fa fa-plus" /></Button>}
					</div>}
				</PrivilegeBarrier>
			</div>;
		})}
		<div>
			<PrivilegeBarrier privileges={[ 'READ_DAY_REMARKS' ]}>
				<DayRemark viewId={view?.id} dayStart={dayStart} remark={remark} onRefresh={() => dayRemarkQuery.refetch()} />
			</PrivilegeBarrier>
		</div>
	</div>;
}

function Shift({ shift, setDuplicateShiftId, setDeleteShiftId }) {
	return <div className="shift">
		<div style={{ display: 'grid', gridTemplateColumns: '26px 1fr auto', alignItems: 'center' }}>
			<div>
				{shift.status == 'VISIBLE'
					? <div style={{ borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'var(--col-green-200)', color: 'var(--col-green-700)' }}><span className="fa fa-eye" /></div>
					: <div style={{ borderRadius: '50%', width: '25px', height: '25px', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'var(--col-red-200)', color: 'var(--col-red-700)' }}><span className="fa fa-eye-slash" /></div>}
			</div>
			<div style={{ textAlign: 'center' }}>
				<FormattedTime value={DateTime.fromISO(shift.shiftStart).toJSDate()} /> - <FormattedTime value={DateTime.fromISO(shift.shiftEnd).toJSDate()} /><br />
				{shift.spots.length} {shift.spots.length == 1 ? 'plek' : 'plekken'}
			</div>

			<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}>
				<DropdownButton
					mainAction={null}>
					<Link to={'/shifts/view/' + shift.id}><span className="fa fa-pencil-square-o" />&nbsp; Bekijken</Link>
					<Link to="#" onClick={() => setDuplicateShiftId(shift.id)}><span className="fa fa-copy" />&nbsp; Dupliceren</Link>
					<Link to="#" onClick={() => setDeleteShiftId(shift.id)} className="danger"><span className="fa fa-trash-o" />&nbsp; Verwijderen</Link>
				</DropdownButton>
			</PrivilegeBarrier>
			<PrivilegeBarrier privileges={[ 'READ_CONCEPT_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_VISIBLE_FUTURE_PLANNINGS' ]} excludeOnThese={[ 'WRITE_PLANNINGS' ]}>
				<LinkButton to={'/shifts/view/' + shift.id}><span className="fa fa-chevron-right" /></LinkButton>
			</PrivilegeBarrier>
		</div>
	</div>;
}