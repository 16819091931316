import { gql, useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';

export default function DockWorkerSelectField({ disabled, duplicateUserIds = new Set(), absentUserIds = new Set(), alreadyPlannedUserIds = new Set(), usersInAdjacentShift = new Set(), onChange, value, placeholder = '', restrictedRoleId, ...props }) {
	const userQuery = useQuery(gql`{
        users {
            id,
            firstName,
            fullName,
            dockWorkerRole {
                id,
	            allowedRoles { id }
            },
	        deletedOn
        }
    }`);

	const options = useMemo(() => {
		return (userQuery?.data?.users ?? [])
			.filter(u => u.deletedOn == null)
			.filter(u => u.dockWorkerRole != null)
			.filter(u => restrictedRoleId == null || (u.dockWorkerRole.id == value || u.dockWorkerRole.allowedRoles.map(r => '' + r.id).includes('' + restrictedRoleId)))
			.map(u => ({
				value: u.dockWorkerRole.id,
				label: u.fullName,
				original: u,
			}));
	}, [ userQuery?.data?.users, restrictedRoleId ]);

	const onChangeCb = useCallback((obj) => {
		onChange(obj?.value ?? null, obj?.original);
	}, [ onChange ]);

	return <Select
		{...props}
		isDisabled={disabled}
		className="react-select"
		classNamePrefix="react-select"
		placeholder={placeholder}
		loading={userQuery.loading}
		isClearable={true}
		openMenuOnFocus={true}
		options={options}
		onChange={onChangeCb}
		formatOptionLabel={(option, { context }) => <span>
			<span style={{ color: 'var(--col-red-600)' }}>
				{context == 'menu' && alreadyPlannedUserIds.has(option.original.id) && <span className="fa fa-calendar-o">&nbsp;</span>}
				{context == 'menu' && usersInAdjacentShift.has(option.original.id) && !alreadyPlannedUserIds.has(option.original.id) && <span className="fa fa-repeat">&nbsp;</span>}
				{context == 'value' && duplicateUserIds.has(option.original.id) && <span className="fa fa-warning">&nbsp;</span>}
				{context == 'value' && usersInAdjacentShift.has(option.original.id) && !duplicateUserIds.has(option.original.id) && <span className="fa fa-repeat">&nbsp;</span>}
				{absentUserIds.has(option.original.id) && <><span className="fa fa-plane" />&nbsp;</>}
			</span>
			{option.label}
		</span>}
		value={options.find(o => o.value == value)}
		styles={{ noOptionsMessage: base => ({ ...base, ...noOptionsMessageStyle }) }} />;
}

const noOptionsMessageStyle = {
	color: "var(--col-grey-600)",
	borderRadius: "var(--u-2)",
	fontSize: "var(--u-14)",
};