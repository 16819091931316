import Breadcrumbs from '../Breadcrumbs';

export default function PageTop({ breadcrumbSegments, children, style = {} }) {
	return <>
		<div className="topbar-spacer" />
		<div style={{ paddingBottom: 'var(--u-16)', display: 'flex', justifyContent: 'space-between', alignItems: 'center', ...style }}>

			{breadcrumbSegments != null && <Breadcrumbs segments={breadcrumbSegments} />}
			<div>
				{children}
			</div>
		</div>
	</>;
}