import React, { useMemo, useState } from 'react';
import Warnings from './Warnings';
import { Droppable } from 'react-beautiful-dnd';
import Spot from './Spot';
import ButtonArea from '../../lib/forms/ButtonArea';
import AddSpot from './AddSpot';
import { Button, DropdownButton, LinkButton, Toggle } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';
import { FormattedTime } from 'react-intl/lib';
import RoleSummary from '../RoleSummary';
import ShiftRemark from './ShiftRemark';
import ReadOnlySpot from './ReadOnlySpot';
import ShiftTrackRemark from './ShiftTrackRemark';
import ExternalRemark from './ExternalRemark';
import PrivilegeBarrier from '../../auth/PrivilegeBarrier';
import ExportPayslipModal from './ExportPayslipModal';

export default function EditSingleShift({ view, spots, usersInAdjacentShift, planning, absences, onExportToExcel }) {
	const id = planning.id;
	const absentUserIds = useMemo(() => new Set(absences.map(a => a.user.id)), [ absences ]);
	const [ downloadPayslipModal, setDownloadPayslipModal ] = useState(false);

	// Checks if there are any (planned or unplanned) planspots on this track
	function isEmptyTrack(track) {
		return planning.spots.filter(s => s.track?.id == track.track?.id).length == 0 && spots.filter(s => s.track?.id == track.track?.id && (s.planning?.shiftDefinition?.code == planning?.shiftDefinition?.code || s.shiftCode == planning?.shiftDefinition?.code) && s.planning?.id != planning.id).length == 0;
	}

	// Object that stores an attached track and a boolean if it is collapsed or not; autocollapses all empty tracks on page load
	const [ collapsibleAttachedTracks, setCollapsibleAttachedTracks ] = useState(() => (view?.attachedTracks ?? []).map(at => ({ track: at, collapsed: isEmptyTrack(at) })));

	function uncollapseAllNotEmpty() {
		setCollapsibleAttachedTracks(prevTracks => prevTracks.map(at => ({ ...at, collapsed: !isEmptyTrack(at) })));
	}

	// Changes the collapse status when clicking the double-arrow buttons
	function changeCollapsed(trackToChange, value) {
		setCollapsibleAttachedTracks(prevTracks => prevTracks.map(at => (at.track.id === trackToChange.id ? { ...at, collapsed: value } : at)));
	}

	const [ changePlanningStatus ] = useMutation(gql`mutation Mutation($planningId: Int!, $status: PlanningStatus!) {
        changePlanningStatus(planningId: $planningId, status: $status) { id }
    }`);

	const duplicateUsers = useMemo(() => {
		if (planning == null) return [];
		const usersById = spots.map(s => s.dockWorker?.user)
			.filter(u => u != null)
			.reduce((total, user) => ({ ...total, [user.id]: user }), {});

		const numberOfSpotsByUserId = planning.spots
			.map(s => s.dockWorker?.user)
			.filter(u => u != null)
			.reduce((byUserId, user) => ({ ...byUserId, [user.id]: (byUserId[user.id] ?? 0) + 1 }), {});

		return Object.entries(numberOfSpotsByUserId)
			.filter(([ _, number ]) => number > 1)
			.map(([ userId, _ ]) => usersById[userId]);
	}, [ planning ]);
	const duplicateUserIds = useMemo(() => new Set(duplicateUsers.map(d => d.id)), [ duplicateUsers ]);

	const alreadyPlannedUserIds = useMemo(() => {
		if (planning == null) return new Set();
		return new Set(spots.map(s => s?.dockWorker?.user.id).filter(x => x != null));
	}, [ planning ]);

	const spotProps = useMemo(() => {
		return {
			absentUserIds,
			duplicateUserIds,
			alreadyPlannedUserIds,
			usersInAdjacentShift,
		};
	}, [ absentUserIds, duplicateUserIds, alreadyPlannedUserIds, usersInAdjacentShift ]);


	return <div>
		<Warnings absentUserIds={absentUserIds} duplicateUsers={duplicateUsers} planning={planning} />
		{downloadPayslipModal && <ExportPayslipModal isOpen={downloadPayslipModal} onClose={() => setDownloadPayslipModal(false)} planning={planning} />}
		<div className="crew-grid-wrapper">
			<div className="crew-grid" style={{ padding: '16px', width: 'min-content' }}>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 'var(--u-8)', padding: 'var(--u-8) 0', paddingRight: 'var(--u-8)', minWidth: '130px', width: '130px' }}>
					<h3 style={{ padding: 0, margin: 0, color: 'var(--col-grey-700)' }}><FormattedTime value={planning.shiftStart} /> - <FormattedTime value={planning.shiftEnd} /></h3>
					<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-8)', paddingBottom: 'var(--u-4)' }}>
						<Toggle onChange={value => changePlanningStatus({ variables: { planningId: planning?.id, status: value ? 'VISIBLE' : 'CONCEPT' } })} value={planning?.status == 'VISIBLE'} />
						<span style={{ fontWeight: 'bold', color: planning?.status == 'VISIBLE' ? 'var(--col-primary-500)' : 'var(--col-grey-600)' }}>openbaar</span>
					</div>
					<RoleSummary spots={spots} />
					<DropdownButton mainAction={<LinkButton to={'/shifts/view/' + id}>Bekijken</LinkButton>}>
						<Button onClick={() => onExportToExcel()}><span className="fa fa-file-excel-o" />&nbsp; Excel</Button>
						<Button style={{ minWidth: '110px' }} onClick={() => setDownloadPayslipModal(true)}><span className="fa fa-download" />Loonbewijzen</Button>
					</DropdownButton>
					<div style={{ display: 'flex', alignItems: 'center', gap: 'var(--u-8)', paddingBottom: 'var(--u-4)' }}>
						<Button style={{ minWidth: '110px' }} onClick={() => uncollapseAllNotEmpty()}><span className="fa fa-angle-double-right" />&nbsp; Uitklappen</Button>
					</div>
				</div>

				{collapsibleAttachedTracks.map(({ track, collapsed }, idx) => {
					const plannableSpots = planning.spots.filter(s => s.track?.id == track.track?.id && s.externalReference == null);

					// external spots that are linked to this planning
					const externalNonPlannableSpots = planning.spots.filter(s => s.track?.id == track.track?.id && s.externalReference != null);

					// external planSpots that have no planning linked (but match based on other criteria)
					// also checks that the spot's times overlap with the planning times to prevent showing spots with overwritten times from another day/shift
					const externalWithoutPlanningNonPlannableSpots = spots.filter(s => s.track?.id == track.track?.id &&
						(s.planning?.shiftDefinition?.code == planning?.shiftDefinition?.code || s.shiftCode == planning?.shiftDefinition?.code) &&
						s.planning?.id != planning.id &&
						((s.start == null && s.end == null) || s.start <= planning.shiftEnd && s.end >= planning.shiftStart)
					);

					// merge the two lists above into one since they are always mutually exclusive (one is from this planning, one is specifically anything that is not from this planning). This is done for backwards compatibility with the old external planSpot sync logic
					const nonPlannableSpots = externalWithoutPlanningNonPlannableSpots.concat(externalNonPlannableSpots);

					const isEmpty = plannableSpots.length == 0 && nonPlannableSpots.length == 0;

					return <div key={track.id}>
						{!collapsed || !isEmpty ?
							<>
								<div className="crew-col" style={{ minWidth: '350px', width: '350px' }}>
									<div className="crew-col-header">
										<div style={{ display: 'flex', justifyContent: 'space-between' }}>
											<h3 style={{ padding: 0, margin: 0 }}>{track.displayName}</h3>
											{isEmpty && <Button style={{ minHeight: '20px', minWidth: '20px', maxHeight: '20px', maxWidth: '20px' }} onClick={() => changeCollapsed(track, true)}><span className="fa fa-angle-double-left" /></Button>}
										</div>
										<div>
											<ExternalRemark planningId={planning?.id} trackId={track.track?.id} />
											<ShiftTrackRemark
												planningId={planning?.id}
												trackId={track.track?.id}
												remark={(planning.remarks ?? []).filter(r => r.track?.id == track?.track?.id)[0]?.remark ?? null} />
										</div>
									</div>

									<Droppable droppableId={'shift-' + id + '-track-' + track.track?.id}>
										{(provided, snapshot) => <div ref={provided.innerRef} {...provided.droppableProps}>
											{plannableSpots.map((spot, idx) => <Spot key={spot.id} spot={spot} idx={idx} planning={planning} {...spotProps} />)}
											{provided.placeholder}

											{nonPlannableSpots.map((spot, idx) => <ReadOnlySpot key={spot.id} canChangeRemark={false} spot={spot} idx={idx} {...spotProps} />)}
										</div>}
									</Droppable>
								</div>
								<div>
									<PrivilegeBarrier privileges={[ 'PLANSPOT_CREATE' ]}>
										<ButtonArea>
											<AddSpot trackId={track?.track?.id} planningId={id} />
										</ButtonArea>
									</PrivilegeBarrier>
								</div>
								{idx == (view?.attachedTracks ?? []).length - 1 && <ShiftRemark planningId={planning.id} remark={(planning.remarks ?? []).filter(r => r.track == null)[0]?.remark ?? null} />}
							</>
							:
							<>
								<div className="crew-col-collapsed">
									<div className="crew-col-header">
										<div style={{ display: 'flex' }}>
											<Button style={{ minHeight: '20px', minWidth: '20px', maxHeight: '20px', maxWidth: '20px' }} onClick={() => changeCollapsed(track, false)}><span className="fa fa-angle-double-right" /></Button>
											&nbsp;
											<h3 style={{ padding: 0, margin: 0 }}>{track.displayName}</h3>
										</div>
									</div>
								</div>
							</>
						}
					</div>;
				})}
			</div>
		</div>
	</div>;
}