import { Button, ButtonGroup } from '@atrocit/scl';
import { useState } from 'react';
import { FormattedDate } from 'react-intl';

export default function WeekSelector({ startOfWeek, setStartOfWeek }) {
	const [ dropdownOpen, setDropdownOpen ] = useState(false);
	const [ month, setMonth ] = useState(null);

	const selectedMonth = month || startOfWeek;

	return <div style={{ position: 'relative' }}>
		<ButtonGroup>
			<Button onClick={() => setStartOfWeek(sd => sd.minus({ weeks: 1 }))}><span className="fa fa-chevron-left" /></Button>
			<div className="scl-button">Week {startOfWeek.weekNumber}, {startOfWeek.weekYear}</div>
			<Button onClick={() => setStartOfWeek(sd => sd.plus({ weeks: 1 }))}><span className="fa fa-chevron-right" /></Button>

			<Button onClick={() => setDropdownOpen(d => !d)} active={dropdownOpen}><span className="fa fa-chevron-down" /></Button>
		</ButtonGroup>

		{dropdownOpen && <div className="week-selector-dropdown" style={{ position: 'absolute', padding: 'var(--u-8)', zIndex: '10', top: 'calc(100% + 8px)', right: 0, minWidth: '100px', minHeight: '50px', background: '#fff', boxShadow: '1px 2px 4px rgba(0, 0, 0, 0.05), 1px 2px 24px rgba(0, 0, 0, 0.07)', borderRadius: '4px' }}>
			<div className="week-selector-dropdown-header" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 'var(--u-8)' }}>
				<Button onClick={() => setMonth(selectedMonth.minus({ months: 1 }))}><span className="fa fa-chevron-left" /></Button>
				<div style={{ padding: '0 var(--u-8)' }}><FormattedDate value={selectedMonth.toJSDate()} month="long" /> {selectedMonth.year}</div>
				<Button onClick={() => setMonth(selectedMonth.plus({ months: 1 }))}><span className="fa fa-chevron-right" /></Button>
			</div>
			<div style={{ display: 'flex', flexDirection: 'column', gap: 'var(--u-2)' }}>
				{(() => {
					const output = [];
					let week = selectedMonth.startOf('month').startOf('week');
					while (week < selectedMonth.endOf('month')) {
						const weekToSet = week;
						const selected = week.toISO() == startOfWeek.toISO();
						output.push(<div style={{ display: 'grid', gridTemplateColumns: '100px 50px 20px 50px', gap: 'var(--u-8)', minHeight: 'var(--u-32)', alignItems: 'center', background: selected ? 'var(--col-primary-600)' : 'var(--col-grey-50)', color: selected ? 'var(--col-primary-100)' : 'var(--col-grey-800)', borderRadius: '4px', cursor: 'pointer' }} key={week.toISO()} onClick={() => { setStartOfWeek(weekToSet); setDropdownOpen(false); }}>
							<div style={{ padding: 'var(--u-8)' }}>Week {week.weekNumber}</div>
							<div style={{ textAlign: 'center' }}><FormattedDate value={week} day="2-digit" month="2-digit" /></div>
							<div style={{ textAlign: 'center', fontSize: 'var(--fs-9)', color: selected ? 'var(--col-primary-300)' : 'var(--col-grey-500)' }}>t/m</div>
							<div style={{ textAlign: 'center' }}><FormattedDate value={week.endOf('week')} day="2-digit" month="2-digit" /></div>
						</div>);
						week = week.plus({ week: 1 });
					}
					return output;
				})()}
			</div>
		</div>}
	</div>;
}