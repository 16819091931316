import { gql, useMutation } from '@apollo/client';
import { useState } from 'react';
import { Button, Form, Modal } from '@atrocit/scl';
import ButtonArea from '../lib/forms/ButtonArea';
import ValidatedButton from '../lib/forms/ValidatedButton';

export default function DeleteUserModal({ isOpen, onClose, userId, refetch }) {
	const [ loading, setLoading ] = useState(false);

	const [ deleteUser ] = useMutation(gql`mutation Mutation($userId: Int!) {
        deleteUser(userId: $userId)
    }`);

	function process() {
		setLoading(true);
		deleteUser({ variables: { userId: userId } })
			.finally(() => { refetch(); onClose(); });
	}

	return <Modal isOpen={isOpen} onClose={onClose} title="Gebruiker archiveren">
		{!loading && <Form style={{ maxWidth: "var(--u-384)" }} onSubmit={process}>
			<p style={{ marginTop: 0 }}>Wilt u deze gebruiker archiveren? Deze actie kan niet ongedaan gemaakt worden.</p>
			<ButtonArea>
				<ValidatedButton type="submit" style={{ background: "var(--col-red)", color: "var(--col-white)", borderColor: 'var(--col-red-600)' }}>
					<span className="fa fa-archive" />&nbsp;Archiveren
				</ValidatedButton>
				<Button onClick={onClose}>Annuleren</Button>
			</ButtonArea>
		</Form>}
		{loading && <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<span className="fa fa-spinner fa-pulse" style={{ fontSize: 'var(--fs-18)' }} />
		</div>}
	</Modal>;
}