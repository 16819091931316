import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { FormattedDate } from 'react-intl';
import { Form, Tooltip } from '@atrocit/scl';

function calcRangePercentage(min, max, amount) {
	return (amount - min) / (max - min);
}

function calcDateRangePercentage(min, max, date) {
	return calcRangePercentage(min.toMillis(), max.toMillis(), date.toMillis());
}

function dataAnalysis(x) {
	const xAvg = x.reduce((a, b) => a + b, 0) / x.length;
	const mr = [];
	for (let i = 1; i < x.length; i++) {
		mr.push(Math.abs(x[i] - x[i - 1]));
	}
	const mrAvg = mr.reduce((a, b) => a + b, 0) / mr.length;
	const uclr = 3.27 * mrAvg;
	const lnpl = xAvg - (2.66 * mrAvg);
	const unpl = xAvg + (2.66 * mrAvg);

	return {
		x,
		xAvg,
		mr,
		mrAvg,
		uclr,
		lnpl,
		unpl,
	};
}

export default function ControlChart({ samples }) {
	const samplesProc = useMemo(() => {
		return samples.map(s => ({ time: DateTime.fromISO(s.timestamp), amount: s.amount }))
			.sort((a, b) => a.time.toMillis() - b.time.toMillis());
	}, [ samples ]);

	const { xAvg, lnpl, unpl } = useMemo(() => dataAnalysis(samplesProc.map(s => s.amount)), [ samplesProc ]);

	const minAmount = 0;
	const maxAmount = Math.max(...samplesProc.map(s => s.amount), xAvg, lnpl, unpl);
	const minTime = Math.min(...samplesProc.map(s => s.time));
	const maxTime = Math.max(...samplesProc.map(s => s.time));

	return <div className="control-chart">
		<div className="control-chart-samples" style={{ position: 'relative', zIndex: 0 }}>
			<div className="control-chart-sample-area-overlay" style={{ position: 'absolute', inset: 0, bottom: '30px', border: '1px solid #ccc', overflow: 'hidden' }}>
				<div className="control-chart-avg-line" style={{ position: 'absolute', bottom: 'calc(' + (calcRangePercentage(minAmount, maxAmount, xAvg) * 100) + '% - 0.5px)', left: 0, right: 0, borderBottom: '1px solid var(--col-primary-500)' }} />
				<div className="control-chart-lnpl-line" style={{ position: 'absolute', bottom: 'calc(' + (calcRangePercentage(minAmount, maxAmount, lnpl) * 100) + '% - 0.5px)', left: 0, right: 0, borderBottom: '1px solid var(--col-primary-300)' }} />
				<div className="control-chart-unpl-line" style={{ position: 'absolute', bottom: 'calc(' + (calcRangePercentage(minAmount, maxAmount, unpl) * 100) + '% - 0.5px)', left: 0, right: 0, borderBottom: '1px solid var(--col-primary-300)' }} />
			</div>
			{samplesProc.map(s => {
				return <div key={s.time.toMillis()} className="control-chart-sample" style={{ zIndex: 1 }}>
					<div className="control-chart-sample-bar-wrapper">
						<div className="control-chart-sample-bar" style={{ height: (calcRangePercentage(minAmount, maxAmount, s.amount) * 100) + '%' }}>
							<Tooltip content={<span>{s.amount}</span>}></Tooltip>
						</div>
					</div>
					<div className="control-chart-sample-label"><Tooltip content={<><FormattedDate value={s.time} /></>}>{s.time.weekNumber}</Tooltip></div>
				</div>;
			})}
		</div>
	</div>;
}