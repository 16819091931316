import React, { useContext } from 'react';
import SideBarLink from './SideBarLink';
import { Button } from '@atrocit/scl';
import { useHotkeys } from 'react-hotkeys-hook';
import usePersistentState from '../../hooks/usePersistentState';
import UserContext from '../../context/UserContext';
import ConfigContext from '../../context/ConfigContext';
import { logout } from '../../util/api';
import PrivilegeBarrier from '../auth/PrivilegeBarrier';

export default function SideBar() {
	const config = useContext(ConfigContext);
	const user = useContext(UserContext);
	const [ collapsed, setCollapsed ] = usePersistentState(false, 'atopp.navSidebarCollapse');

	useHotkeys('m', () => setCollapsed(c => !c));

	return <>
		<div className={"hamburger-btn" + (collapsed ? ' hamburger-btn-open' : ' hamburger-btn-closed')}>
			<button className="hamburger-btn" onClick={() => setCollapsed(c => !c)}>
				<div className="hamburger-btn-stripe" />
				<div className="hamburger-btn-stripe" />
				<div className="hamburger-btn-stripe" />
			</button>
		</div>
		<nav className={"sidebar " + (!collapsed ? "" : "sidebar-open")}>
			<div className="sidebar-transform-layer">
				<div className="sidebar-content">
					<div style={{ padding: '16px 20px' }}>
						<img src="/assets/logo_light.png" alt="Logo" style={{ maxWidth: '120px', height: 'auto' }} />
					</div>

					{user.dockWorkerRole && <PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><SideBarLink to="/myShifts" label="Mijn shifts" icon="calendar-check-o" /></PrivilegeBarrier>}
					{user.dockWorkerRole && <PrivilegeBarrier privileges={[ 'READ_MY_PLANNINGS' ]}><SideBarLink to="/myAbsences" label="Mijn absenties" icon="plane" /></PrivilegeBarrier>}

					<PrivilegeBarrier privileges={[ 'READ_VISIBLE_FUTURE_PLANNINGS', 'READ_VISIBLE_PLANNINGS', 'READ_CONCEPT_PLANNINGS' ]}>
						<SideBarLink to="/shifts" label="Planning" icon="calendar-o">
							<SideBarLink to="/shifts/week" label="Kalender" icon="calendar" />
							<SideBarLink to="/shifts/byperson" label="Per persoon" icon="users" />
						</SideBarLink>
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_ABSENCES' ]}>
						<SideBarLink to="/absences" label="Absentie" icon="plane">
							<SideBarLink to="/absences/calendar" label="Kalender" icon="calendar-o" />
							<PrivilegeBarrier privileges={[ 'WRITE_ABSENCES' ]}>
								<SideBarLink to="/absences/approval" label="Goedkeuring" icon="check-circle" />
							</PrivilegeBarrier>
							<SideBarLink to="/absences/byuser" label="Per persoon" icon="users" />
							<SideBarLink to="/absences/specialdays" label="Feestdagen" icon="tree"></SideBarLink>
						</SideBarLink>
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_OWN_SAVINGS_RECORD', 'READ_SAVINGS_RECORDS' ]}>
						<SideBarLink to="/savings" label="Briefjes" icon="sticky-note" />
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_OWN_WAGE_RECORDS', 'READ_ALL_WAGE_RECORDS' ]}>
						<SideBarLink to="/wages" label="Loonbewijzen" icon="euro" />
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_ALL_WORK_RECORDS' ]}>
						<SideBarLink to="/administration" label="CEPA" icon="book">
							{/* <SideBarLink to="/administration/absences" label="CEPA-verlof" icon="briefcase" />*/}{/* TODO */}
							<SideBarLink to="/administration/workrecords" label="Loonlijnen" icon="list">
								<SideBarLink to="/administration/workrecords/toEnter" label="In te voeren" icon="plus" />
								<SideBarLink to="/administration/workrecords/changes" label="Wijzigingen" icon="exclamation-circle" />
							</SideBarLink>
							{(user?.email?.includes('boortmalt.com') || user?.email?.includes('atrocit.com')) && <SideBarLink to="/administration/bmcsvgenerator" label="CSV-generator" icon="file-excel-o" />}
							<SideBarLink to="/administration/wagetables" label="Loontabellen" icon="table" />
						</SideBarLink>
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_ANALYTICS' ]}>
						<SideBarLink to="/analytics" label="Statistieken" icon="bar-chart" />
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'WRITE_PLANNINGS' ]}>
						<SideBarLink to="/roles" label="Rollen" icon="user-circle" />
					</PrivilegeBarrier>

					<PrivilegeBarrier privileges={[ 'READ_USERS' ]}>
						<SideBarLink to="/users" label="Gebruikers" icon="user" />
					</PrivilegeBarrier>
				</div>
				<div className="login-info">
					<span style={{ fontWeight: 'bold' }} className="login-info-user">{user.fullName}</span><br />
					<span style={{ color: 'var(--col-grey-300)' }}>{user.authRoles.map(ar => ar.name).join(', ')}</span><br />
					<br />

					<Button onClick={() => { logout().finally(() => window.location.reload()); }}><span className="fa fa-sign-out" />&nbsp; Uitloggen</Button>
				</div>
			</div>
		</nav>
	</>;
}