import { useRef, useState } from 'react';
import { DateTime } from 'luxon';
import { gql, useQuery } from '@apollo/client';
import BlurredZeroValue from '../lib/tables/BlurredZeroValue';
import { Button, DateField, WithLabel } from '@atrocit/scl';
import RoleSelectField from '../lib/selectfields/RoleSelectField';
import ControlChart from './ControlChart';

export default function AnalyticsOverview() {
	const [ startDate, setStartDate ] = useState(DateTime.now().minus({ month: 1 }).startOf('month'));
	const [ endDate, setEndDate ] = useState(DateTime.now().minus({ month: 1 }).endOf('month'));
	const [ role, setRole ] = useState(null);

	const statsPerRoleQuery = useQuery(gql`query Query($start: Instant!, $end: Instant!) {
		statsPerRole(start: $start, end: $end) {
			roleId,
			roleShortCode,
			roleColorCode,
			total,
			totalEarly,
			totalDay,
			totalLate,
			totalNight,
			weekdayEarly,
			weekdayDay,
			weekdayLate,
			weekdayNight,
            saturdayEarly,
            saturdayDay,
            saturdayLate,
            saturdayNight,
            sundayEarly,
            sundayDay,
            sundayLate,
            sundayNight,
		}
	}`, { variables: { start: startDate.toUTC().toISO(), end: endDate.toUTC().toISO() } });
	const statsPerRole = (statsPerRoleQuery.data?.statsPerRole ?? []).sort((a, b) => b.total - a.total);

	const roleSamples = useQuery(gql`query Query($start: Instant!, $end: Instant!, $role: Int!) {
		weeklyCounts(start: $start, end: $end, roleId: $role) {
			timestamp,
			amount,
		}
	}`, { variables: { start: DateTime.now().startOf('week').minus({ weeks: 30 }), end: DateTime.now().endOf('week').minus({ weeks: 1 }), role }, skip: role == null });
	const samples = roleSamples?.data?.weeklyCounts ?? [];

	const tableRef = useRef();

	return <div className="page">
		<h1>Aantallen shifts</h1>

		<div style={{ display: 'flex' }}>
			<div style={{ display: 'grid', gridTemplateColumns: '150px 150px', gap: 'var(--u-16)', flex: 1 }}>
				<WithLabel label="Vanaf">
					<DateField onChange={setStartDate} value={startDate} />
				</WithLabel>
				<WithLabel label="Tot (excl.)">
					<DateField onChange={setEndDate} value={endDate} relativeTo={startDate} />
				</WithLabel>
			</div>
			<div>
				<WithLabel label={<>&nbsp;</>}>
					<Button onClick={() => {
						import('xlsx-js-style').then(xlsx => {
							console.log(xlsx);
							const wb = xlsx.utils.table_to_book(tableRef.current);
							xlsx.writeFile(wb, 'shift-aantallen.xlsx', { bookType: 'xlsx' });
						});
					}}>
						<span className="fa fa-file-excel-o" />
					</Button>
				</WithLabel>
			</div>
		</div>

		<table className="table" ref={tableRef}>
			<colgroup>
				<col width="70" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="2" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="2" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="2" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="38" />
				<col width="2" />
				<col width="*" />
			</colgroup>
			<thead>
				<tr>
					<th>Rol</th>
					<th colSpan={4}>Totalen</th>
					<th style={{ padding: 0 }} />
					<th colSpan={4}>Weekdag</th>
					<th style={{ padding: 0 }} />
					<th colSpan={4}>Zaterdag</th>
					<th style={{ padding: 0 }} />
					<th colSpan={4}>Zondag</th>
					<th style={{ padding: 0 }} />
					<th>Alles opgeteld</th>
				</tr>
				<tr>
					<th />
					<th className="tbl-align-right">V</th>
					<th className="tbl-align-right">D</th>
					<th className="tbl-align-right">L</th>
					<th className="tbl-align-right">N</th>
					<th style={{ padding: 0 }} />
					<th className="tbl-align-right">V</th>
					<th className="tbl-align-right">D</th>
					<th className="tbl-align-right">L</th>
					<th className="tbl-align-right">N</th>
					<th style={{ padding: 0 }} />
					<th className="tbl-align-right">V</th>
					<th className="tbl-align-right">D</th>
					<th className="tbl-align-right">L</th>
					<th className="tbl-align-right">N</th>
					<th style={{ padding: 0 }} />
					<th className="tbl-align-right">V</th>
					<th className="tbl-align-right">D</th>
					<th className="tbl-align-right">L</th>
					<th className="tbl-align-right">N</th>
					<th style={{ padding: 0 }} />
					<th />
				</tr>
			</thead>
			<tbody>
				{statsPerRole.map(stat => <tr key={stat.roleId}>
					<td style={{ background: stat.roleColorCode }}>{stat.roleShortCode}</td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.totalEarly} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.totalDay} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.totalLate} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.totalNight} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.weekdayEarly} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.weekdayDay} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.weekdayLate} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.weekdayNight} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.saturdayEarly} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.saturdayDay} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.saturdayLate} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.saturdayNight} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.sundayEarly} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.sundayDay} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.sundayLate} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={stat.sundayNight} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right" style={{ fontWeight: 'bold' }}>{stat.total}</td>
				</tr>)}
				<tr style={{ fontWeight: 'bold' }}>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.totalEarly).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.totalDay).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.totalLate).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.totalNight).reduce((a, b) => a + b, 0)} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.weekdayEarly).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.weekdayDay).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.weekdayLate).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.weekdayNight).reduce((a, b) => a + b, 0)} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.saturdayEarly).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.saturdayDay).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.saturdayLate).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.saturdayNight).reduce((a, b) => a + b, 0)} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.sundayEarly).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.sundayDay).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.sundayLate).reduce((a, b) => a + b, 0)} /></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.sundayNight).reduce((a, b) => a + b, 0)} /></td>
					<td style={{ padding: 0 }}></td>
					<td className="tbl-align-right"><BlurredZeroValue value={statsPerRole.map(s => s.total).reduce((a, b) => a + b, 0)} /></td>
				</tr>
			</tbody>
		</table>

		<br /><br />
		<h1>Control Charts</h1>
		<WithLabel label="Rol">
			<RoleSelectField onChange={setRole} value={role} />
		</WithLabel>

		<ControlChart samples={samples} />

	</div>;
}