import React, { useContext } from 'react';
import ColorDot from '../../lib/ui/ColorDot';
import { FormattedTime } from 'react-intl';
import UserContext from '../../../context/UserContext';
import { PrivilegesContext } from '../../../context/PrivilegesContext';

export default function ViewSpot({ spot, extraInfo }) {
	const user = useContext(UserContext);
	const isCurrentUserInSpot = user?.id == spot.dockWorker?.user?.id;
	const privileges = useContext(PrivilegesContext);

	return <div style={{ display: 'grid', gap: '0 var(--u-4)', gridTemplateColumns: '64px 1fr', padding: '4px 0', borderBottom: '1px solid var(--col-grey-200)' }}>
		<div>
			<ColorDot hex={spot.role?.colorCode} /> {spot.role?.shortCode}
		</div>
		<div style={{ fontWeight: isCurrentUserInSpot ? 'bold' : 'normal' }}>
			{spot.dockWorker?.user?.fullName ?? '-'}
			{(isCurrentUserInSpot || privileges.has('PLANSPOT_CHANGE_TIMES')) && spot.start && spot.end && <div style={{ display: 'flex', minHeight: '24px', alignItems: 'center' }}>
				<div className='date-time-display'>Aangepaste tijden: <FormattedTime value={spot.start} /> - <FormattedTime value={spot.end} /></div>
			</div>}
		</div>
		<div />
		<div>
			{(extraInfo == 'REMARK' || extraInfo == 'ALL') && (spot.remarkVisibility == 'PUBLIC' || (spot.remarkVisibility == 'WORKER_IN_SPOT_AND_ADMIN' && isCurrentUserInSpot)) && <i>{spot.remark}</i>}
			{(extraInfo == 'WORKBOOK_NR' || extraInfo == 'ALL') && <i>{spot.dockWorker?.workbookNr ?? '-'}</i>}
		</div>
	</div>;
}