import { Button } from '@atrocit/scl';
import { useState } from 'react';

export default function DropdownButton({ mainAction, children }) {
	const [ open, setOpen ] = useState(false);

	return <div className={"dropdown-button" + (open ? ' dropdown-button-open' : '')}>
		<div className="btn-group">
			{mainAction}
			<Button className="dropdown-button-open-btn" active={open} onClick={() => setOpen(o => !o)}>
				<span className="fa fa-caret-down" />
			</Button>
		</div>

		<div className="dropdown-button-content" onClick={() => setOpen(false)}>
			{children}
		</div>
	</div>;
}