import { gql, useQuery } from '@apollo/client';
import { useMemo, useState } from 'react';
import { DateTime } from 'luxon';
import TopBar from '../TopBar';
import PageTop from '../lib/ui/PageTop';
import { Button, ButtonGroup, DateField, LoadingOverlay, WithLabel } from '@atrocit/scl';
import WorkRecordDockWorker from './lib/WorkRecordsTable';
import { groupByDockWorker } from './lib/utils';

export default function WorkRecordsOverview() {
	const [ lowerbound, setLowerbound ] = useState(DateTime.now().minus({ weeks: 1 }).startOf('week').minus({ days: 2 }));
	const [ upperbound, setUpperbound ] = useState(DateTime.now().minus({ weeks: 1 }).endOf('week').minus({ days: 2 }));

	const workRecordsQuery = useQuery(gql`query Query($lowerbound: Instant!, $upperbound: Instant!) {
        workRecordsWithinRange(lowerbound: $lowerbound, upperbound: $upperbound) {
            id,
            needsChange,
            planSpot {
                id,
                planning {
                    id,
                },
	            start,
	            end,
            },
            dockWorkerTarget {
                id,
                workbookNr,
                category,
                user {
                    id,
                    fullName,
                }
            },
            dockWorkerEntered {
                id,
                workbookNr,
                category,
                user {
                    id,
                    fullName,
                }
            },
            startDateTarget,
            endDateTarget,
            absenceTypeTarget,
            roleTarget {
                id, colorCode, shortCode,
            },
            remarkTarget,
            calculatedWageTarget,
            calculatedPremiumTarget,
        }
    }`, { variables: { lowerbound: lowerbound.toUTC().toISO(), upperbound: upperbound.toUTC().toISO() } });
	const workRecords = workRecordsQuery?.data?.workRecordsWithinRange ?? [];

	// Work records per dock worker
	const wrpdw = useMemo(() => groupByDockWorker(workRecords), [ workRecords ]);

	return <>
		<TopBar title="Alle CEPA loonlijnen" />
		<div className="grey-page-bg">
			<div className="page">
				{workRecordsQuery.loading && <LoadingOverlay />}

				<PageTop breadcrumbSegments={[ { link: '/administration', label: 'CEPA' }, { link: '/administration/workrecords', label: 'Loonlijnen' } ]} />

				<div className="col-2" style={{ alignItems: 'flex-end' }}>
					<WithLabel label="Van">
						<DateField onChange={d => setLowerbound(d.startOf('day'))} value={lowerbound} />
					</WithLabel>
					<WithLabel label="T/m">
						<DateField onChange={d => setUpperbound(d.endOf('day'))} value={upperbound} />
					</WithLabel>
				</div>

				<ButtonGroup>
					<Button
						onClick={() => {
							setLowerbound(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 }));
							setUpperbound(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 }));
						}}
						active={lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 }))}
						level={lowerbound.equals(DateTime.now().minus({ days: 14 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 14 }).endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
						Twee weken geleden
					</Button>
					<Button
						onClick={() => {
							setLowerbound(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 }));
							setUpperbound(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 }));
						}}
						active={lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 }))}
						level={lowerbound.equals(DateTime.now().minus({ days: 7 }).startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().minus({ days: 7 }).endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
						Afgelopen week
					</Button>
					<Button
						onClick={() => {
							setLowerbound(DateTime.now().startOf('week').minus({ days: 2 }));
							setUpperbound(DateTime.now().endOf('week').minus({ days: 2 }));
						}}
						active={lowerbound.equals(DateTime.now().startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().endOf('week').minus({ days: 2 }))}
						level={lowerbound.equals(DateTime.now().startOf('week').minus({ days: 2 })) && upperbound.equals(DateTime.now().endOf('week').minus({ days: 2 })) ? 'primary' : 'secondary'}>
						Deze week
					</Button>
				</ButtonGroup>

				<div style={{ borderBottom: '1px solid var(--col-grey-200)', padding: 'var(--u-8) 0', marginBottom: 'var(--u-8)' }} />

				{wrpdw.map(({ dockWorker, records }) => <WorkRecordDockWorker key={dockWorker.id} dockWorker={dockWorker} workRecords={records} highlightNewEntries={true} />)}
				{wrpdw.length == 0 && <div style={{ textAlign: 'center', color: 'var(--col-grey-500)', padding: '32px 0', fontStyle: 'italic' }}>Geen resultaten</div>}
			</div>
		</div>
	</>;
}