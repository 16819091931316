import { Button } from '@atrocit/scl';
import { gql, useMutation } from '@apollo/client';

export default function AddSpot({ trackId, planningId }) {
	const [ addSpot ] = useMutation(gql`mutation Mutation($planningId: Int!, $trackId: Int!) {
		addSpot(planningId: $planningId, trackId: $trackId) { id }
	}`);

	return <div>
		<Button onClick={() => addSpot({ variables: { planningId, trackId } })}><span className="fa fa-plus" />&nbsp; Plaats toevoegen</Button>
	</div>;
}