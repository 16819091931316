import { useCallback, useState } from 'react';
import TopBar from '../../TopBar';
import { Button, ButtonArea, ErrorBanner, FileDropInput, Form, LoadingOverlay, WithLabel } from '@atrocit/scl';
import ValidatedButton from '../../lib/forms/ValidatedButton';
import { DateTime } from 'luxon';

const xlsxImport = import('xlsx-js-style');

function readFile(file) {
	return new Promise((resolve, reject) => {
		const fr = new FileReader();
		fr.addEventListener('load', () => resolve(new Uint8Array(fr.result)));
		fr.addEventListener('error', reject);
		fr.readAsArrayBuffer(file);
	});
}

/**
 * This page allows the user to upload a specific structured excel (aanwezigheidslijst Boortmalt) and
 *  convert this into a CSV ready to be uploaded into CEPA
 * @constructor
 */
export default function BoortmaltCsvGenerator() {
	const [ error, setError ] = useState(null);
	const [ file, setFile ] = useState(null);
	const loading = false;

	const onProcess = useCallback(async () => {
		try {
			setError(null);

			const xlsx = await xlsxImport;
			const fileData = await readFile(file);
			const workbook = xlsx.read(fileData, { type: 'array' });

			const sheet = workbook.Sheets[workbook.SheetNames[0]];
			const rows = xlsx.utils.sheet_to_json(sheet, { header: 1 });

			const output = [
				[ 'Firma', 'Dept', 'Werkboek', 'Shift', 'Subshift', 'Datum betrek', 'Vergoed', 'Loon', 'Premie', 'Misen', 'Kledij', 'Attestnr Kledij', 'Wt', 'Uitbetalen', 'Gewerkte functie' ],
			];
			const shiftCode = rows[6][6];
			const date = rows[0][6];
			for (let i = 16; i < rows.length; i++) {
				const row = rows[i];

				const workbookNr = row[1];
				const wage = row[5];
				let dept = '0';
				if (row[6] != null && row[6].includes('90')) dept = '90';
				if (row[6] != null && row[6].includes('100')) dept = '100';

				if (workbookNr == null || (workbookNr + '').trim().length != 5) continue;

				output.push([
					'614', // TODO: get from config
					dept,
					workbookNr,
					shiftCode,
					date,
					'1000',
					(wage + '').replaceAll('.', ','),
					'0',
					'0',
					'',
					'',
					'',
					'J',
					'J',
				]);
			}

			if (output.length == 1) {
				throw new Error('Geen geldige lijnen gevonden in Excel');
			}

			const csv = output.map(row => row.join(';')).join('\n');

			const element = document.createElement("a");
			element.href = URL.createObjectURL(new Blob([ csv ], { type: 'text/csv' }));
			element.download = "cepa-export.csv";
			document.body.appendChild(element); // Required for this to work in FireFox
			element.click();
		} catch (e) {
			console.error(e);
			setError(e?.message ?? e);
		}
	}, [ file ]);

	return <>
		<TopBar title="CEPA CSV-generator" />

		<div className="grey-page-bg">
			{loading && <LoadingOverlay />}
			<div className="page">
				<Form onSubmit={() => onProcess()}>
					{error && <>
						<ErrorBanner>
							Er is iets fout gelopen: <span style={{ fontStyle: 'italic' }}>{error}</span>
						</ErrorBanner>
						<br />
					</>}

					<WithLabel label="Aanwezigheidslijst" isFake={true}>
						<FileDropInput onChange={setFile} value={file} />
					</WithLabel>

					<ButtonArea>
						<ValidatedButton
							level="primary"
							type="submit"
							validations={[
								{ valid: file != null, error: 'Selecteer een bestand om door te gaan' },
							]}>
							<span className="fa fa-file-excel-o" />&nbsp; Converteren naar CEPA CSV
						</ValidatedButton>
					</ButtonArea>
				</Form>
			</div>
		</div>
	</>;
}