import { DropdownButton, SelectField } from '@atrocit/scl';

export default function RemarkViewLevel({ showPublicly, setShowPublicly }) {
	return <SelectField
		options={[
			{ value: 'WORKER_INCLUDED', label: 'Zichtbaar voor arbeiders' },
			{ value: 'INTERNAL', label: 'Onzichtbaar voor arbeiders' },
		]}
		onChange={newValue => setShowPublicly(newValue == 'WORKER_INCLUDED')}
		value={showPublicly ? 'WORKER_INCLUDED' : 'INTERNAL'} />;
}