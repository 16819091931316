export default function EmptyState({ img, title, description }) {
	return <div className="empty-state">
		<div className="empty-state-graphic">
			<img src={img} aria-hidden={true} />
		</div>

		<div className="empty-state-text">
			<h1>{title}</h1>
			<p className="hero-text">{description}</p>
		</div>
	</div>;
}