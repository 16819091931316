export function AbsenceType({ type }) {
	if (type == 'UNKNOWN') return 'Onbekend';
	if (type == 'SICK_LEAVE') return 'Ziekte';
	if (type == 'VACATION') return 'Vakantie';
	if (type == 'SENIORITY') return 'Anciënniteit';
	if (type == 'TIME_FOR_TIME') return 'HVD';
	if (type == 'SAVINGS_RECORD') return 'Briefje';
	if (type == 'UNEMPLOYMENT') return 'DOP';
	if (type == 'OFFICIAL_REASON') return 'Attest';
	if (type == 'PARENTAL_LEAVE') return 'Ouderschap';
	if (type == 'TIME_CREDIT') return 'Tijdkrediet';
	if (type == 'SPECIAL_LEAVE') return 'Klein verlet';
	if (type == 'PARTTIME_3_5') return '3/5';
	if (type == 'PARTTIME_4_5') return '4/5';
	if (type == 'OTHER') return 'Overig';
	return '- ? -';
}