export function requestMagicLink(email) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return fetch('/api/whoami/magiclink/request', {
		method: 'post',
		body: JSON.stringify({ email }),
		headers,
		credentials: 'include',
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}

export function useMagicLink(token) {
	const headers = new Headers();
	headers.append('Content-Type', 'application/json');

	return fetch('/api/whoami/magiclink/login/' + token, {
		method: 'post',
		headers,
		credentials: 'include',
	}).then(r => {
		if (r.status >= 400) throw r;
		return r.text();
	});
}

export function authenticate(username, password) {
	const headers = new Headers();
	headers.append('Authorization', 'Basic ' + btoa(username + ':' + password));

	return fetch('/api/whoami', {
		method: 'get',
		headers,
		credentials: 'include',
	}).then(r => {
		if (r.status >= 400) throw r;
	});
}

export function logout() {
	return fetch('/api/logout', { method: 'POST', credentials: 'include' });
}